const menuOptions = [
  {
    label: "Home",
    icon: "Home",
    group: "side-bar-top",
    to: "/",
  },
  {
    label: "Reports",
    icon: "ChartPie",
    group: "",
    to: "/reports",
  },
  {
    label: "Dashboard",
    icon: "ViewGrid",
    group: "side-bar-top",
    to: "/dashboard",
  },
  {
    label: "View All Programmes",
    icon: "Table",
    group: "side-bar-top",
    to: "/eopl/allprograms",
  },
  {
    label: "Create Custom Programme",
    icon: "DocumentAdd",
    group: "side-bar-top",
    to: "/eopl/create",
  },
  {
    label: "Upload Cycle Plan",
    icon: "Upload",
    group: "side-bar-top",
    to: "/eopl/upload",
  },
  {
    label: "Email Programme",
    icon: "Mail",
    group: "side-bar-top",
    to: "/eopl/mail",
  },
  {
    label: "Explorer",
    icon: "Folder",
    group: "side-bar-top",
    to: "/eopl/explorer",
  },
  {
    label: "Formik",
    icon: "Table",
    group: "",
    to: "/formik",
  },
  {
    label: "Push View|Overlay View",
    icon: "Push|Overlay",
    group: "side-bar-bottomX",
    action: "toggleViewState",
  },
  // {
  //   label: "Light Mode|Dark Mode", //"Light Mode|Dark Mode|Custom Mode"
  //   icon: "Moon|Sun", // "Sun|Moon|ColorSwatch"
  //   group: "side-bar-bottom",
  //   action: "changeTheme",
  // },
  // {
  //   label: "Settings",
  //   icon: "Adjustments",
  //   group: "side-bar-bottom",
  //   to: "/settings",
  // },
  {
    label: "Help",
    group: "nav-bar",
  },
  {
    label: "Messages",
    group: "nav-bar",
  },
];

module.exports = menuOptions;
