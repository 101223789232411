import axios, { deleteRequest, getRequest, postRequest } from "../axios";
import { explorerActions } from "./explorerSlice";
import Toast from "../components/ui/Toast";
import { globalActions } from "./globalSlice";

export const setLoader = (state) => {
  return (dispatch) => {
    dispatch(explorerActions.setLoader(state));
  };
};

export const setItemDeleted = (state) => {
  return (dispatch) => {
    dispatch(explorerActions.setItemDeleted(state));
  };
};

export const setCloseContextMenu = (state) => {
  return (dispatch) => {
    dispatch(explorerActions.setCloseContextMenu(state));
  };
};

export const resetExplorer = () => {
  return (dispatch) => {
    dispatch(explorerActions.resetExplorer());
  };
};

export const getExplorerData = () => {
  return async (dispatch) => {
    try {
      dispatch(
        globalActions.setLoading({
          loading: true,
          label: `Loading Explorer Windows...`,
        })
      );

      const response = await getRequest("explorer/");

      await dispatch(explorerActions.updateExplorer(response));
    } catch (error) {
      Toast({ status: "error", message: "Failed to fetch Explorer" });
    } finally {
      dispatch(globalActions.setLoading({ loading: false }));
    }
  };
};

export const createExplorerWindow = (name) => {
  return async (dispatch) => {
    try {
      dispatch(
        globalActions.setLoading({
          loading: true,
          label: `Creating Explorer Window...`,
        })
      );

      const response = await postRequest("explorer-windows/", { name });

      Toast({ status: "success", message: response.message });
      await dispatch(getExplorerData());
    } catch (error) {
      Toast({ status: "error", message: error.message });
    } finally {
      dispatch(globalActions.setLoading({ loading: false }));
    }
  };
};

export const createExplorerFolder = (newItem) => {
  return async (dispatch) => {
    try {
      dispatch(
        globalActions.setLoading({
          loading: true,
          label: `Creating Explorer Folder, ${newItem.name}`,
        })
      );

      const response = await postRequest("explorer-folders/", newItem);

      Toast({ status: "success", message: response.message });
      await dispatch(getExplorerData());
    } catch (error) {
      Toast({ status: "error", message: error.message });
    } finally {
      dispatch(globalActions.setLoading({ loading: false }));
    }
  };
};

export const createExplorerFile = (newItem) => {
  return async (dispatch) => {
    try {
      dispatch(
        globalActions.setLoading({
          loading: true,
          label: `Creating Explorer Item, ${newItem.name}`,
        })
      );

      const response = await postRequest("explorer-files/", newItem);

      Toast({ status: "success", message: response.message });
      await dispatch(getExplorerData());
    } catch (error) {
      Toast({ status: "error", message: error.message });
    } finally {
      dispatch(globalActions.setLoading({ loading: false }));
    }
  };
};

export const deleteExplorerWindow = (deleteItem) => {
  return async (dispatch) => {
    try {
      const updateState = {
        loading: true,
        label: `Deleting Explorer Window...`,
        id: deleteItem.windowName,
      };

      dispatch(explorerActions.setLoader(updateState));
      dispatch(globalActions.setLoading(updateState));

      const response = await deleteRequest(
        `explorer-windows/${deleteItem.windowId}/`
      );

      Toast({ status: "success", message: response.message });
      await dispatch(getExplorerData());
    } catch (error) {
      Toast({ status: "error", message: error.message });
    } finally {
      dispatch(globalActions.setLoading({ loading: false }));
      dispatch(explorerActions.setLoader({ loading: false }));
    }
  };
};

export const deleteExplorerFolder = (deleteItem) => {
  return async (dispatch) => {
    try {
      const updateState = {
        loading: true,
        label: "Deleting Explorer Folder...",
        id: deleteItem.folderName,
      };

      dispatch(explorerActions.setLoader(updateState));
      dispatch(globalActions.setLoading(updateState));

      const response = await deleteRequest(
        `explorer-folders/${deleteItem.folderId}/`
      );

      Toast({ status: "success", message: response.message });
      await dispatch(getExplorerData());
    } catch (error) {
      Toast({ status: "error", message: error.message });
    } finally {
      dispatch(globalActions.setLoading({ loading: false }));
      dispatch(explorerActions.setLoader({ loading: false }));
    }
  };
};

export const deleteExplorerFile = (deleteItem) => {
  return async (dispatch) => {
    try {
      const updateState = {
        loading: true,
        label: "Deleting Explorer File...",
        id: deleteItem.fileId,
      };

      dispatch(explorerActions.setLoader(updateState));
      dispatch(globalActions.setLoading(updateState));

      const response = await deleteRequest(
        `explorer-files/${deleteItem.fileId}/`
      );

      Toast({ status: "success", message: response.message });
      await dispatch(getExplorerData());
      dispatch(explorerActions.setItemDeleted(true));
    } catch (error) {
      Toast({ status: "error", message: error.message });
    } finally {
      dispatch(globalActions.setLoading({ loading: false }));
      dispatch(explorerActions.setLoader({ loading: false }));
    }
  };
};

export const uploadFile = (file, newItem) => {
  return async (dispatch) => {
    const sendRequest = () => {
      let url = "";
      const formData = new FormData();

      url = `/explorer-files/`;
      formData.append("name", newItem?.name);
      formData.append("icon", newItem?.icon);
      formData.append("path", file);
      formData.append("explorerWindow", newItem?.explorerWindow);
      if (newItem?.parentFolder) {
        formData.append("parentFolder", newItem?.parentFolder);
      }

      dispatch(
        globalActions.setLoading({
          loading: true,
          label: "Uploading Explorer File...",
        })
      );

      let options = {
        responseType: "json",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
      };

      if (process.env.REACT_APP_BUILD_TYPE === "DEV") {
        options = {
          ...options,
          auth: {
            username: "devuser",
            password: "devuser",
          },
        };
      }

      return axios
        .post(url, formData, options)
        .then((response) => {
          if (response?.status === 201) {
            Toast({
              status: "success",
              message: "File uploaded successfully",
            });
            dispatch(getExplorerData());
          }
          if (response?.status === 400) {
            Toast({
              status: "error",
              message: "File upload failed",
            });
          }
        })
        .catch((e) => {
          Toast({
            status: "error",
            message: "Image Upload failed " + e.message,
          });
        })
        .finally(() => {
          dispatch(globalActions.setLoading({ loading: false }));
        });
    };

    try {
      await sendRequest();
    } catch (error) {
      Toast({ status: "error", message: error.message });
    }
  };
};

export const getExplorerImage = (fileId) => {
  return async (dispatch) => {
    try {
      dispatch(
        explorerActions.setLoader({
          loading: true,
          label: `Loading Image. Please wait...`,
        })
      );

      const response = await getRequest(`explorer-files/${fileId}/`);

      await dispatch(explorerActions.setExplorerImage(response.base64Url));
    } catch (error) {
      Toast({ status: "error", message: "Failed to fetch Explorer" });
    } finally {
      dispatch(explorerActions.setLoader({ loading: false }));
    }
  };
};
