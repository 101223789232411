import { InformationCircleIcon } from "@heroicons/react/solid";

const Empty = ({ message }) => {
  return (
    <div className="w-full text-typography-1 px-2 py-2 border-b border-primary/20 bg-primary/80">
      <p className="h-8 flex items-center justify-center text-typography-2">
        <InformationCircleIcon className="h-6 w-6 mr-2" />
        {message}
      </p>
    </div>
  );
};

export default Empty;
