import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Button from "../../packages/button/Button";

import { MentionsInput, Mention } from "react-mentions";
import { defaultMentionStyle } from "./defaultMentionStyle";
import { defaultStyle } from "./defaultStyle";
import { getRequest } from "../../axios";
import { useSelector } from "react-redux";
import SpinnerIcon from "../../packages/button/SpinnerIcon";
import { PencilIcon, PlusIcon, SaveIcon } from "@heroicons/react/solid";

export const fetchUsers = async (query, callback) => {
  if (!query) return;
  const filteredUsers = await getRequest(`usersFiltered/?taggedUser=@${query}`);
  callback(filteredUsers);
};

const CommentForm = ({
  submitLabel,
  handleSubmit,
  hasCancelButton = false,
  handleCancel,
  initialText = "",
}) => {
  const [text, setText] = useState(initialText);

  const loaderAdd = useSelector((state) => state.eoplComments.loaderAdd);

  useEffect(() => {
    if (loaderAdd) {
      setText("");
    }
  }, [loaderAdd]);

  const isTextareaDisabled = text.length === 0;

  const onChange = (e) => {
    setText(e.target.value);
  };

  const onAdd = (e) => {
    // likely not needed
  };

  const onSubmit = (event) => {
    event.preventDefault();
    handleSubmit(text);
    // setText('');
  };

  const onHandleCancel = () => {
    if (initialText === "") setText("");
    handleCancel();
  };

  return (
    <form onSubmit={onSubmit} className="" id="comment-form">
      <MentionsInput
        value={text}
        onChange={onChange}
        placeholder={"Mention people using '@', followed by the comment"}
        a11ySuggestionsListLabel={"Suggested mentions"}
        style={defaultStyle}
      >
        <Mention style={defaultMentionStyle} data={fetchUsers} onAdd={onAdd} />
      </MentionsInput>
      <div className="py-2 flex">
        <Button
          className="text-sm flex"
          type="submit"
          onClick={onSubmit}
          disabled={isTextareaDisabled || loaderAdd}
        >
          {loaderAdd ? (
            <SpinnerIcon className="border-secondary/20 border-t-secondary" />
          ) : submitLabel === "Add" ? (
            <PlusIcon className="mr-2 h-5 w-5" />
          ) : submitLabel === "Update" ? (
            <SaveIcon className="mr-2 h-5 w-5" />
          ) : (
            <PencilIcon className="mr-2 h-5 w-5" />
          )}
          {submitLabel}
        </Button>
        {hasCancelButton && (
          <Button
            type="button"
            className="ml-2 text-sm"
            onClick={onHandleCancel}
          >
            Cancel
          </Button>
        )}
      </div>
    </form>
  );
};

export default CommentForm;

CommentForm.propTypes = {
  handleSubmit: PropTypes.func,
  handleCancel: PropTypes.func,
  submitLabel: PropTypes.string,
  hasCancelButton: PropTypes.bool,
  initialText: PropTypes.string,
};
