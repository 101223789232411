import { Tab, Tabs } from "../../packages/tabs/Tabs";
import SimpleCard from "../../components/ui/SimpleCard";

import Users from "./Users";
import Config from "./Config";
import MailingGroup from "./MailingGroup";
import MailingList from "./MailingList";
import Contacts from "./Contacts";

export const tableStyles = {
  table: "table table-striped min-w-fit text-typography-2 mt-2 bg-white",
  thead: {
    base: "bg-primary whitespace-nowrap ",
    tr: "bg-secondary h-20",
    th: {
      actions: "text-center",
    },
  },
  tbody: {
    base: "",
    tr: "bg-seconadry/10 odd:!bg-primary/10 text-typography-1 odd:!text-typography-1 border-b border-b-tertiary ",
    td: {
      actions: "text-right w-32",
    },
  },
};
const Settings = () => (
  <SimpleCard className="p-6 ">
    <Tabs>
      <Tab title="Contacts">
        <Contacts />
      </Tab>
      <Tab title="Mailing Groups">
        <MailingGroup />
      </Tab>
      <Tab title="Mailing Lists">
        <MailingList />
      </Tab>
      <Tab title="Users">
        <Users />
      </Tab>
      <Tab title="Config">
        <Config />
      </Tab>
    </Tabs>
  </SimpleCard>
);

export default Settings;
