import { useSelector, useDispatch } from "react-redux";
import Icon from "../../components/ui/Icon";
import { getFormattedDate } from "../../utils/utility";
import Tippy from "@tippyjs/react";

import useClickOutside from "../../packages/_utils/useClickOutside";
import { setCloseContextMenu } from "../../store/explorerActions";

const File = (props) => {
  const { data } = props;
  const dispatch = useDispatch();

  const loader = useSelector((state) => state.explorer.loader);

  let domNode = useClickOutside(() => {
    dispatch(setCloseContextMenu(true));
  });

  const getIcon = (icon) => {
    let color;
    switch (icon) {
      case "Excel":
        color = "text-green-800";
        break;
      case "Word":
        color = "text-blue-800";
        break;
      case "PowerPoint":
        color = "text-orange-800";
        break;
      case "Text":
        color = "text-gray-800";
        break;
      default:
        color = "text-primary";
        break;
    }

    return (
      <Icon iconName={icon ?? "Document"} className={`h-6 w-6 ${color}`} />
    );
  };

  return (
    <Tippy
      arrow={true}
      content={
        <span className="bg-secondary-2 text-sm rounded px-2 py-1 shadow text-white">
          {data.fileName}
        </span>
      }
      delay={[200, 0]} // delay by 200 url give the previous tippy time url close
    >
      <div
        ref={domNode}
        className={`${
          loader.loading && +loader.id === +data.fileId
            ? "bg-danger"
            : "bg-white hover:bg-tertiary dark:hover:bg-tertiary/50"
        } ignore-click-outside transition-colors duration-300 ease-in-out use-context w-full text-typography-1 px-2 py-2 group  overflow-hidden cursor-pointer`}
        data-name={data.fileName}
        data-url={data.fileUrl || data.fileDownloadPath}
        data-action={data.fileUrl ? "Visit" : "Download"}
        data-file-id={data.fileId}
        data-file-name={data.fileName}
      >
        <div className="flex flex-1 items-center space-x-2 relative">
          <div className="w-12 h-12 flex items-center justify-center text-primary">
            {getIcon(data?.fileIcon)}
          </div>
          <div className="flex-1 overflow-hidden">
            <p className="text-typography-1 flex-1 whitespace-nowrap overflow-hidden text-ellipsis">
              {data?.fileName}
            </p>
            <div className="text-xs flex flex-1 items-center justify-between">
              <span>
                {data?.fileAuditDateTime
                  ? getFormattedDate(
                      data.fileAuditDateTime.substring(0, 16),
                      true
                    )
                  : "Unknown"}
              </span>
              <span>
                User:&nbsp;
                {data?.fileAuditUsername ? data?.fileAuditUsername : "Unknown"}
              </span>
            </div>
          </div>
        </div>
      </div>
    </Tippy>
  );
};

export default File;
