import { getRequest, postRequest, putRequest, deleteRequest } from "../axios";
import { eoplCommentsActions } from "./eoplCommentsSlice";
import Toast from "../components/ui/Toast";
// import { getProgramInfo } from "./programInfoActions";

export const resetComments = () => {
  return async (dispatch) => {
    dispatch(eoplCommentsActions.replaceEoplComments([]));
  };
};

export const resetToggleId = () => {
  return async (dispatch) => {
    dispatch(eoplCommentsActions.resetToggleId());
  };
};
export const setToggleRowId = (value) => {
  return async (dispatch) => {
    dispatch(eoplCommentsActions.toggleRowId(value));
  };
};

export const sortComments = (comments) => {
  return comments.sort(
    (a, b) =>
      new Date(a.auditDateTime).getTime() - new Date(b.auditDateTime).getTime()
  );
};

export const getEoplComments = (eoplProgramId) => {
  return async (dispatch) => {
    try {
      let response;
      if (typeof eoplProgramId === "number") {
        response = await getRequest(`comments/id/${eoplProgramId}/`);
      } else {
        response = await getRequest(`comments/programIdRef/${eoplProgramId}/`);
      }
      if (response?.message === "No comments found for programme") {
        dispatch(eoplCommentsActions.replaceEoplComments());
      } else {
        dispatch(eoplCommentsActions.replaceEoplComments(response));
      }
    } catch (error) {
      console.error("getEoplComment: " + error.message);
      if (
        error.message.substring(0, 14) === "Request failed" ||
        error.message.substring(0, 2) === "40"
      ) {
        dispatch(eoplCommentsActions.setLoaderAdd(false));
      }
      if (
        error.message.substring(0, 14) !== "Request failed" &&
        error.message.substring(0, 2) !== "40"
      )
        Toast({ status: "error", message: error.message });
    }
  };
};

export const createEoplComment = (eoplCommentData, isReply) => {
  return async (dispatch) => {
    try {
      dispatch(eoplCommentsActions.setLoaderAdd(true));

      let eoplComment;

      if (isReply) {
        eoplComment = await postRequest("commentsReply/", eoplCommentData);
      } else {
        eoplComment = await postRequest("comments/", eoplCommentData);
      }

      dispatch(
        eoplCommentsActions.updateEoplComment({
          comment: eoplComment,
          isReply,
        })
      );

      Toast({ status: "success", message: "Comment added" });
    } catch (error) {
      Toast({ status: "error", message: error.message });
    } finally {
      dispatch(eoplCommentsActions.setLoaderAdd(false));
    }
  };
};

export const updateEoplComment = (eoplCommentData, isReply) => {
  return async (dispatch) => {
    try {
      dispatch(eoplCommentsActions.setLoaderAdd(true));

      let response;
      let commentId;
      if (isReply) {
        commentId = eoplCommentData?.id;
        response = await putRequest(
          `commentsReply/${commentId}/`,
          eoplCommentData
        );
      } else {
        commentId = eoplCommentData?.id;
        response = await putRequest(`comments/${commentId}/`, eoplCommentData);
      }

      Toast({ status: "success", message: response.message });
      dispatch(
        eoplCommentsActions.updateEoplComment({
          comment: response,
          isReply,
        })
      );
    } catch (error) {
      // console.error("updateEoplComment: " + error.message)
      Toast({ status: "error", message: error.message });
    } finally {
      // dispatch(eoplCommentsActions.setLoaderAdd(false));
    }
  };
};

export const deleteEoplComment = (id, isReply) => {
  return async (dispatch) => {
    try {
      dispatch(eoplCommentsActions.setLoaderDelete(true));
      let response;
      if (isReply) {
        response = await deleteRequest(`commentsReply/${id}/`);
      } else {
        response = await deleteRequest(`comments/${id}/`);
      }
      dispatch(eoplCommentsActions.removeEoplComment({ id, isReply }));
      Toast({ status: "success", message: response.message });
    } catch (error) {
      Toast({ status: "error", message: error.message });
    } finally {
      dispatch(eoplCommentsActions.setLoaderDelete(false));
    }
  };
};

export const setLoaderAdd = (value) => {
  return async (dispatch) => {
    dispatch(eoplCommentsActions.setLoaderAdd(value));
  };
};
