import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";

import CommentForm from "./CommentForm";

import {
  updateEoplComment,
  deleteEoplComment,
  createEoplComment,
  setLoaderAdd,
} from "../../store/eoplCommentsActions";
import { getFormattedDate } from "../../utils/utility";
import Button from "../../packages/button/Button";
import {
  AnnotationIcon,
  ReplyIcon,
  UserCircleIcon,
  TrashIcon,
  PencilIcon,
} from "@heroicons/react/solid";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "../../packages/modal/Modal";
import useToggle from "../../packages/_utils/useToggle";
import SpinnerIcon from "../../packages/button/SpinnerIcon";
import Toast from "../ui/Toast";
import FormField from "../../pages/eopl/form/FormField";

const Comments = ({ eoplProgramId, commentType, comment }) => {
  const allComments = useSelector(
    (state) => state.eoplComments.allDataComments
  );
  const profileData = useState("View");
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.profile.profilesData);
  // const loaded = useSelector((state) => state.eoplComments.loaded);

  // add, edit and delete comment loaders
  const loaderAdd = useSelector((state) => state.eoplComments.loaderAdd);
  const loaderDelete = useSelector((state) => state.eoplComments.loaderDelete);
  // const programInfoCommentCounts = useSelector(
  //   (state) => state.programInfo.data.commentCounts
  // );
  const toggleRowId = useSelector((state) => state.eoplComments.toggleRowId);
  // const replyLoading = useSelector((state) => state.eoplComments.replyLoading);

  const [activeComment, setActiveComment] = useState(null);
  const [activeReplyComment, setActiveReplyComment] = useState(null);
  const [allowBruteParentDelete] = useState(false); // allow child comments to be deleted from parent delete
  const [commentCount, setCommentCount] = useState(0);
  // const [showReply, setShowReply] = useState(false);
  const [showComments, setShowComments] = useState(false);
  const [addCommentOpen, setAddCommentOpen] = useState(false);
  const [replyCommentOpen, setReplyCommentOpen] = useState(false);
  const [replyBody, setReplyBody] = useState(null);
  const [isEditing, setIsEditing] = useState(null);
  const [isReplying, setIsReplying] = useState(null);
  const [toggle, isOpen] = useToggle();

  const [filteredComments, setFilteredComments] = useState([]);

  const replyRef = useRef(null); // doesn't work as intended, will fix later
  const firstRef = useRef(null);

  useEffect(() => {
    const headerComment = (comment) =>
      comment.eoplChildPartsId === null &&
      comment.eoplShoppingListId === null &&
      commentType === "headerComment";
    const childPartList = (comment) =>
      comment.eoplChildPartsId !== null && commentType === "childPartList";
    const shoppingList = (comment) =>
      comment.eoplShoppingListId !== null && commentType === "shoppingList";

    if (allComments) {
      let commentsFilter = allComments?.filter((comment) => {
        return (
          headerComment(comment) ||
          childPartList(comment) ||
          shoppingList(comment)
        );
      });

      let totalCommentsCount = 0;

      if (commentType === "childPartList" || commentType === "shoppingList") {
        commentsFilter = commentsFilter.filter(
          (m) =>
            m.eoplChildPartsId === toggleRowId ||
            m.eoplShoppingListId === toggleRowId
        );

        totalCommentsCount = commentsFilter
          .filter(
            (m) =>
              m.eoplChildPartsId === toggleRowId ||
              m.eoplShoppingListId === toggleRowId
          )
          .reduce((count, comment) => {
            return count + 1 + (comment?.replies ? comment.replies.length : 0);
          }, 0);
      } else {
        totalCommentsCount = commentsFilter.reduce((count, comment) => {
          return count + 1 + (comment?.replies ? comment.replies.length : 0);
        }, 0);
      }

      setFilteredComments(commentsFilter);
      setCommentCount(totalCommentsCount);

      if (
        totalCommentsCount > 0 &&
        !showComments &&
        (commentType === "childPartList" || commentType === "shoppingList")
      )
        setShowComments(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commentType, allComments, commentCount.length, toggleRowId]);

  useEffect(() => {
    if (loaderAdd) return;
    setAddCommentOpen(loaderAdd);
    setReplyCommentOpen(loaderAdd);
    setIsEditing(false);
    setIsReplying(false);
  }, [loaderAdd]);

  useEffect(() => {
    if (showComments) firstRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [showComments]);

  const commentWithoutMention = (comment) => {
    if (!comment) return;
    // const pattern = new RegExp(/(\@.*?\[)(.*?(?=]))(.*?\))/g);
    const mentionPattern = new RegExp(/(\@.*?\))/g); // eslint-disable-line no-useless-escape
    // const fullNameMention = new RegExp(/(\@.*?\])(.*?\))/g);
    const fullNamePattern = new RegExp(/(\@.*?\])/g); // eslint-disable-line no-useless-escape
    const emailPattern = new RegExp(/[^(]+(?=\))/g); // eslint-disable-line no-useless-escape

    let parts = comment.body.split(mentionPattern);
    for (let i = 0; i < parts.length; i++) {
      var match = parts[i].match(mentionPattern);
      if (match) {
        var email = parts[i].match(emailPattern);
        var userName = parts[i].match(fullNamePattern);
        parts[i] = (
          <a key={"link" + i} href={`mailto:${email}`}>
            {userName}
          </a>
        );
      }
    }

    if (replyCommentOpen) {
      const stringParts = parts.reduce((acc, x, i) => {
        if (x !== "" && !x.props) {
          acc.push(x);
        } else if (x?.props) {
          acc.push(x.props.children[0]);
        }
        return acc;
      }, []);
      return stringParts.join("");
    }
    return parts;
  };

  const addComment = async (text) => {
    const angularBracketsRegex = /^[^<>|=]*$/;
    if (!angularBracketsRegex.test(text)) {
      Toast({ status: "warning", message: "Invalid Characters in Comments" });
      return;
    }
    let newComment = {};
    if (commentType === "headerComment") {
      newComment = {
        eoplProgramId: eoplProgramId,
        body: text,
        eoplShoppingListId: null,
        eoplChildPartsId: null,
      };
    } else if (commentType === "shoppingList") {
      newComment = {
        eoplProgramId: eoplProgramId,
        body: text,
        eoplShoppingListId: comment.id,
        eoplChildPartsId: null,
      };
    } else if (commentType === "childPartList") {
      newComment = {
        eoplProgramId: eoplProgramId,
        body: text,
        eoplShoppingListId: null,
        eoplChildPartsId: comment.id,
      };
    }

    dispatch(createEoplComment(newComment));
  };

  const deleteComment = (eoplCommentId, isReply) => {
    dispatch(deleteEoplComment(eoplCommentId, isReply));
    toggle();
  };

  const updateComment = (text, eoplCommentId) => {
    const updatedComment = {
      body: text,
      id: eoplCommentId,
      eoplProgramId: eoplProgramId,
    };

    if (activeReplyComment)
      updatedComment.CommentId = activeReplyComment?.CommentId;

    dispatch(updateEoplComment(updatedComment, !!activeReplyComment?.id));
  };

  const replyComment = async (text, replyId) => {
    const angularBracketsRegex = /^[^<>|=]*$/;
    if (!angularBracketsRegex.test(text)) {
      Toast({ status: "warning", message: "Invalid Characters in Comments" });
      return;
    }
    const newReply = {
      body: text,
      CommentId: activeReplyComment?.CommentId || activeComment?.id,
    };

    dispatch(createEoplComment(newReply, true));
  };

  const getReplyingToMessage = (comment) => {
    const childComment = allComments.find((m) =>
      m.replies.find((n) => n.id === activeReplyComment?.id)
    );

    return (
      <i>
        <span>Replying to:</span>
        <span className="font-bold"> {comment?.auditUserId?.name}</span>
        {childComment && (
          <>
            <span className="pdl-1">, from original thread started by: </span>
            <span className="font-bold">
              {comment?.auditUserId?.name} on{" "}
              {getFormattedDate(comment?.auditDateTime, true)}
            </span>
          </>
        )}
      </i>
    );
  };

  const getEditReplyDeleteState = (comment) => {
    const commentUserName = comment.auditUserId?.name;
    // if (!commentUserName) return;

    // Define the regular expression to match the part inside the parentheses
    const userNamePattern = /\(([^)]+)\)/;

    // Extract the username using the match method
    const match = commentUserName.match(userNamePattern);
    const extractedUserName = match ? match[1].trim().toLowerCase() : "";
    const currentUserName = currentUser.username.trim().toLowerCase();

    const canDelete =
      currentUser.role !== "view" &&
      currentUserName === extractedUserName &&
      (!comment?.replies ||
        comment?.replies.length === 0 ||
        allowBruteParentDelete) &&
      !isReplying &&
      !isEditing;

    const canReply =
      currentUser.role !== "view" &&
      Boolean(currentUserName) &&
      !isReplying &&
      !isEditing;

    const canEdit =
      currentUser.role !== "view" &&
      currentUserName === extractedUserName &&
      !isReplying &&
      !isEditing;

    return { canDelete: canDelete, canReply: canReply, canEdit: canEdit };
  };

  const getCommentType = (comment) => {
    let commentType = "";

    if (comment?.eoplShoppingListId) {
      commentType = "comment from Shopping List";
    } else if (comment?.eoplChildPartsId) {
      commentType = "comment from Child Parts List";
    } else {
      commentType = "Program comment";
    }

    if (commentType === "Program comment") return null;
    return (
      <span className="ml-4 text-xs italic text-gray-500">({commentType})</span>
    );
  };

  const replies = (data) => {
    return data.map((x) => {
      const { canDelete, canReply, canEdit } = getEditReplyDeleteState(x);
      return (
        <div key={x.id} className="my-1 mfax-w-fit">
          <div className="px-5">
            <div
              className={`shadow-sm rounded p-2 border !border-secondary-3 ${
                isEditing && x?.id === activeReplyComment?.id
                  ? "bg-tertiary-warm-4/60"
                  : "bg-white"
              }`}
            >
              <div className="flex items-center pb-1">
                <UserCircleIcon className="w-8 h-8 text-secondary-2 mr-2" />
                <div className="font-bold mr-4">{x.auditUserId.name}</div>
                <div className="text-xs text-gray-500">
                  {getFormattedDate(x.auditDateTime, true)}
                </div>
              </div>

              {(!isEditing || activeReplyComment?.id !== x?.id) && (
                <>
                  <div className="border-b rounded p-2 leading-relaxed">
                    <p className="whitespace-pre-wrap">{x.body}</p>
                  </div>
                  <div className="flex pt-1 h-6">
                    {/* {canReply && ( */}
                    <div
                      className={`${
                        canReply
                          ? "pointer-events-auto text-info/80"
                          : "Xpointer-events-none text-info/50"
                      } select-none mr-4 cursor-pointer hover:text-info text-xs flex w-fit items-center`}
                      onClick={() => {
                        if (profileData.role === "View") return;
                        setActiveComment(null);
                        setActiveReplyComment({
                          id: x?.id,
                          CommentId: x?.CommentId,
                          type: "replying",
                        });
                        setReplyBody({
                          id: x?.id,
                          body: x?.body.replace(/\n/g, "<br />"),
                        });
                        setIsReplying(!isReplying);
                        setReplyCommentOpen(!replyCommentOpen);
                      }}
                    >
                      Reply to thread
                      <ReplyIcon className="h-4 pl-2" />
                    </div>
                    {/* )} */}
                    {/* {canEdit && ( */}
                    <div
                      className={`${
                        canEdit
                          ? "pointer-events-auto text-info/80"
                          : "pointer-events-none text-info/50"
                      } select-none mr-4 cursor-pointer hover:text-info text-xs flex w-fit items-center`}
                      onClick={() => {
                        if (profileData.role === "View") return;
                        setActiveComment(null);
                        setActiveReplyComment({
                          id: x.id,
                          CommentId: x?.CommentId,
                          type: "editing",
                        });
                        setIsEditing(true);
                        setIsReplying(false);
                        setAddCommentOpen(false);
                        setReplyCommentOpen(false);
                      }}
                    >
                      Edit
                      <PencilIcon className="h-4 pl-2" />
                    </div>
                    {/* )} */}
                    {/* {canDelete && ( */}
                    <div
                      className={`${
                        canDelete
                          ? "pointer-events-auto text-danger/80"
                          : "pointer-events-none text-danger/50"
                      } select-none mr-4 cursor-pointer hover:text-danger text-xs flex w-fit items-center`}
                      onClick={() => {
                        if (profileData.role === "View") return;
                        setActiveComment(null);
                        setActiveReplyComment({
                          id: x.id,
                          CommentId: x?.CommentId,
                          type: "deleting",
                        });
                        toggle();
                        setAddCommentOpen(false);
                        setReplyCommentOpen(false);
                      }}
                    >
                      Delete
                      {loaderDelete && activeReplyComment?.id === x?.id ? (
                        <SpinnerIcon type="error" className="ml-2" />
                      ) : (
                        <TrashIcon className="h-4 pl-2" />
                      )}
                    </div>
                    {/* )} */}
                  </div>
                </>
              )}
              {/* CommentForm for child comment */}
              {isEditing && activeReplyComment?.id === x?.id && (
                <CommentForm
                  submitLabel="Update"
                  hasCancelButton
                  initialText={x?.body}
                  handleSubmit={(text) => updateComment(text, x?.id)}
                  handleCancel={() => {
                    setAddCommentOpen(false);
                    setReplyCommentOpen(false);
                    setIsEditing(false);
                    setIsReplying(false);
                    dispatch(setLoaderAdd(false));
                  }}
                />
              )}
            </div>
          </div>
        </div>
      );
    });
  };

  return (
    <>
      <div
        ref={firstRef}
        className="flex items-center px-4 py-2 bg-primary dark:bg-secondary-3 text-typography-2 text-white h-10"
      >
        <h3
          className={`font-semibold ${
            commentType === "headerComment" ? "w-52" : "pr-6"
          }`}
        >
          Comments ({commentCount})
        </h3>
        <div
          className={`${
            commentType !== "headerComment" &&
            toggleRowId === 0 &&
            "pointer-events-none"
          } flex gap-x-2 text-xs font-bold items-center text-white flex-row-reversse cursor-pointer`}
          onClick={() => setShowComments(!showComments)}
        >
          <FormField
            label={`${showComments === false ? "Show" : "Hide"} Comments`}
            labelClassName={`${
              commentType === "headerComment" ? "!w-36" : "pr-2"
            }`}
            name="showComments"
            type="checkbox"
            checked={showComments}
            onClick={() => setShowComments(!showComments)}
            disabled={commentType !== "headerComment" && toggleRowId === 0}
          />
        </div>
      </div>
      <div
        className={`${
          !showComments && "hidden"
        } px-4 py-2 border text-sm !border-secondary-3 shadow-sm bg-primary/30 dark:bg-secondary-3-5`}
      >
        {!filteredComments
          ? null
          : filteredComments.map((x, i) => {
              const { canDelete, canReply, canEdit } =
                getEditReplyDeleteState(x);
              return (
                <div key={x?.id} className="mb-2 mfax-w-fit">
                  {/* <span style={{fontSize: '12px'}}>Date here</span> */}
                  <div
                    className={`shadow-sm rounded p-2 border !border-secondary-3 ${
                      isEditing && x?.id === activeComment?.id
                        ? "bg-tertiary-warm-4/60"
                        : "bg-white"
                    }`}
                  >
                    <div className="flex items-center pb-1">
                      <UserCircleIcon className="w-8 h-8 text-secondary-2 mr-2" />
                      <div className="font-bold mr-4">
                        {x?.auditUserId?.name}
                      </div>
                      <div className="text-xs text-gray-500">
                        {getFormattedDate(x?.auditDateTime, true)}
                      </div>
                      {getCommentType(x)}
                    </div>

                    {(!isEditing || activeComment?.id !== x?.id) && (
                      <>
                        <div className="border-b rounded p-2 leading-relaxed">
                          <span className="whitespace-pre-wrap">
                            {commentWithoutMention(x)}
                          </span>
                        </div>
                        <div className="flex pt-1 h-6">
                          {/* {canReply && ( */}
                          <div
                            className={`${
                              canReply
                                ? "pointer-events-auto text-info/80"
                                : "Xpointer-events-none text-info/50"
                            } select-none mr-4 cursor-pointer hover:text-info text-xs flex w-fit items-center`}
                            onClick={() => {
                              if (profileData.role === "View") return;
                              setActiveComment({ id: x?.id });
                              setActiveReplyComment(null);
                              setReplyBody({
                                id: x?.id,
                                body: x?.body.replace(/\n/g, "<br />"),
                              });
                              // setIsReplying(true);
                              // setReplyCommentOpen(true);
                              setIsReplying(!isReplying);
                              setReplyCommentOpen(!replyCommentOpen);
                              // replyRef.current?.scrollIntoView({
                              //   behavior: "smooth",
                              // });

                              //   const yOffset = -100; // Adjust this value to add 100px offset
                              //   const y =
                              //     replyRef.current.getBoundingClientRect().top +
                              //     window.pageYOffset +
                              //     yOffset;
                              //   window.scrollTo({ top: y, behavior: "smooth" });
                            }}
                          >
                            Reply
                            <ReplyIcon className="h-4 pl-2" />
                          </div>
                          {/* )}
                      {canEdit && ( */}
                          <div
                            className={`${
                              canEdit
                                ? "pointer-events-auto text-info/80"
                                : "pointer-events-none text-info/50"
                            } select-none mr-4 cursor-pointer hover:text-info text-xs flex w-fit items-center`}
                            onClick={() => {
                              if (profileData.role === "View") return;
                              setActiveComment({
                                id: x.id,
                                message: x.body,
                                type: "editing",
                              });
                              setActiveReplyComment(null);
                              setIsEditing(true);
                              setIsReplying(false);
                              setAddCommentOpen(false);
                              setReplyCommentOpen(false);
                            }}
                          >
                            Edit
                            <PencilIcon className="h-4 pl-2" />
                          </div>
                          {/* )}
                      {canDelete && ( */}
                          <div
                            className={`${
                              canDelete
                                ? "pointer-events-auto text-danger/80"
                                : "pointer-events-none text-danger/50"
                            } select-none mr-4 cursor-pointer hover:text-danger text-xs flex w-fit items-center`}
                            onClick={() => {
                              if (profileData.role === "View") return;
                              setActiveComment({ id: x.id, type: "deleting" });
                              setActiveReplyComment(null);
                              toggle();
                              setAddCommentOpen(false);
                              setReplyCommentOpen(false);
                            }}
                          >
                            Delete
                            {loaderDelete &&
                            !activeReplyComment &&
                            activeComment.id === x.id ? (
                              <SpinnerIcon type="error" className="ml-2" />
                            ) : (
                              <TrashIcon className="h-4 pl-2" />
                            )}
                          </div>
                          {/* )} */}
                        </div>
                      </>
                    )}
                    {/* CommentForm for parent comment */}
                    {isEditing && activeComment?.id === x?.id && (
                      <CommentForm
                        submitLabel="Update"
                        hasCancelButton
                        initialText={x?.body}
                        handleSubmit={(text) => updateComment(text, x?.id)}
                        handleCancel={() => {
                          setAddCommentOpen(false);
                          setReplyCommentOpen(false);
                          setIsEditing(false);
                          setIsReplying(false);
                          dispatch(setLoaderAdd(false));
                        }}
                      />
                    )}
                  </div>
                  <span>{replies(x.replies)}</span> {/* Child comment */}{" "}
                  {/* {replyLoading && activeComment?.id === x?.id ? (
                <SpinnerIcon type="danger" />
              ) : (
                <p></p>
              )} */}
                  {replyCommentOpen &&
                    (activeComment?.id === x?.id ||
                      x?.replies.find(
                        (m) => m?.id === activeReplyComment?.id
                      )) && (
                      <div className="flex-1 bg-primary/30 px-2 mt-2 pt-2 rounded">
                        {getReplyingToMessage(x)}
                        <p>{commentWithoutMention(replyBody)}</p>
                        {/* <div
                      dangerouslySetInnerHTML={{
                        __html: commentWithoutMention(replyBody.body),
                      }}
                    /> */}
                        {/* CommentForm for replying to the parent comment (unable to directly reply to child comments) */}
                        <div ref={replyRef}>
                          <CommentForm
                            submitLabel="Reply"
                            handleSubmit={replyComment}
                            hasCancelButton
                            handleCancel={() => {
                              setAddCommentOpen(false);
                              setReplyCommentOpen(false);
                              setIsEditing(false);
                              setIsReplying(false);
                              dispatch(setLoaderAdd(false));
                            }}
                          />
                        </div>
                      </div>
                    )}
                </div>
              );
            })}

        {currentUser.role !== "View" ? (
          <Button
            className="mt-4 text-sm flex items-center"
            type="button"
            onClick={() => {
              setActiveComment(null);
              setActiveReplyComment(null);
              setAddCommentOpen(true);
              setReplyCommentOpen(false);
            }}
            disabled={profileData.role === "View"}
          >
            {" "}
            {loaderAdd && !activeComment && !activeReplyComment ? (
              <SpinnerIcon type="danger" />
            ) : (
              <AnnotationIcon className="h-4 pr-2" />
            )}
            Add Comment
          </Button>
        ) : null}
        {addCommentOpen && (
          <div className="flex-1 bg-primary/30 px-2 mt-2 pt-2 rounded">
            {/* CommentForm for adding a new comment */}
            <CommentForm
              submitLabel="Add"
              handleSubmit={(text) => addComment(text)}
              hasCancelButton
              handleCancel={() => {
                setAddCommentOpen(false);
                setReplyCommentOpen(false);
                setIsEditing(false);
                setIsReplying(false);
                dispatch(setLoaderAdd(false));
              }}
            />
          </div>
        )}
      </div>

      <Modal
        isOpen={isOpen}
        toggle={toggle}
        animate={true}
        closeOnClickOutside={true}
      >
        <ModalHeader>Delete Comment</ModalHeader>
        <ModalBody>
          <div className="d-flex flex-row bd-highlight mb-3">
            <div className="p-2 bd-highlight">
              <p>Are you sure you want to remove this comment?</p>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={toggle}
            color="danger"
            className="ignore-click-outside"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              if (activeReplyComment) {
                return deleteComment(activeReplyComment.id, true);
              } else {
                return deleteComment(activeComment.id);
              }
            }}
            color="primary"
            className="mr-1 ignore-click-outside"
          >
            Confirm
          </Button>
        </ModalFooter>
      </Modal>
      {/* <div ref={replyRef}></div> */}
    </>
  );
};

export default Comments;

Comments.propTypes = {
  handleSubmit: PropTypes.func,
  eoplProgramId: PropTypes.number,
  commentsData: PropTypes.array,
  commentType: PropTypes.string,
  info: PropTypes.string,
};
