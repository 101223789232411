import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import useToggle from "../../../packages/_utils/useToggle";
import {
  getProgramById,
  resetEoplData,
  setChanged,
} from "../../../store/eoplActions";
import { resetComments } from "../../../store/eoplCommentsActions";
import { resetShoppingListData } from "../../../store/shoppingListActions";
import { resetChildPartsData } from "../../../store/childPartListActions";
import SpinnerIcon from "../../../packages/button/SpinnerIcon";

import MainDetails from "./MainDetails";
import ShoppingListAdd from "./ShoppingListAdd";
import ChildPartListAdd from "./ChildPartListAdd";
import ProgramDetails from "./ProgramForm";

const EOPLForm = (props) => {
  const { eoplProgramId, flag, closeDrawer } = props;
  const eopl = useSelector((state) => state.eopl.data);
  // const allData = useSelector((state) => state.eopl.allData);
  // const uploaded = useSelector((state) => state.eopl.uploaded);
  // const changed = useSelector((state) => state.eopl.changed);
  const loading = useSelector((state) => state.global.loading);
  const dispatch = useDispatch();
  const [currentStep, setCurrentStep] = useState(0);
  // store the eopl data and update it before mapping back to the store
  const [data, setData] = useState(eopl); // we set this here for the custom program blank form
  const [toggle] = useToggle();

  useEffect(() => {
    if (!eoplProgramId || flag !== "programList") return;
    // console.log("Getting Programme : ", eoplProgramId);
    dispatch(getProgramById(eoplProgramId));
  }, [dispatch, eoplProgramId, flag]);

  useEffect(() => {
    if (eopl?.id) {
      // console.log("Setting local data from store", eopl);
      setData(eopl);
    }
  }, [eopl]);

  useEffect(() => {
    return () => {
      // console.log("Unmounting EOPL Form");
      dispatch(setChanged(false));
      dispatch(resetEoplData());
      dispatch(resetChildPartsData());
      dispatch(resetShoppingListData());
      dispatch(resetComments());
    };
  }, [dispatch]);

  // const getEoplData = useCallback(() => {
  //   return eopl;
  // }, [eopl]);

  // useEffect(() => {
  //   if ((changed || !eopl?.id) && !uploaded) {
  //     if (allData && !allData?.id && !eopl?.id) return;
  //     setData(eopl);
  //     if (changed) {
  //       dispatch(setLoading());
  //     }
  //   }
  // }, [eopl, changed, dispatch, uploaded, allData]);

  const handleNextStep = (newData, final = false) => {
    setData((prev) => ({ ...prev, ...newData }));
    if (final) {
      toggle();
      return;
    }
    setCurrentStep((prev) => prev + 1);
  };

  const handleLastStep = (newData, final = false) => {
    setData((prev) => ({ ...prev, ...newData }));
    if (final) {
      toggle();
      return;
    }
    setCurrentStep((prev) => prev + 2);
  };

  const handlePrevStep = (newData) => {
    setCurrentStep(0);
  };
  const handleFirstStep = (newData) => {
    setCurrentStep(0);
  };
  const handleShoppingStep = (newData) => {
    setCurrentStep(1);
  };

  const stepTitles = ["Programme Details", "Shopping List", "Child Parts List"];
  let stepArray = [];

  if (flag === "programList") {
    stepArray = [
      <MainDetails
        data={data}
        next={handleNextStep}
        last={handleLastStep}
        closeDrawer={closeDrawer}
      />,
      <ShoppingListAdd
        data={data}
        prev={handlePrevStep}
        next={handleNextStep}
      />,
      <ChildPartListAdd
        data={data}
        prev={handleFirstStep}
        next={handleShoppingStep}
      />,
    ];
  } else {
    stepArray = [
      <ProgramDetails
        data={data}
        next={handleNextStep}
        last={handleLastStep}
      />,
    ];
  }
  const steps = stepArray;

  return (
    <>
      {data && (
        <div className="shadow-sm overflow-hidden sm:rounded-md min-w-full max-w-fit">
          <div className="px-4 py-2 bg-primary dark:bg-secondary-3 text-left sm:px-6 rounded-t">
            <h2 className="text-typography-2 font-semibold flex items-center">
              {stepTitles[currentStep]} &gt;{" "}
              {loading ? (
                <span className="flex pl-2">
                  <span>Please wait...</span>
                  <SpinnerIcon
                    className={
                      "!border-typography-2/20 !border-t-typography-2 ml-2"
                    }
                  />
                </span>
              ) : !eopl?.id ? (
                "New Custom Programme Creation"
              ) : (
                `EOPL Programme : ${eopl?.eoplProgramRefId}`
              )}
            </h2>
          </div>
          {steps[currentStep]}
        </div>
      )}
    </>
  );
};

export default EOPLForm;
