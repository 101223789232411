import Intro from "../components/intro/Intro";
import Jumbotron from "../components/ui/Jumbotron";
import { useContext } from "react";
import { ThemeContext } from "../components/layout/ThemeProvider";

const Home = () => {
  const { theme } = useContext(ThemeContext);

  const getGradientFill = () => {
    let classes = "";

    switch (theme) {
      case "theme2":
        classes =
          "bg-gradient-to-r from-tertiary-warm-3 via-tertiary-warm-4 to-tertiary-warm-2";
        break;
      case "theme3":
        classes =
          "bg-gradient-to-r from-secondary-3 via-secondary-4 to-secondary-2";
        break;
      case "theme4":
        classes = "bg-gradient-to-r from-secondary-1 via-secondary-2 to-black";
        break;
      default:
        classes =
          "bg-gradient-to-r from-tertiary-cool-3 via-bg-primary/30 to-bg-tertiary";
        break;
    }
    return classes;
  };

  return (
    <>
      <Jumbotron
        className={`w-full text-typography-1 bg-primary ${getGradientFill()}`}
      >
        <p className="w-full max-w-fit pb-2 font-bold text-2xl flex tracking-loose items-center">
          Welcome to the End of Production Life application
        </p>
        <div className="relative flex flex-1 w-full h-full lg:w-full justify-center items-center px-6">
          <div
            style={{ backgroundImage: "url(/hero-image-1.png)" }}
            className="border-2 border-primary absolute opacity-80 w-full h-96 top-0 p-12 mx-auto flex flex-col md:flex-row items-center mhy-12 md:mhy-12 bg-no-repeat bg-center bg-cover"
          ></div>
          <div className="text-typography-1 w-full h-96 mdy-12 p-12 top-0 mx-auto flex flex-col md:flex-row items-center justify-center"></div>
          <div className="w-11/12">
            <Intro />
          </div>
        </div>
      </Jumbotron>

      <div className="flex justify-center">
        <p className="bg-primary/30 dark:bg-secondary/70 text-typography-1 dark:text-typography-2 inline py-2 px-6 rounded-lg font-bold font-jlr-emeric-extra-light">
          Please email to&nbsp;
          <a
            href="mailto:ithelp1@jaguarlandrover.com"
            className="text-info dark:text-warning "
          >
            ithelp1@jaguarlandrover.com
          </a>
          &nbsp;or please call Digital Service Desk on Internal{" "}
          <span className="!text-info"> 015 </span>or External
          <span className="!text-info"> +44-8000-280-837 </span> for support.
        </p>
      </div>
    </>
  );
};

export default Home;
