import React, { useState, useEffect, useRef, useCallback } from "react";
import { download_file } from "../../store/eoplActions";
import {
  DownloadIcon,
  EyeIcon,
  TrashIcon,
  XIcon,
} from "@heroicons/react/solid";

import { isImage } from "../../utils/utility";
import {
  getExplorerImage,
  setCloseContextMenu,
  setItemDeleted,
} from "../../store/explorerActions";
import SpinnerIcon from "../../packages/button/SpinnerIcon";
import useToggle from "../../packages/_utils/useToggle";
import { useDispatch, useSelector } from "react-redux";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "../../packages/modal/Modal";
import Button from "../../packages/button/Button";

const ContextMenu = ({ windowName, setDeleteItem }) => {
  const dispatch = useDispatch();

  const closeContextMenu = useSelector(
    (state) => state.explorer.closeContextMenu
  );

  const [visible, setVisible] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [toggleImage, isOpenImage] = useToggle();
  const [updateMenu, setUpdateMenu] = useState(false); // Add state to track context menu updates
  const [menuReady, setMenuReady] = useState(false); // Add state to control final visibility

  const contextData = useRef({ name: "", url: "", action: "" });

  const [hideMenu, setHideMenu] = useState(false);
  const [contextMenuItems, setContextMenuItems] = useState([]);

  const contextMenuRef = useRef(null);

  useEffect(() => {
    if (closeContextMenu) {
      dispatch(setCloseContextMenu(false));
      if (!hideMenu) {
        setHideMenu(true);
      }
    }
  }, [closeContextMenu, dispatch, hideMenu]);

  const handleDownload = useCallback(() => {
    dispatch(download_file(contextData.current.name, contextData.current.url));
  }, [dispatch]);

  const handleDelete = useCallback(() => {
    setDeleteItem({
      fileId: contextData.current.fileId,
      fileName: contextData.current.fileName,
      type: "File",
    });
  }, [setDeleteItem]);

  const handleView = useCallback(() => {
    dispatch(getExplorerImage(contextData.current.fileId));
  }, [dispatch]);

  useEffect(() => {
    if (updateMenu) {
      const contextMenuOptions = [
        {
          label: contextData.current.action,
          icon: <DownloadIcon className="h-5 w-5" />,
          onClick: () => {
            if (contextData.current.action === "Visit") {
              window.open(contextData.current.url, "_blank");
            } else if (contextData.current.action === "Download") {
              handleDownload();
            }
            setHideMenu(true);
          },
        },
      ];

      if (windowName !== "Cycle Plan Report Archive") {
        contextMenuOptions.push({
          label: "Delete",
          icon: <TrashIcon className="h-5 w-5 group-hover:text-danger " />,
          className: "hover:text-danger",
          onClick: () => {
            handleDelete();
            setHideMenu(true);
          },
        });
      }

      // Add "View" option at the beginning if the file is an image
      if (isImage(contextData.current.fileName)) {
        contextMenuOptions.unshift({
          label: "View",
          icon: <EyeIcon className="h-5 w-5" />,
          onClick: () => {
            handleView();
            toggleImage();
            setHideMenu(true);
          },
        });
      }

      setContextMenuItems(contextMenuOptions);
      setUpdateMenu(false); // Reset the updateMenu state
      setMenuReady(true); // Set menuReady to true after updating the options
    }
  }, [
    updateMenu,
    windowName,
    handleDownload,
    handleDelete,
    handleView,
    toggleImage,
  ]);

  const resetHideMenu = useCallback(() => {
    setHideMenu(false);
  }, []);

  const explorerImage = useSelector((state) => state.explorer.explorerImage);
  const loader = useSelector((state) => state.explorer.loader);

  const itemDeleted = useSelector((state) => state.explorer.itemDeleted);

  useEffect(() => {
    if (itemDeleted && isOpenImage) {
      toggleImage();
      dispatch(setItemDeleted(false));
    }
  }, [dispatch, isOpenImage, itemDeleted, toggleImage]);

  useEffect(() => {
    const handleContextMenu = (event) => {
      if (event.shiftKey) return;
      const contextElement = event.target.closest(".use-context");
      if (contextElement) {
        event.preventDefault();

        if (event.type === "contextmenu") {
          // Right-click
          setPosition({ x: event.pageX, y: event.pageY });
        } else if (event.type === "click") {
          // Left-click
          const rect = contextElement.getBoundingClientRect();
          const contextmenu = contextMenuRef.current.getBoundingClientRect();
          setPosition({
            x: rect.right - 10,
            y:
              rect.top +
              rect.height / 2 -
              contextmenu.height / 2 +
              window.scrollY,
          });
        }

        setMenuReady(false);

        contextData.current = {
          name: contextElement.getAttribute("data-name"),
          url: contextElement.getAttribute("data-url"),
          action: contextElement.getAttribute("data-action"),
          fileId: contextElement.getAttribute("data-file-id"),
          fileName: contextElement.getAttribute("data-file-name"),
          windowName: windowName,
        };
        setUpdateMenu(true);
      } else {
        setVisible(false);
      }
    };

    const handleClick = (event) => {
      handleContextMenu(event, true);
    };

    document.addEventListener("contextmenu", handleContextMenu);
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
      document.removeEventListener("click", handleClick);
    };
  }, [contextData, windowName]);

  useEffect(() => {
    if (hideMenu) {
      setVisible(false);
      resetHideMenu();
    }
  }, [hideMenu, resetHideMenu]);

  useEffect(() => {
    if (menuReady) {
      setVisible(true);
    }
  }, [menuReady]);

  return (
    <>
      <div
        id="contextMenu"
        ref={contextMenuRef}
        className={`absolute  ${
          visible && menuReady ? "block" : "hidden"
        } z-[500] pointer-events-auto ignore-click-outside`}
        style={{ top: position.y, left: position.x }}
      >
        <ul className="menu flex flex-col rounded-md shadow-xl overflow-hidden bg-white">
          {contextMenuItems &&
            contextMenuItems.map((item, index) => (
              <li
                key={index}
                onClick={() => {
                  item.onClick();
                  setVisible(false); // Hide the context menu after the action
                }}
                className={`${item.className} flex items-center gap-x-4 group cursor-pointer bg-secondary dark:bg-secondary hover:bg-primary/20 transition-all ease-linear dark:hover:bg-primary/20 px-4 py-3 w-full h-full text-typography-1 dark:text-typography-2 relative`}
              >
                {item.icon}
                {item.label}
              </li>
            ))}
        </ul>
      </div>

      <Modal
        isOpen={isOpenImage}
        toggle={toggleImage}
        animate={true}
        closeOnClickOutside={false}
      >
        <ModalHeader>
          <div className="flex justify-between items-center">
            <span>{contextData.current.fileName}</span>
            <XIcon className="h-6 w-6 cursor-pointer" onClick={toggleImage} />
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="text-lg font-semibold rounded-lg p-2 border border-gray-300 flex items-center justify-center w-full p-px min-h-[150px] ">
            <div className="bg-white  ">
              {explorerImage && !loader.loading ? (
                <div className="bg-white  ">
                  <img
                    src={`data:image/png;base64,${explorerImage}`}
                    alt="Uploaded"
                  />
                </div>
              ) : loader.loading ? (
                <div className="flex items-center justify-center ">
                  <SpinnerIcon className={"w-10 h-10 mr-6"} />
                  {loader.label}
                </div>
              ) : (
                <span>No image found for {contextData.current.fileName}</span>
              )}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={handleDownload}
            className="flex items-center !pl-2 !pr-3 ws-40"
          >
            <DownloadIcon className="w-6 h-6 mr-2" />
            Download
          </Button>
          <Button
            onClick={handleDelete}
            className="flex items-center"
            color="danger"
          >
            <TrashIcon className="h-5 w-5 mr-2" />
            Delete
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ContextMenu;
