import { createSlice } from "@reduxjs/toolkit";

export const initialState = "";

const eoplIssueSlice = createSlice({
  name: "eoplIssue",
  initialState: {
    data: initialState,
    shoppingListData: null,
  },
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    resetEoplIssue(state, action) {
      state.data = "";
      state.loading = false;
    },
    replaceEoplIssue(state, action) {
      state.data = action.payload;
    },
    replaceEoplShoppingListData(state, action) {
      state.shoppingListData = action.payload;
    },
    resetEoplData(state, action) {
      state.data = initialState;
      state.changed = false;
      state.loading = false;
    },
    setEoplNumber(state, action) {
      state.storedEopl = action.payload;
    },
  },
});

export const eoplIssueActions = eoplIssueSlice.actions;

export default eoplIssueSlice;
