import { Field, ErrorMessage } from "formik";
import TextError from "./TextError";

import style from "./Styles";

const Input = (props) => {
  const { label, name, className, disabled, labelClass, dirty, ...rest } =
    props;

  return (
    <div className={`${className || style.formControl}`}>
      {label && (
        <label htmlFor={name} className={`${style.label} ${labelClass}`}>
          {label}
        </label>
      )}
      <Field name={name} dirty={dirty} {...rest}>
        {({ field }) => (
          <input
            {...field}
            disabled={disabled}
            className={style.input}
            {...rest}
            value={field.value || ""}
          />
        )}
      </Field>
      <ErrorMessage component={TextError} name={name} />
    </div>
  );
};

export default Input;
