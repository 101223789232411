import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import useWindowSize from "../../packages/_utils/useWindowSize";
import useClickOutside from "../../packages/_utils/useClickOutside";

import menuOptions from "../../data/menu";

import Icon from "../ui/Icon";

const SideNav = (props) => {
  const ref = useRef(null);
  const { sideNavOpen, setSideNavOpen, viewState, setViewState, sideNavPeek } =
    props;
  const [scrollBarVisible, setScrollBarVisible] = useState(false);
  const [width, height] = useWindowSize();

  const peek = sideNavPeek ? "w-16" : "w-0";

  let domNode = useClickOutside(() => {
    if (viewState !== "push" && sideNavOpen) setSideNavOpen(!sideNavOpen);
  });

  const style = {
    sideNav: {
      button:
        "focus:outline-none flex h-16 w-full items-center justify-start focus:bg-secondary/30 focus:text-primary hover:bg-secondary/30 text-secondary transition duration-300 ease-in-out relative",
      icon: `fill-stroke flex flex-none h-full w-16 items-center justify-center p-0`, //${!sideNavOpen && scrollBarVisible ? 'pl-5 pr-3 ' : 'p-4'}
      text: `flex w-full h-full text-base items-center p-2 whitespace-nowrap  ${
        sideNavOpen
          ? "opacity-100"
          : "opacity-0 pointer-events-none transition-opacity duration-300 ease-in-out"
      }`,
    },
    profile: {
      text: "flex-none cursor-pointer text-typography-2 hover:bg-tertiary/60 w-full h-10 w-10 md:p-2 transition-colors duration-300 ease-in-out",
    },
  };

  useEffect(() => {
    const scrollVisible = ref.current.scrollHeight > ref.current.clientHeight;
    if (scrollBarVisible && scrollVisible) return;
    setScrollBarVisible(ref.current.scrollHeight > ref.current.clientHeight);
  }, [width, height, setScrollBarVisible, scrollBarVisible]);

  // eslint-disable-next-line
  const toggleViewState = () => {
    setViewState();
  };

  const sideNavOptions = (group) => {
    return menuOptions
      .filter((m) => m.group === group)
      .map((option, i) => {
        const icons = option.icon.split("|");
        const labels = option.label.split("|");
        let iconName = option.icon;
        let labelName = option.label;

        if (icons.length > 1) {
          if (option.action === "toggleViewState") {
            const viewIndex = viewState === "push" ? 0 : 1;
            iconName = icons[viewIndex];
            labelName = labels[viewIndex];
          }
        }

        let notification;
        const content = (
          <button
            tabIndex={0}
            key={i}
            className={style.sideNav.button}
            onClick={
              option.action
                ? () => {
                    eval(option.action).call(); // eslint-disable-line no-eval
                  }
                : null
            }
          >
            <div className={style.sideNav.icon}>
              <Icon iconName={iconName} className="w-7 h-7" />
            </div>
            {notification}
            <p className={style.sideNav.text}>{labelName}</p>
          </button>
        );

        if (!option.to) return content;

        return (
          <Link
            className={`w-full outline-offset-[-2px]`}
            key={i}
            to={option.to}
            onClick={viewState === "overlay" && sideNavOpen && setSideNavOpen}
            tabIndex={0}
          >
            {content}
          </Link>
        );
      });
  };

  return (
    <div
      ref={domNode}
      id="sideNav"
      className={`h-full fixed top-0 ${
        sideNavOpen ? "!w-72" : `sm:${peek} w-0`
      } transition-width ease-in-out duration-500 z-20`}
    >
      <div className="h-full flex flex-col overflow-hidden">
        <div className="bg-secondary h-16 flex-none"></div>
        <div
          ref={ref}
          className="flex-grow flex flex-row overflow-x-hidden overflow-y-auto bg-primary text-typography-2 scrollbar-thin scrollbar-thumb-gray-700 scrollbar-track-gray-500"
        >
          <div className="flex flex-col flex-1 justify-between sm:w-0 w-0">
            <div className="">{sideNavOptions("side-bar-top")}</div>
            <div className="hidden px-3 my-4X w-full">
              <hr className="bg-secondary w-full" />
            </div>
            <div className="hidden flex-col w-full items-center">
              {sideNavOptions("side-bar-bottom")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

SideNav.propTypes = {
  sideNavOpen: PropTypes.bool,
  setSideNavOpen: PropTypes.func,
  viewState: PropTypes.string,
  setViewState: PropTypes.func,
  setUserAvatar: PropTypes.func,
  changeTheme: PropTypes.func,
  toggleViewState: PropTypes.func,
};

export default SideNav;
