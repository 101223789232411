import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  loader: {
    loading: false,
    label: "",
  },
  itemDeleted: false,
  explorerImage: "",
  closeContextMenu: false,
};

const explorerSlice = createSlice({
  name: "explorer",
  initialState: initialState,
  reducers: {
    setCloseContextMenu(state, action) {
      state.closeContextMenu = action.payload;
    },
    setLoader(state, action) {
      state.loader = action.payload;
    },
    resetExplorer(state) {
      state = initialState;
    },
    updateExplorer(state, action) {
      state.data = action.payload;
      state.loader = initialState.loader;
    },
    setExplorerImage(state, action) {
      state.explorerImage = action.payload;
    },
    setItemDeleted(state, action) {
      state.itemDeleted = action.payload;
    },
  },
});

export const explorerActions = explorerSlice.actions;

export default explorerSlice;
