import React from "react";

const SpinnerIcon = ({ type, className }) => {
  let borderColor;

  switch (type) {
    case "error":
      borderColor = "border-danger/20 border-t-danger";
      break;
    case "success":
      borderColor = "border-success/20 border-t-success";
      break;
    case "warning":
      borderColor = "border-warning/20 border-t-warning";
      break;
    default: /* "info" */
      borderColor = "border-info/20 border-t-info";
  }

  return (
    <div
      className={`flex-none mr-2 h-5 w-5 border-2 ${className} ${borderColor} border-solid rounded-full animate-spin`}
    ></div>
  );
};

export default SpinnerIcon;
