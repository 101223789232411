import { childPartListActions } from "./childPartListSlice";
import axios, {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "../axios";
import Toast from "../components/ui/Toast";
import { globalActions } from "./globalSlice";
import fileDownload from "js-file-download";
import { getFormattedDate } from "../utils/utility";
import { programInfoActions } from "./programInfoSlice";

export const setChanged = (value) => {
  return (dispatch) => {
    dispatch(childPartListActions.setChanged(value));
  };
};

export const setSearchValue = (value) => {
  return (dispatch) => {
    dispatch(childPartListActions.setSearchValue(value));
  };
};

export const resetChildPartsData = () => {
  return (dispatch) => {
    dispatch(childPartListActions.resetChildPartsData());
  };
};

export const resetUploaded = () => {
  return (dispatch) => {
    dispatch(childPartListActions.resetUploaded());
  };
};

export const updateField = (field, value) => {
  return (dispatch) => {
    dispatch(
      childPartListActions.updateSpoData({ field: field, value: value })
    );
  };
};

export const resetCheckForExisting = () => {
  return (dispatch) => {
    dispatch(childPartListActions.existingSpo());
  };
};

export const checkForExisting = (spo) => {
  return async (dispatch) => {
    try {
      const existing = await getRequest("spo/existing/" + spo);
      dispatch(childPartListActions.existingSpo(existing));
    } catch (error) {
      Toast({ status: "error", message: error.message });
    }
  };
};

export const uploadChildPartList = (file, eoplProgramRefId) => {
  return async (dispatch) => {
    const sendRequest = () => {
      const url = `/uploadChildParts/`;
      const formData = new FormData();
      formData.append("excel_file", file);
      formData.append("eoplProgramId", eoplProgramRefId);
      dispatch(
        globalActions.setLoading({
          loading: true,
          label: "Uploading Child Parts List",
        })
      );
      dispatch(childPartListActions.setUploading(true));
      let options = {
        responseType: "blob",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
      };
      if (process.env.REACT_APP_BUILD_TYPE === "DEV") {
        options = {
          ...options,
          auth: {
            username: "devuser",
            password: "devuser",
          },
        };
      }
      return axios
        .post(url, formData, options)
        .then((response) => {
          const blob = new Blob([response?.data], {
            type: "application/vnd.ms-excel",
          });
          fileDownload(
            blob,
            `Child_Part_List_Upload_Report ${getFormattedDate(
              Date.now(),
              true
            )}.xlsx`
          );
          if (response?.status === 201) {
            Toast({
              status: "success",
              message: "Child Parts List Successfully Uploaded",
            });
          } else if (response?.status === 207) {
            Toast({
              status: "warning",
              message: "Partially Uploaded Child Parts List",
            });
          } else if (response?.status === 400) {
            Toast({
              status: "error",
              message: "Child Parts List Upload Failed",
            });
          }
        })
        .catch((e) => {
          Toast({ status: "error", message: "Upload failed " + e.message });
        })
        .finally(() => {
          dispatch(
            globalActions.setLoading({ loading: false, label: "Completed" })
          );
          dispatch(childPartListActions.setUploading());
        });
    };

    try {
      await sendRequest();
    } catch (error) {
      Toast({ status: "error", message: error.message });
    }
  };
};

export const getChildPartList = (eoplProgramRefId, indx) => {
  return async (dispatch) => {
    try {
      dispatch(
        globalActions.setLoading({
          loading: true,
          label: "Getting Child Parts List Data",
        })
      );
      const details = await getRequest(
        "myChildParts/?eoplProgramId=" + eoplProgramRefId
      );
      dispatch(
        childPartListActions.replaceChildPartListData({
          childPartList:
            indx === undefined
              ? details?.map((v) => ({
                  ...v,
                  isSelected: false,
                  isEditable: false,
                })) || []
              : details?.map((currElement) => {
                  if (currElement.currentEpn === indx) {
                    return {
                      ...currElement,
                      isSelected: false,
                      isEditable: false,
                    };
                  } else {
                    return {
                      ...currElement,
                      isSelected: false,
                      isEditable: false,
                    };
                  }
                }),
        })
      );
    } catch (error) {
      Toast({ status: "error", message: "Error in getting Program Details" });
    } finally {
      dispatch(
        globalActions.setLoading({ loading: false, label: "Completed" })
      );
    }
  };
};

export const editChildPartList = (customChildPartListObj, itemId) => {
  return async (dispatch) => {
    try {
      await putRequest(
        "myChildParts/" + itemId + "/",
        customChildPartListObj.constructedObjectForApiUpdate
      );
      // dispatch(childPartListActions.updateChildPartListData({ childPartList: responseData || [] }));
      Toast({ status: "Success", message: "ChildPart List Item Edited" });
    } catch (error) {
      console.error("Edit ChildPart list Item: " + error.message);
      Toast({ status: "error", message: error.message });
    }
  };
};

export const createChildPartList = (childPartData) => {
  return async (dispatch) => {
    try {
      dispatch(
        globalActions.setLoading({
          loading: true,
          label: `Saving Child Parts ${childPartData?.eoplProgramId}`,
        })
      );
      await postRequest("myChildParts/", childPartData);
      dispatch(globalActions.setLoading({ loading: false }));
      Toast({ status: "Success", message: "ChildPart List Items Added" });
    } catch (error) {
      console.error("Add ChildPart list Item: " + error.message);
      Toast({ status: "error", message: error.message });
    }
  };
};

export const deleteChildPartItem = (item) => {
  return async (dispatch) => {
    try {
      dispatch(setSearchValue());
      const response = await deleteRequest(`myChildParts/${item.id}/`);
      Toast({ status: "success", message: response.message });
      dispatch(childPartListActions.removeSpoAllData(item.id));
      dispatch(setSearchValue(item));
    } catch (error) {
      console.error("updateUser: " + error.message);
      Toast({ status: "error", message: error.message });
    }
  };
};

export const email = (type) => {
  let url = "";

  if (type === "pdf") url = "spo/sendgridpdf";
  if (type === "excel") url = "spo/sendgrid";

  return async (dispatch) => {
    const sendRequest = async () => {
      Toast({ status: "info", message: `${url}` });
      return axios
        .get(url)
        .then((response) => {
          if (response.status === 200) {
            Toast({ status: "success", message: `${response.data}` });
            return response.data;
          }
          throw new Error(response.data.message);
        })
        .catch((error) => {
          throw new Error(error.message);
        });
    };

    try {
      await sendRequest();
    } catch (error) {
      Toast({ status: "error", message: error.message });
    }
  };
};

export const deleteChildPartsList = (eoplProgramId) => {
  return async (dispatch) => {
    try {
      dispatch(
        globalActions.setLoading({
          loading: true,
          label: "Deleting Child Parts List",
          type: "warning",
        })
      );
      const response = await deleteRequest(
        `myChildParts/deleteAllByEoplProgramId/?eoplProgramId=${eoplProgramId}`
      );
      Toast({ status: "success", message: response.message });
      dispatch(resetChildPartsData());
      dispatch(globalActions.setLoading({ loading: false }));
      dispatch(programInfoActions.removeImages("childPartsList"));
    } catch (error) {
      console.error("updateUser: " + error.message);
      Toast({ status: "error", message: error.message });
    } finally {
      dispatch(globalActions.setLoading({ loading: false }));
    }
  };
};
