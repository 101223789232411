import { eoplActions } from "./eoplSlice";
import axios, {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "../axios";
import Toast from "../components/ui/Toast";
import { globalActions } from "./globalSlice";
import fileDownload from "js-file-download";
import { getFormattedDate } from "../utils/utility";

export const setLoading = (value) => {
  return (dispatch) => {
    dispatch(eoplActions.setLoading(value));
  };
};

export const setChanged = (value) => {
  return (dispatch) => {
    dispatch(eoplActions.setChanged(value));
  };
};

export const resetEoplData = () => {
  return (dispatch) => {
    dispatch(eoplActions.resetEoplData());
  };
};

export const setSearchValue = (value) => {
  return (dispatch) => {
    dispatch(eoplActions.setSearchValue(value));
  };
};

export const replaceEoplAllData = () => {
  return (dispatch) => {
    dispatch(eoplActions.setSearchValue());
    dispatch(eoplActions.replaceEoplAllData());
  };
};

export const resetUploaded = () => {
  return (dispatch) => {
    dispatch(eoplActions.resetUploaded());
  };
};

export const updateField = (field, value) => {
  return (dispatch) => {
    dispatch(eoplActions.updateEoplData({ field: field, value: value }));
  };
};

export const checkForExisting = (spo) => {
  return async (dispatch) => {
    try {
      const existing = await getRequest("spo/existing/" + spo);
      dispatch(eoplActions.existingSpo(existing));
    } catch (error) {
      Toast({ status: "error", message: error.message });
    }
  };
};

export const uploadCyclePlan = (file) => {
  return async (dispatch) => {
    const sendRequest = () => {
      const url = `/uploadProgramList/`;
      const formData = new FormData();
      formData.append("excel_file", file);
      dispatch(
        globalActions.setLoading({
          loading: true,
          label: "Uploading Cycle Plan",
        })
      );
      dispatch(eoplActions.setUploading(true));
      let options = {
        responseType: "blob",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
      };
      if (process.env.REACT_APP_BUILD_TYPE === "DEV") {
        options = {
          ...options,
          auth: {
            username: "devuser",
            password: "devuser",
          },
        };
      }
      return axios
        .post(url, formData, options)
        .then((response) => {
          const blob = new Blob([response?.data], {
            type: "application/vnd.ms-excel",
          });
          fileDownload(
            blob,
            `Cycle_Plan_Uploaded_Report ${getFormattedDate(
              Date.now(),
              true
            )}.xlsx`
          );
          if (response?.status === 201) {
            Toast({
              status: "success",
              message: "Cycle Plan Successfully Uploaded",
            });
          } else if (response?.status === 207) {
            Toast({
              status: "warning",
              message: "Partially Uploaded Cycle Plan",
            });
          } else if (response?.status === 400) {
            Toast({
              status: "error",
              message: "Cycle Plan Upload Failed",
            });
          }
        })
        .catch((e) => {
          Toast({ status: "error", message: "Upload failed " + e.message });
        })
        .finally(() => {
          dispatch(
            globalActions.setLoading({ loading: false, label: "Completed" })
          );
          dispatch(eoplActions.setUploading());
        });
    };

    try {
      await sendRequest();
    } catch (error) {
      Toast({ status: "error", message: error.message });
    }
  };
};

export const cyclePlanAutomationUpload = () => {
  return async (dispatch) => {
    const sendRequest = () => {
      const url = `/cyclePlanAutomation/`;
      dispatch(
        globalActions.setLoading({
          loading: true,
          label: "Uploading Cycle Plan",
        })
      );
      dispatch(eoplActions.setUploading(true));
      let options = {
        responseType: "blob",
        withCredentials: true,
      };
      if (process.env.REACT_APP_BUILD_TYPE === "DEV") {
        options = {
          ...options,
          auth: {
            username: "devuser",
            password: "devuser",
          },
        };
      }
      return axios
        .post(url, {}, options)
        .then((response) => {
          const blob = new Blob([response?.data], {
            type: "application/vnd.ms-excel",
          });
          fileDownload(
            blob,
            `Cycle_Plan_Uploaded_Report ${getFormattedDate(
              Date.now(),
              true
            )}.xlsx`
          );
          if (response?.status === 201) {
            Toast({
              status: "success",
              message: "Cycle Plan Successfully Uploaded",
            });
          } else if (response?.status === 207) {
            Toast({
              status: "warning",
              message: "Partially Uploaded Cycle Plan",
            });
          } else if (response?.status === 400) {
            Toast({
              status: "error",
              message: "Cycle Plan Upload Failed",
            });
          }
        })
        .catch((e) => {
          Toast({ status: "error", message: "Upload failed " + e.message });
        })
        .finally(() => {
          dispatch(
            globalActions.setLoading({ loading: false, label: "Completed" })
          );
          dispatch(eoplActions.setUploading());
        });
    };

    try {
      await sendRequest();
    } catch (error) {
      Toast({ status: "error", message: error.message });
    }
  };
};

export const getEoplData = (eoplProgramId) => {
  let url = `programList/?eoplProgramRefId=${eoplProgramId}`;

  return async (dispatch) => {
    try {
      dispatch(
        globalActions.setLoading({
          loading: true,
          label: "Retrieving EOPL Programme",
        })
      );
      const eoplData = await getRequest(url);
      dispatch(eoplActions.replaceEoplData({ data: eoplData[0] }));
    } catch (error) {
      if (error.message.substring(0, 2) !== "40") {
        let status = "error";
        if (error.message === "EOPL Doesn't Exist") {
          status = "warning";
        }
        Toast({ status: status, message: error.message });
      }
    } finally {
      dispatch(globalActions.setLoading({ loading: false }));
    }
  };
};

export const getAllProgramData = (data) => {
  return async (dispatch) => {
    try {
      let url = "programListAlldata/";
      let label = "Fetching All EOPL Programmes";
      if (data && data?.value !== "") {
        url = `/eoplPartsFilter/?${data.partType}=${data.value}`;
        label = `Fetching Programme with Part no: ${data.value}`;
      }
      dispatch(globalActions.setLoading({ loading: true, label: label }));
      const programData = await getRequest(url);
      dispatch(globalActions.setLoading({ loading: false }));
      dispatch(eoplActions.replaceEoplAllData({ allData: programData || [] }));
    } catch (error) {
      if (error.message.substring(0, 2) !== "40")
        Toast({ status: "error", message: error.message });
    } finally {
      dispatch(
        globalActions.setLoading({ loading: false, label: "Completed" })
      );
    }
  };
};

export const getProgramById = (id) => {
  return async (dispatch) => {
    try {
      dispatch(
        globalActions.setLoading({
          loading: true,
          label: "Getting Programme Data",
        })
      );
      const details = await getRequest(`programList/${id}/`);
      dispatch(eoplActions.updateEoplData(details));
      dispatch(globalActions.setLoading({ loading: false }));
    } catch (error) {
      dispatch(globalActions.setLoading({ loading: false }));
      Toast({ status: "error", message: "Error in getting Programme Details" });
    }
  };
};

export const createEoplProgrammeDetails = (eoplData) => {
  return async (dispatch) => {
    try {
      //const data =
      await postRequest(`programList/`, eoplData.constructedObjectForApiCreate);
      Toast({ status: "success", message: "Programme Created" });
      // dispatch(
      //   eoplActions.updateEoplAllData({ allData:  eoplData.constructedObjectForStateUpdate, uploaded: true })
      // );
      // dispatch(eoplActions.updateEoplData( eoplData.constructedObjectForStateUpdate));
    } catch (error) {
      dispatch(eoplActions.setError());
      dispatch(globalActions.setLoading({ loading: false }));
      Toast({ status: "error", message: error.message });
    }
  };
};

export const updateEoplProgrammeDetails = (id, eoplData) => {
  return async (dispatch) => {
    try {
      dispatch(
        globalActions.setLoading({
          loading: true,
          label: `Updating EOPL Programme ${eoplData.constructedObjectForApiUpdate.eoplProgramRefId}`,
        })
      );
      const response = await putRequest(
        `programList/${id}/`,
        eoplData.constructedObjectForApiUpdate
      );

      if (response?.status === 202) {
        Toast({
          status: "info",
          message: response.data,
        });
      } else {
        Toast({ status: "success", message: "Programme Updated" });
        dispatch(
          eoplActions.updateEoplAllData({
            allData: response,
            uploaded: true,
          })
        );
        // dispatch(
        //   eoplActions.updateEoplData(eoplData.constructedObjectForStateUpdate)
        // );
      }
      dispatch(globalActions.setLoading({ loading: false }));
    } catch (error) {
      dispatch(eoplActions.setError());
      dispatch(globalActions.setLoading({ loading: false }));
      Toast({ status: "error", message: error.message });
    }
  };
};

export const deleteEopl = (id, spo) => {
  return async (dispatch) => {
    try {
      dispatch(setSearchValue());
      const response = await deleteRequest(`spo/delete/${id}`);
      Toast({ status: "success", message: response });
      dispatch(eoplActions.removeSpoAllData(id));
      dispatch(setSearchValue(spo));
    } catch (error) {
      console.error("updateUser: " + error.message);
      Toast({ status: "error", message: error.message });
    }
  };
};

export const email = (type) => {
  let url = "";

  if (type === "pdf") url = "spo/sendgridpdf";
  if (type === "excel") url = "spo/sendgrid";

  return async (dispatch) => {
    const sendRequest = async () => {
      Toast({ status: "info", message: `${url}` });
      return axios
        .get(url)
        .then((response) => {
          if (response.status === 200) {
            Toast({ status: "success", message: `${response.data}` });
            return response.data;
          }
          throw new Error(response.data.message);
        })
        .catch((error) => {
          throw new Error(error.message);
        });
    };

    try {
      await sendRequest();
    } catch (error) {
      Toast({ status: "error", message: error.message });
    }
  };
};

export const download = (type, eoplProgramRefId) => {
  const url = `${type}/?eoplProgramId=${eoplProgramRefId}`;

  return async (dispatch) => {
    try {
      if (type === "shoppingListExport") {
        dispatch(
          globalActions.setLoading({
            loading: true,
            label: `Downloading Shopping List EOPL Programme ${eoplProgramRefId}`,
          })
        );
      } else if (type === "childPartsExport") {
        dispatch(
          globalActions.setLoading({
            loading: true,
            label: `Downloading ChildPart List for EOPL Programme ${eoplProgramRefId}`,
          })
        );
      } else if (type === "programListExport") {
        dispatch(
          globalActions.setLoading({
            loading: true,
            label: `Downloading EOPL Programme ${eoplProgramRefId}`,
          })
        );
      }
      const response = await getRequest(url, { responseType: "blob" });
      if (response) {
        const blob = new Blob([response], {
          type: "application/vnd.ms-excel",
        });
        if (type === "shoppingListExport") {
          fileDownload(
            blob,
            `Shopping List ${
              eoplProgramRefId + "  " + getFormattedDate(Date.now(), true)
            }.xlsx`
          );
          Toast({
            status: "success",
            message: `Shopping List for ${eoplProgramRefId} Downloaded`,
          });
        } else if (type === "childPartsExport") {
          fileDownload(
            blob,
            `ChildPart List ${
              eoplProgramRefId + "  " + getFormattedDate(Date.now(), true)
            }.xlsx`
          );
          Toast({
            status: "success",
            message: `Child Parts List for ${eoplProgramRefId} Downloaded`,
          });
        } else if (type === "programListExport") {
          fileDownload(
            blob,
            `EOPL ${
              eoplProgramRefId + "  " + getFormattedDate(Date.now(), true)
            }.xlsx`
          );
          Toast({
            status: "success",
            message: `EOPL ${eoplProgramRefId} Downloaded`,
          });
        }
        dispatch(globalActions.setLoading({ loading: false }));
      }
    } catch (error) {
      console.error("download: " + error.message);
      dispatch(globalActions.setLoading({ loading: false }));
      Toast({ status: "error", message: error.message });
    }
  };
};

export const updateQuarterlyStatus = (id, ...data) => {
  return async (dispatch, getState) => {
    try {
      dispatch(
        globalActions.setLoading({
          loading: true,
          label: `Updating Quarterly Review Status`,
        })
      );
      //const response =
      const response = await putRequest(`nextQuarterReview/${id}/`, data[0]);

      Toast({ status: "success", message: "Status Updated" });

      const state = getState();
      const eoplData = state.eopl.data;

      const newEoplData = {
        ...eoplData,
        eoplNextQuarterReview: eoplData.eoplNextQuarterReview.map((review) =>
          review.id === response.id ? response : review
        ),
      };

      dispatch(eoplActions.updateEoplData(newEoplData));

      dispatch(globalActions.setLoading({ loading: false }));
    } catch (error) {
      dispatch(eoplActions.setError());
      dispatch(globalActions.setLoading({ loading: false }));
      Toast({ status: "error", message: error.message });
    }
  };
};

export const setFilters = (filters) => {
  return (dispatch) => {
    dispatch(
      eoplActions.setFilters({
        filters: filters ?? [],
      })
    );
  };
};

export const setGblFilter = (filter) => {
  return (dispatch) => {
    dispatch(
      eoplActions.setGlbFilter({
        gblFilter: filter,
      })
    );
  };
};

export const getCyclePlanStatus = () => {
  return async (dispatch) => {
    try {
      dispatch(
        globalActions.setLoading({
          loading: true,
          label: "Checking Status for Cycle Plan",
        })
      );
      const details = await getRequest(`cyclePlanMetaInfo/`);
      dispatch(
        globalActions.setLoading({ loading: false, label: "Completed" })
      );
      return details;
    } catch (error) {
      Toast({
        status: "error",
        message: "Error in getting latest Cycle Plan Status",
      });
    }
  };
};

export const getImages = (type, id, label) => {
  return async (dispatch) => {
    try {
      let result = [];

      dispatch(
        eoplActions.setLoading({ loading: true, label: "Loading Images..." })
      );

      if (type === "programme") {
        result = await getRequest(`programImages/?eoplProgramId=${id}`);
      }
      if (type === "childParts") {
        result = await getRequest(`childPartsImages/?eoplChildPartsId=${id}`);
      }
      if (type === "shoppingList") {
        result = await getRequest(
          `shoppingListImages/?eoplShoppingListId=${id}`
        );
      }

      return result;
    } catch (error) {
      Toast({ status: "error", message: "Error in getting the image" });
    } finally {
      dispatch(eoplActions.setLoading({ loading: false }));
    }
  };
};

export const deleteImage = (type, imageId) => {
  return async (dispatch) => {
    try {
      dispatch(
        eoplActions.setLoading({ loading: true, label: "Deleting Image..." })
      );

      let response;

      if (type === "programme") {
        response = await deleteRequest(`programImages/${imageId}`);
      }
      if (type === "childParts") {
        response = await deleteRequest(`childPartsImages/${imageId}`);
      }
      if (type === "shoppingList") {
        response = await deleteRequest(`shoppingListImages/${imageId}/`);
      }
      if (response?.status === 200)
        Toast({ status: "success", message: response });
      if (response?.status === 404)
        Toast({ status: "error", message: response?.detail });
    } catch (error) {
      Toast({ status: "error", message: "Error in deleting the image!!" });
    } finally {
      dispatch(eoplActions.setLoading({ loading: false }));
    }
  };
};

export const uploadImage = (file, data) => {
  return async (dispatch) => {
    const sendRequest = () => {
      let url = "";

      const formData = new FormData();
      if (data?.type === "programme") {
        url = `/programImages/`;
        formData.append("eoplProgramId", data?.partId);
      }
      if (data?.type === "childParts") {
        url = `/childPartsImages/`;
        formData.append("eoplChildPartsId", data?.partId);
      }
      if (data?.type === "shoppingList") {
        url = `/shoppingListImages/`;
        formData.append("eoplShoppingListId", data?.partId);
      }
      formData.append("name", data?.name);
      formData.append("imageUrl", file);

      dispatch(
        eoplActions.setLoading({ loading: true, label: "Uploading Image..." })
      );

      let options = {
        responseType: "json",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
      };

      if (process.env.REACT_APP_BUILD_TYPE === "DEV") {
        options = {
          ...options,
          auth: {
            username: "devuser",
            password: "devuser",
          },
        };
      }

      return axios
        .post(url, formData, options)
        .then((response) => {
          if (response?.status === 201) {
            Toast({
              status: "success",
              message: "Image Uploaded successfully",
            });
          }
        })
        .catch((e) => {
          Toast({
            status: "error",
            message: "Image Upload failed " + e.message,
          });
        })
        .finally(() => {
          dispatch(eoplActions.setLoading({ loading: false }));
        });
    };

    try {
      await sendRequest();
    } catch (error) {
      Toast({ status: "error", message: error.message });
    }
  };
};

export const download_file = (filename, filePath) => {
  return async (dispatch) => {
    try {
      // const encodedFilename = encodeURIComponent(filePath);

      const response = await getRequest(`/download/${filePath}/`, {
        responseType: "blob",
      });
      if (response) {
        const blob = new Blob([response], {
          type: "application/vnd.ms-excel",
        });

        fileDownload(blob, `${filename}`);
        Toast({
          status: "success",
          message: `${filename} Downloaded`,
        });
      }
    } catch (error) {
      console.error("download: " + error.message);
      Toast({ status: "error", message: error.message });
    }
  };
};
