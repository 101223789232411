import Select from "react-select";
import CreatableSelect from "react-select/creatable";

const customStyles = {
  control: (base, state) => ({
    ...base,
    minHeight: "2.5rem",
    padding: "2px",
    outline: "1px solid transparent",
    borderRadius: "0.25rem",
    boxShadow:
      state.isFocused || state.isActive ? "0 0 0 1px rgb(140,140,140)" : 0,
    borderColor: "rgb(168,168,168)",
    borderWidth: "1px",
    "&:hover": {
      border: "1px solid rgb(140,140,140)",
    },
    // "&:focus": {
    //   border: "1px solid rgb(140,140,140)",
    //   outline: "1px solid rgb(140,140,140)",
    // },
    "&:active": {
      border: "1px solid rgb(140,140,140)",
    },
    width: "100%",
  }),
  option: (base, state) => ({
    ...base,
    cursor: "pointer",
    backgroundColor: state.isSelected ? "rgb(var(--color-secondary-2))" : "",
    "&:hover": {
      backgroundColor: state.isSelected
        ? "rgb(var(--color-secondary-3))"
        : "rgb(var(--color-secondary-4))",
      color: state.isSelected
        ? "rgb(var(--color-primary-1))"
        : "rgb(var(--color-secondary-1))",
    },
    whitespace: "nowrap",
  }),
  valueContainer: (styles, { data }) => ({
    ...styles,
    padding: "0px",
  }),
  menuList: (styles, state) => ({
    ...styles,
    padding: "0px",
    whiteSpace: "nowrap",
    backgroundColor: "inherit",
    borderRadius: "0.25rem",
    width: "max-content",
    minWidth: "100%",
    cursor: "pointer",
  }),
  multiValue: (styles, { data }) => ({
    ...styles,
    backgroundColor: "rgba(var(--color-primary) / 0.8)",
  }),
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    padding: "1px 5px",
    color: "rgb(var(--color-typography-2))",
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    padding: "0px 2px",
    color: "rgb(var(--color-typography-2))",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgb(var(--color-danger))",
      color: "rgb(var(--color-typography-2))",
    },
  }),
  indicatorsContainer: (base) => ({
    ...base,
  }),
  indicatorSeparator: (base) => ({
    ...base,
  }),
  dropdownIndicator: (base) => ({
    ...base,
    padding: "4px",
    cursor: "pointer",
    "&:hover": {
      color: "rgb(var(--color-primary))",
    },
  }),
  clearIndicator: (base) => ({
    ...base,
    padding: "4px",
    cursor: "pointer",
    "&:hover": {
      color: "rgb(var(--color-danger))",
    },
  }),
  menu: (base) => ({
    ...base,
    zIndex: 9999,
  }),
  menuPortal: (base) => ({ ...base, zIndex: "9999" }),
  noOptionsMessage: (base) => ({
    ...base,
    margin: "0px",
    padding: "0px",
  }),
};

export const SelectInput = ({
  className,
  placeholder,
  name,
  value,
  onChangeHandler,
  label,
  options,
  isMulti = false,
  isClearable = true,
  isCreatable = false,
  byLabel = false,
  ...rest
}) => {
  const onChange = (option) => {
    let values;
    if (isMulti)
      values = !option
        ? []
        : option.map((item) => (byLabel ? item.label : item.value));
    else values = !option ? "" : byLabel ? option.label : option.value;
    onChangeHandler(values);
  };

  const getValue = () => {
    const isArray = Array.isArray(value);
    let val;
    if (options) {
      if (isMulti && isArray)
        val =
          options.filter((option) =>
            value.includes(byLabel ? option.label : option.value)
          ) ?? [];
      else
        val =
          options.find(
            (option) => (byLabel ? option.label : option.value) === value
          ) ?? "";
    } else {
      val = isMulti ? [] : "";
    }

    return val;
  };

  return (
    <>
      <label
        htmlFor={name}
        className={`${
          !label && "hidden"
        } text-sm font-bold leading-tight tracking-normal mb-2 whitespace-nowrap`}
      >
        {label}
      </label>
      {isCreatable ? (
        <CreatableSelect
          {...rest}
          className={`shadow-sm ${className || ""}`}
          name={name}
          id={name}
          value={getValue()}
          onChange={onChange}
          placeholder={placeholder}
          options={options}
          isMulti={isMulti}
          styles={customStyles}
          isClearable={isClearable}
          menuPortalTarget={document.body}
        />
      ) : (
        <Select
          {...rest}
          className={`relative shadow-sm ${className || ""} flsex flex-cosl`}
          name={name}
          id={name}
          value={getValue()}
          onChange={onChange}
          placeholder={placeholder}
          options={options}
          isMulti={isMulti}
          styles={customStyles}
          isClearable={isClearable}
          menuPortalTarget={document.body}
          noOptionsMessage={() => <p>No more options</p>}
        />
      )}
    </>
  );
};

export default SelectInput;
