import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "../packages/modal/Modal";
import Button from "../packages/button/Button";
import { deleteImage, getImages, uploadImage } from "../store/eoplActions";
import { getProgramInfo } from "../store/programInfoActions";
import { globalActions } from "../store/globalSlice";
import { formatCamelCaseString } from "../utils/utility";
import SpinnerIcon from "../packages/button/SpinnerIcon";
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  DownloadIcon,
  PhotographIcon,
} from "@heroicons/react/solid";

const ImageUploadModal = ({ data, closeModal, eoplProgramId }) => {
  const dispatch = useDispatch();
  const [imageList, setImageList] = useState([]);
  const [parentData, setParentData] = useState(null);
  const [currentImage, setCurrentImage] = useState(null);
  const [imagesCount, setImagesCount] = useState(0);
  const [currentImageCount, setCurrentImageCount] = useState(0);
  const fileInput = useRef(null);

  useEffect(() => {
    if (imagesCount === currentImageCount) return;

    if (!currentImage) {
      setCurrentImage(imageList[0]);
    } else if (imagesCount > currentImageCount) {
      setCurrentImage(imageList[imageList.length - 1]);
    } else {
      setCurrentImage(imageList[currentImage?.index - 1]);
    }
    setCurrentImageCount(imagesCount);
  }, [currentImage, currentImageCount, imageList, imagesCount]);

  const imageState = useSelector((state) => state.eopl.loading);

  useEffect(() => {
    setParentData({ partId: data?.id, type: data?.type });
    const fetchData = async () => {
      const imageData = await dispatch(getImages(data?.type, data?.id));
      setImageList(imageData?.map((data, index) => ({ ...data, index })));
    };
    fetchData();
  }, [data, dispatch]);

  useEffect(() => {
    if (imageList.length > 0) {
      setImagesCount(imageList.length);
    } else {
      setImagesCount(0);
    }
  }, [imageList, imagesCount]);

  const handleImagePrev = () => {
    const currentIndex = currentImage?.index;
    if (currentIndex > 0) {
      setCurrentImage(imageList[currentIndex - 1]);
    }
  };

  const handleImageNext = () => {
    const currentIndex = currentImage?.index;
    if (currentIndex < imageList.length - 1) {
      setCurrentImage(imageList[currentIndex + 1]);
    }
  };

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = `data:image/png;base64,${currentImage?.base64Url}`;
    link.download = currentImage?.name;
    link.click();
  };

  const handleUpload = async (event) => {
    const file = event.target.files[0];
    if (fileInput.current && typeof fileInput.current.click === "function") {
      dispatch(globalActions.setLoading(true, "Uploading Image"));
      await dispatch(uploadImage(file, { ...parentData, name: file?.name }));
      const imageData = await dispatch(
        getImages(parentData?.type, parentData?.partId)
      );
      setImageList(imageData.map((data, index) => ({ ...data, index })));
      fileInput.current.value = ""; // Clear the file input

      dispatch(getProgramInfo(eoplProgramId));
      dispatch(globalActions.setLoading(false));
    }
  };

  const handleDelete = async () => {
    if (currentImage) {
      await dispatch(deleteImage(parentData?.type, currentImage?.id));
      const imageData = await dispatch(
        getImages(parentData?.type, parentData?.partId)
      );
      setImageList(imageData.map((data, index) => ({ ...data, index })));
      dispatch(getProgramInfo(eoplProgramId));
    }
  };

  const handleButtonClick = () => {
    fileInput.current.click();
  };

  const getImageType = () => {
    let imageType = formatCamelCaseString(data?.type);
    if (imageType === "Child Parts") {
      imageType += " List";
    }
    return imageType;
  };

  const imageType = getImageType();

  return (
    <Modal isOpen={true} animate={true} closeOnClickOutside={false}>
      <ModalHeader>
        <div className="flex justify-between items-center">
          {imageType} Images
          <div className="text-center text-[1.1rem] font-bold">
            {currentImage?.index >= 0 ? currentImage?.index + 1 : 0} of{" "}
            {imagesCount}
          </div>
        </div>
      </ModalHeader>
      <ModalBody>
        <div className="text-lg font-semibold rounded-lg p-2 border border-gray-300 flex items-center justify-center w-full p-px min-h-[150px] ">
          {imageList.length > 0 && !imageState.loading ? (
            <div className="bg-white  ">
              <img
                src={`data:image/png;base64,${currentImage?.base64Url}`}
                alt="Uploaded"
              />
            </div>
          ) : imageState.loading ? (
            <div className="flex items-center justify-center ">
              <SpinnerIcon className={"w-10 h-10 mr-6"} />
              {imageState.label}
            </div>
          ) : (
            <span>No images for this {imageType}</span>
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          className="!px-2"
          onClick={handleImagePrev}
          disabled={currentImage?.index === 0 || imagesCount === 0}
        >
          <ChevronDoubleLeftIcon className="w-5 h-6" />
        </Button>
        <Button
          onClick={handleDownload}
          disabled={imagesCount === 0}
          className="flex items-center !pl-2 !pr-3 ws-40"
        >
          <DownloadIcon className="w-6 h-6 mr-2" />
          Download
        </Button>
        <Button
          onClick={handleImageNext}
          className="!px-2"
          disabled={
            currentImage?.index === imageList.length - 1 || imagesCount === 0
          }
        >
          <ChevronDoubleRightIcon className="w-5 h-6" />
        </Button>
      </ModalFooter>
      <ModalFooter
      // style={{
      //   display: "grid",
      //   gridTemplateColumns: "repeat(5, 1fr)",
      //   gap: "10px",
      //   alignItems: "bottom",
      // }}
      >
        <Button onClick={closeModal} color="danger" className="w-20">
          Close
        </Button>
        <div className="flex items-center ">
          <input
            ref={fileInput}
            type="file"
            accept=".png,.jpg,.jpeg"
            onChange={handleUpload}
            className="hidden" // Hide the default file input
          />
          <Button
            onClick={handleButtonClick}
            color="primary"
            className="flex items-center !pl-2 !pr-3 w-3s2"
          >
            <PhotographIcon className="w-6 h-6 mr-2 " />
            Upload
          </Button>
        </div>
        <Button
          onClick={handleDelete}
          disabled={imagesCount === 0}
          className="w-20"
        >
          Delete
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ImageUploadModal;
