import { createSlice, current } from "@reduxjs/toolkit";

import _ from "underscore";

export const childPartListObject = {
  eoplProgramId: "",
  currentEpn: "",
  currentApn: "",
  newEpn: "",
  partDescription: "",
  vendor: "",
  changeDescription: "",
  vehicleApplicability: "",
  backwardsCompatible: "",
  epn1: "",
  pf1: "",
  apn1: "",
  epn2: "",
  pf2: "",
  apn2: "",
  epn3: "",
  pf3: "",
  apn3: "",
  iCreate: "",
};

export const initialValues = {
  id: null,
  eoplProgramId: "",
  currentEpn: "",
  currentApn: "",
  newEpn: "",
  partDescription: "",
  vendor: "",
  changeDescription: "",
  vehicleApplicability: "",
  backwardsCompatible: "",
  epn1: "",
  pf1: "",
  apn1: "",
  epn2: "",
  pf2: "",
  apn2: "",
  epn3: "",
  pf3: "",
  apn3: "",
  auditDateTime: null,
  version: "",
  isReadonly: true,
  iCreate: "",
  imageCount: null,
};

const childPartListSlice = createSlice({
  name: "childPart",
  initialState: {
    childPartList: [],
    data: initialValues,
    changed: false,
    uploaded: false,
    uploading: false,
    loading: true,
    searchValue: null,
  },
  reducers: {
    setSearchValue(state, action) {
      state.searchValue = action.payload;
    },
    setUploading(state, action) {
      state.uploading = action.payload ?? false;
    },
    resetUploaded(state) {
      state.uploaded = false;
    },
    setChanged(state, action) {
      state.changed = action.payload ?? false;
    },
    setError(state) {
      state.error = true;
    },
    resetChildPartsData(state) {
      state.data = initialValues;
      state.childPartList = [];
      state.changed = true;
      state.error = false;
    },
    updateField(state, action) {
      const currentData = current(state.data);
      const newData = {
        ...currentData,
        [action.payload.field]: action.payload.value,
      };
      state.data = newData;
    },
    updateSpoData(state, action) {
      state.data = action.payload;
      state.changed = true;
      state.error = false;
    },
    // all data
    replaceChildPartListData(state, action) {
      const data = action.payload?.childPartList ?? [];
      state.childPartList = _.sortBy(data, "id");
      state.changed = true;
      state.error = false;
    },
    removeSpoAllData(state, action) {
      const id = action.payload;
      var data = state.childPartList.filter((d) => d.id !== id);
      state.childPartList = _.sortBy(data, "auditDateTime").reverse();
      state.changed = true;
      state.error = false;
    },
    updateChildPartListData(state, action) {
      const data = action.payload.childPartList;
      const updatedData = [
        ...state.childPartList.filter((d) => d.id !== data.id),
        data,
      ];
      state.childPartList = _.sortBy(updatedData, "id");
      state.changed = true;
      state.error = false;
      state.uploaded = action.payload?.uploaded ?? false;
    },
  },
});

export const childPartListActions = childPartListSlice.actions;

export default childPartListSlice;
