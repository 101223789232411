import { createSlice } from "@reduxjs/toolkit";

const initialValues = {
  loading: null,
  label: null,
  error: null,
  viewState: null,
  sideNavPeek: null,
  seconds: 0, // for getActiveProfile timeout
};

const globalSlice = createSlice({
  name: "global",
  initialState: initialValues,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload?.loading ?? false;
      state.label = action.payload?.label ?? null;
      state.error = action.payload?.error ?? false;
      state.seconds = 0;
    },
    setViewState(state, action) {
      state.viewState = action.payload;
    },
    setSideNavPeek(state, action) {
      state.sideNavPeek = action.payload;
    },
    setTimeoutExceeded(state, action) {
      state.label = action.payload?.label ?? null;
      state.error = action.payload?.message ?? null;
      state.seconds = action.payload?.seconds ?? null;
    },
    setSeconds(state, action) {
      state.seconds = action.payload ?? 0;
    },
    resetTimeoutExceeded(state) {
      state.label = null;
      state.seconds = 0;
      state.error = null;
    },
  },
});

export const globalActions = globalSlice.actions;

export default globalSlice;
