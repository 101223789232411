import React, { useState } from "react";
import Folder from "./Folder";
import File from "./File";
import Empty from "./Empty";

const Display = ({ data, addNew, setAddNew, setDeleteItem }) => {
  const [open, setOpen] = useState([]);

  const toggleFolderState = (folderId) => {
    if (open.includes(folderId)) {
      setOpen(open.filter((id) => id !== folderId));
    } else {
      setOpen([...open, folderId]);
    }
  };

  const renderFolders = (folders) => {
    return Object.values(folders).map((folder) => {
      // Only render top-level folders (those without a parentFolderId)
      if (!folder.parentFolderId) {
        return (
          <Folder
            key={folder.folderId}
            data={folder}
            open={open}
            toggleFolderState={toggleFolderState}
            addNew={addNew}
            setAddNew={setAddNew}
            setDeleteItem={setDeleteItem}
            windowId={data.windowId}
            windowName={data.windowName}
            setOpen={setOpen}
          />
        );
      }
      return null;
    });
  };

  const renderFiles = (files) => {
    return files.map((file) => (
      <div
        key={file.fileId}
        className="flex items-center w-full group hover:bg-tertiary dark:hover:bg-tertiary/50"
      >
        <File data={file} />
      </div>
    ));
  };

  return (
    <div>
      {data && (
        <>
          {Object.keys(data.folders).length === 0 &&
          data.files.length === 0 &&
          (!addNew?.windowId || addNew?.parentFolderId) ? (
            <Empty message={"No files or folders"} />
          ) : (
            <>
              {renderFolders(data.folders)}
              {renderFiles(data.files)}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Display;
