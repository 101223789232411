import { useSelector } from "react-redux";
import Display from "./Display";
import Input from "./Input";
import New from "./New";

const Window = (props) => {
  const { data, addNew, setAddNew, setDeleteItem, onContextMenu } = props;

  const loader = useSelector((state) => state.explorer.loader);

  return (
    <>
      <div
        onContextMenu={onContextMenu}
        onClick={onContextMenu}
        className={`${
          loader.loading && loader.id === data?.windowName
            ? "bg-danger bg-danger/80 shadow-danger/20"
            : "bg-primary bg-primary/80 shadow-primary/20"
        } transition-colors duration-300 ease-in-out mx-auto h-fit mr-4 mb-10 w-full p-px shadow-md  rounded text-sm`}
      >
        <div
          className={`${
            loader.loading && loader.id === data?.windowName
              ? "bg-danger"
              : "bg-primary"
          } transition-colors duration-300 ease-in-out h-10 px-3 py-2 rounded-t flex items-center justify-between`}
        >
          <p className="font-bold text-typography-2 tracking-wide">
            {data.windowName}
          </p>
          {setAddNew && (
            <New
              addNew={addNew}
              setAddNew={setAddNew}
              root={true}
              setDeleteItem={setDeleteItem}
              data={data}
              windowName={data?.windowName}
              windowId={data?.windowId}
            />
          )}
        </div>
        <div className="bg-white">
          <div className="bg-primary/80 text-typography-2">
            {addNew && addNew.root && addNew.windowName === data.windowName && (
              <Input
                {...{
                  addNew,
                  setAddNew,
                }}
              />
            )}
            <div className="w-full h-full rounded-b bg-white max-h-[500px] overflow-y-auto">
              <Display
                data={data}
                addNew={addNew}
                setAddNew={setAddNew}
                setDeleteItem={setDeleteItem}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Window;
