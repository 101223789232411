import { getRequest } from "../axios";
import { programInfoActions } from "./programInfoSlice";
import Toast from "../components/ui/Toast";

export const getProgramInfo = (eoplProgramId) => {
  return async (dispatch) => {
    try {
      const programInfo = await getRequest(`program-info/${eoplProgramId}/`);
      dispatch(programInfoActions.replaceProgramInfo(programInfo));
    } catch (error) {
      console.error("getProgramInfo: " + error.message);
      if (
        error.message.substring(0, 14) !== "Request failed" &&
        error.message.substring(0, 2) !== "40"
      )
        Toast({ status: "error", message: error.message });
    }
  };
};
