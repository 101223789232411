import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Formik, Form } from "formik";
import FormikControl from "../../../components/ui/Formik/FormikControl";
import * as Yup from "yup";
import Button from "../../../packages/button/Button";
import {
  ArrowRightIcon,
  DownloadIcon,
  PhotographIcon,
} from "@heroicons/react/solid";
import { download } from "../../../store/eoplActions";
import Comments from "../../../components/comments/Comments";
import Toast from "../../../components/ui/Toast";
import { getEoplComments } from "../../../store/eoplCommentsActions";

import { updateEoplProgrammeDetails } from "../../../store/eoplActions";
import { constructPogramDetailsObject } from "../../../utils/utility";
import { Role } from "../../../data/roles";
import NextQuarterReviewView from "./QuarterlyReviewView";
import { getProgramInfo } from "../../../store/programInfoActions";
import ImageUploadModal from "../../../components/imageUploadModal";

const validationSchema = Yup.object().shape({
  initialInScopeData: Yup.date().nullable().required("Required"),
  initialImpact: Yup.number().nullable().required("Required"),
  initialPdlCheck: Yup.string().nullable().required("Required"),
  initialStatus: Yup.number().nullable().required("Required"),
});

const MainDetails = (props) => {
  const { data, next, last, closeDrawer } = props;

  const dispatch = useDispatch();

  const currentUser = useSelector((state) => state.profile.profilesData);
  const eoplProgramData = useSelector((state) => state.eopl.data);
  const dropdownsData = useSelector((state) => state.dropdown.dropdownsData);
  const changed = useSelector((state) => state.eopl.changed);
  const programInfoImageCounts = useSelector(
    (state) => state.programInfo.data.imageCounts
  );

  const [imageCount, setImageCount] = useState(0);

  useEffect(() => {
    if (!programInfoImageCounts) return;
    const imageCount =
      programInfoImageCounts.find(
        (m) => m.id === data?.id && m.imageType === "programme"
      )?.imageCount || 0;
    setImageCount(imageCount);
  }, [programInfoImageCounts, dispatch, data?.id]);

  const [impactOptions, setImpactOptions] = useState(dropdownsData.eoplImpact);

  const [vehicleBrandOptions, setVehicleBrandOptions] = useState(
    dropdownsData.vehicleBrands
  );

  const [pdlCheckOptions, setPdlCheckOptions] = useState(
    dropdownsData.eoplPdlCheck
  );
  const [inScopeOptions] = useState(dropdownsData.inScopeStatus);
  const [statusOptions, setStatusOptions] = useState(dropdownsData.eoplStatus);
  const [initialInScopeData, setInitialInScopeData] = useState(
    eoplProgramData?.inScope?.id
  );
  const [initialImpact, setinitialImpact] = useState(
    eoplProgramData?.impact?.id
  );
  const [initialVehicleBrands, setinitialVehicleBrands] = useState(
    eoplProgramData?.vehicleBrands?.id
  );
  const [initialPdlCheck, setInitialPdlCheck] = useState(
    eoplProgramData?.pdlCheck?.id
  );
  const [initialStatus, setInitialStatus] = useState(
    eoplProgramData?.status?.id
  );

  const [eoplProgramRefId, setEoplProgramRefId] = useState(
    data.eoplProgramRefId
  );

  const [imageData, setImageData] = useState(null);
  const [visibleImageUpload, setVisibleImageUpload] = useState(false);

  useEffect(() => {
    const inScopeObject = inScopeOptions.find((element) => {
      return eoplProgramData?.inScope?.id === element?.id;
    });

    setStatusOptions(inScopeObject?.availableStatus);
    setImpactOptions(inScopeObject?.availableImpactOptions);
    setVehicleBrandOptions(dropdownsData?.vehicleBrands);
    setPdlCheckOptions(inScopeObject?.availablePdlOptions);

    setInitialInScopeData(eoplProgramData?.inScope?.id);
    setinitialImpact(eoplProgramData?.impact?.id);
    setinitialVehicleBrands(eoplProgramData?.vehicleBrands?.id);
    setInitialPdlCheck(eoplProgramData?.pdlCheck?.id);
    setInitialStatus(eoplProgramData?.status?.id);
  }, [dropdownsData?.vehicleBrands, eoplProgramData, inScopeOptions]);

  useEffect(() => {
    if (eoplProgramRefId === eoplProgramData?.eoplProgramRefId || !data?.id)
      return;
    dispatch(getProgramInfo(eoplProgramData?.eoplProgramRefId));
    dispatch(getEoplComments(eoplProgramData.id));
    setEoplProgramRefId(eoplProgramData?.eoplProgramRefId);
    return;
  }, [data.id, dispatch, eoplProgramData, eoplProgramRefId]);

  const handleInScopeDropdownChange = (value) => {
    if (value !== null) {
      const inScopeObject = inScopeOptions.find((element) => {
        return value === element?.id;
      });
      setStatusOptions(inScopeObject?.availableStatus);
      setImpactOptions(inScopeObject?.availableImpactOptions);
      setPdlCheckOptions(inScopeObject?.availablePdlOptions);
      setInitialInScopeData(inScopeObject?.id);
      setinitialImpact(inScopeObject?.availableImpactOptions[0]?.id);
      setInitialPdlCheck(inScopeObject?.availablePdlOptions[0]?.id);
      setInitialStatus(inScopeObject?.availableStatus[0]?.id);
    }
  };

  const handleSubmit = (values) => {
    const customProgarmDetailsObj = constructPogramDetailsObject(
      values,
      dropdownsData
    );

    dispatch(
      updateEoplProgrammeDetails(values.id, customProgarmDetailsObj)
    ).then(() => {
      if (closeDrawer) closeDrawer();
    });
  };

  const handleNext = (values) => {
    next(values);
  };
  const handleLast = (values) => {
    last(values);
  };

  const isReadOnly = true;

  // // To avoid reset of comments during switching between main details and inspection screens
  // if (refreshComments) {
  //   setTimeout(() => {
  //     dispatch(setComments(data.comments));
  //   }, 1000);
  // }

  const excelDownloadHandler = () =>
    dispatch(download("programListExport", data.eoplProgramRefId));

  const toggleImgUploadModal = (data = null) => {
    if (data !== null) {
      setVisibleImageUpload(true);
      setImageData({ id: data, type: "programme" });
    } else {
      setVisibleImageUpload(false);
      setImageData(null);
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          ...eoplProgramData,
          initialInScopeData,
          initialImpact,
          initialVehicleBrands,
          initialPdlCheck,
          initialStatus,
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
        validateOnMount
        id="eoplProgramDetails"
      >
        {({
          submitForm,
          errors,
          handleBlur,
          touched,
          dirty,
          values,
          setFieldTouched,
          setFieldValue,
          handleChange,
        }) => {
          // Track the dirty state outside the render phase
          // handleDirtyChange(dirty);
          return (
            <>
              <Form>
                <div className="p-4 bg-primary/30 sm:pt-6 sm:pXb-4 ">
                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 xl:grid-cols-11 2xl:grid-cols-12 gap-x-6 ">
                    <FormikControl
                      className="pb-4 !col-span-2 md:!col-span-2 lg:!col-span-3 xl:!col-span-6 2xl:!col-span-3"
                      control="input"
                      type="text"
                      label="Programme Reference"
                      name="eoplProgramRefId"
                      disabled={isReadOnly}
                    />
                    <FormikControl
                      className="pb-4 !col-span-2 sm:!col-span-1 lg:!col-span-2 xl:!col-span-3"
                      control="input"
                      type="text"
                      label="Programme Title"
                      name="name"
                      disabled={isReadOnly}
                    />
                    <FormikControl
                      className="pb-4 !col-span-2 sm:!col-span-1 lg:!col-span-2 xl:!col-span-2"
                      control="input"
                      type="text"
                      label="Type"
                      name="type"
                      disabled={isReadOnly}
                    />

                    <FormikControl
                      className="pb-4 !col-span-2 sm:!col-span-1 lg:!col-span-2 xl:!col-span-2"
                      control="input"
                      type="text"
                      label="Created By"
                      name="createdUserId.name"
                      disabled={isReadOnly}
                    />
                    <FormikControl
                      className="pb-4 !col-span-2 sm:!col-span-1 lg:!col-span-2 xl:!col-span-2"
                      control="date"
                      type="date"
                      label="Created Date"
                      name="createdDateTime"
                      disabled={isReadOnly}
                    />

                    <FormikControl
                      className="pb-4 !col-span-2 sm:!col-span-1 lg:!col-span-2 xl:!col-span-2"
                      control="date"
                      type="date"
                      label="Job 1"
                      name="job1"
                      disabled={isReadOnly}
                    />

                    <FormikControl
                      className="pb-4 !col-span-2 sm:!col-span-1 lg:!col-span-2 xl:!col-span-2"
                      control="input"
                      type="text"
                      label="Model Year"
                      name="manufacturingYear"
                      disabled={isReadOnly}
                    />
                    <FormikControl
                      className="pb-4 !col-span-2 sm:!col-span-1 lg:!col-span-2 xl:!col-span-2"
                      control="input"
                      type="text"
                      label="Vehicle Line"
                      name="vehicleLine.name"
                      disabled={isReadOnly}
                    />

                    <FormikControl
                      className="pb-4 !col-span-1 sm:!col-span-1 lg:!col-span-1 xl:!col-span-1"
                      control="input"
                      type="number"
                      label="TFLE"
                      name="tfle"
                      disabled={isReadOnly}
                    />

                    <FormikControl
                      className="pb-4 !col-span-1 sm:!col-span-1 lg:!col-span-1 xl:!col-span-1"
                      control="input"
                      type="text"
                      label="ARCH"
                      name="architecturePlatform.name"
                      disabled={isReadOnly}
                    />
                    <FormikControl
                      className="pb-4 !col-span-1 sm:!col-span-1 lg:!col-span-1 xl:!col-span-1"
                      control="input"
                      type="text"
                      label="Version"
                      name="version"
                      disabled={isReadOnly}
                    />
                    <FormikControl
                      className="pb-4 !col-span-1 sm:!col-span-1 lg:!col-span-1 xl:!col-span-1"
                      control="input"
                      type="text"
                      label="S4P"
                      name="sp4"
                      // dirty={dirty}
                      disabled={currentUser.role === Role.view}
                    />

                    <FormikControl
                      className="pb-4 !col-span-2 sm:!col-span-1 lg:!col-span-2 xl:!col-span-2"
                      control="select"
                      label="Brand"
                      name="initialVehicleBrands"
                      isMulti={false}
                      // dirty={dirty}
                      options={vehicleBrandOptions?.map((element) => ({
                        value: element?.id,
                        label: element?.name,
                      }))}
                      onBlur={handleBlur}
                      disabled={currentUser.role === Role.view}
                    />

                    <FormikControl
                      className="pb-4 !col-span-2 sm:!col-span-1 lg:!col-span-2 xl:!col-span-2"
                      control="input"
                      type="number"
                      dirty={dirty}
                      label="Initial Shopping List Part Count"
                      name="shoppingListPartsCount"
                      disabled={currentUser.role === Role.view}
                    />
                    <FormikControl
                      className="pb-4 !col-span-2 sm:!col-span-1 lg:!col-span-2 xl:!col-span-2"
                      control="input"
                      type="text"
                      label="Engineer Contacts"
                      dirty={dirty}
                      name="engineersContact"
                      onBlur={handleBlur} // This apparently updates `touched`?
                      disabled={currentUser.role === Role.view}
                    />
                    <FormikControl
                      className="pb-4 !col-span-2 sm:!col-span-1 lg:!col-span-2 xl:!col-span-2"
                      control="select"
                      label="In Scope"
                      name="initialInScopeData"
                      isMulti={false}
                      dirty={dirty}
                      options={inScopeOptions?.map((element) => ({
                        value: element?.id,
                        label: element?.name,
                      }))}
                      isCustomHandler={true}
                      customHandlerCallback={handleInScopeDropdownChange}
                      onBlur={handleBlur}
                      disabled={currentUser.role === Role.view}
                      onChange={(e) => {
                        handleChange(e);
                        const inScopeObject = inScopeOptions.find((element) => {
                          return e.target.value === element?.id;
                        });
                        setFieldValue(
                          `${initialInScopeData}`,
                          inScopeObject?.id
                        );
                        setFieldValue(
                          `${initialImpact}`,
                          inScopeObject?.availableImpactOptions[0]?.id
                        );
                        setFieldValue(
                          `${initialPdlCheck}`,
                          inScopeObject?.availablePdlOptions[0]?.id
                        );
                        setFieldValue(
                          `${initialStatus}`,
                          inScopeObject?.availableStatus[0]?.id
                        );
                      }}
                    />
                    <FormikControl
                      className="pb-4 !col-span-2 sm:!col-span-1 lg:!col-span-2 xl:!col-span-2"
                      control="select"
                      label="PDL Check"
                      name="initialPdlCheck"
                      dirty={dirty}
                      isMulti={false}
                      options={pdlCheckOptions?.map((element) => ({
                        value: element?.id,
                        label: element?.name,
                      }))}
                      onBlur={handleBlur}
                      disabled={currentUser.role === Role.view}
                    />
                    <FormikControl
                      className="pb-4 !col-span-2 sm:!col-span-1 lg:!col-span-2 xl:!col-span-2"
                      control="select"
                      label="Impact"
                      name="initialImpact"
                      isMulti={false}
                      dirty={dirty}
                      options={impactOptions?.map((element) => ({
                        value: element?.id,
                        label: element?.name,
                      }))}
                      onBlur={handleBlur}
                      disabled={currentUser.role === Role.view}
                    />
                    <FormikControl
                      className="pb-4 !col-span-2 sm:!col-span-1 lg:!col-span-2 xl:!col-span-2"
                      control="select"
                      label="Status"
                      name="initialStatus"
                      dirty={dirty}
                      isMulti={false}
                      options={statusOptions?.map((element) => ({
                        value: element?.id,
                        label: element?.name,
                      }))}
                      onBlur={handleBlur}
                      disabled={currentUser.role === Role.view}
                    />
                  </div>
                  <b>Programme:</b>
                  <hr></hr>
                  <br></br>
                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 xl:grid-cols-11 2xl:grid-cols-12 gap-x-6 ">
                    <FormikControl
                      className="pb-4 !col-span-2 sm:!col-span-1 lg:!col-span-2 xl:!col-span-3"
                      control="date"
                      type="date"
                      label="EOPL Start"
                      name="eoplProgramCalculation[0].eoplStart"
                      disabled={isReadOnly}
                    />
                    <FormikControl
                      className="pb-4 !col-span-2 sm:!col-span-1 lg:!col-span-2 xl:!col-span-3"
                      control="date"
                      type="date"
                      label="EOPL Aftercare End"
                      name="eoplProgramCalculation[0].eoplAftercare"
                      disabled={isReadOnly}
                    />
                    <FormikControl
                      className="pb-4 !col-span-2 sm:!col-span-1 lg:!col-span-2 xl:!col-span-3"
                      control="input"
                      type="text"
                      label="Days until Job Last / J1"
                      name="eoplProgramCalculation[0].jobLastDays"
                      disabled={isReadOnly}
                    />
                    <FormikControl
                      className="pb-4 !col-span-2 sm:!col-span-1 lg:!col-span-2 xl:!col-span-3"
                      control="date"
                      type="date"
                      label="Shopping List Issue Date"
                      name="shoppingListIssueDate"
                      disabled={true}
                    />
                  </div>
                </div>
                <NextQuarterReviewView />
              </Form>
              <hr />
              {data.id && currentUser.role !== Role.view && (
                <Comments eoplProgramId={data.id} commentType="headerComment" />
              )}
              <div className="grid grid-cols-3 gap-x-4 py-3 bg-white text-left sm:px-6 rounded-b">
                <>
                  <div className="flex justify-start gap-x-2">
                    {((currentUser.role === Role.view &&
                      data.shoppingListIssueDate !== null) ||
                      currentUser.role !== Role.view) && (
                      <Button
                        type="button"
                        className={`bg-primary flex items-center `}
                        onClick={() => {
                          if (
                            Object.keys(errors).length === 0 &&
                            (Object.keys(touched).length === 0 || !changed)
                          )
                            return handleLast(values);
                          else if (Object.keys(errors).length > 0) {
                            Object.keys(errors).forEach((err) => {
                              setFieldTouched(err);
                            });
                          } else if (
                            Object.keys(touched).length > 0 &&
                            changed
                          ) {
                            Toast({
                              status: "warning",
                              message: "Please save the programme data",
                            });
                            Object.keys(touched).forEach((err) => {
                              setFieldTouched(err);
                            });
                          }
                        }}
                      >
                        Child Parts List
                        <ArrowRightIcon className="h-4 ml-2" />
                      </Button>
                    )}
                    {((currentUser.role === Role.view &&
                      data.shoppingListIssueDate !== null) ||
                      currentUser.role !== Role.view) && (
                      <Button
                        type="button"
                        onClick={() => {
                          if (
                            Object.keys(errors).length === 0 &&
                            (Object.keys(touched).length === 0 || !changed)
                          )
                            return handleNext(values);
                          else if (Object.keys(errors).length > 0) {
                            Object.keys(errors).forEach((err) => {
                              setFieldTouched(err);
                            });
                          } else if (
                            Object.keys(touched).length > 0 &&
                            changed
                          ) {
                            Toast({
                              status: "warning",
                              message: "Please save the programme data",
                            });
                            Object.keys(touched).forEach((err) => {
                              setFieldTouched(err);
                            });
                          }
                        }}
                        className={`bg-primary flex items-center `}
                      >
                        Shopping List
                        <ArrowRightIcon className="h-4 ml-2" />
                      </Button>
                    )}
                  </div>
                  {currentUser.role !== Role.view && data?.eoplProgramRefId && (
                    <Button
                      type="submit"
                      color="success"
                      className={`place-self-center col-start-2 w-20`}
                      onClick={() => {
                        if (Object.keys(errors).length === 0) {
                          return handleSubmit(values);
                        } else {
                          Toast({
                            status: "warning",
                            message: "Please fill required data",
                          });
                          Object.keys(errors).forEach((err) => {
                            setFieldTouched(err);
                          });
                        }
                      }}
                      disabled={!changed}
                    >
                      Save
                    </Button>
                  )}
                  <div className="place-self-end col-start-3 flex justify-end gap-x-2">
                    <Button
                      type="submit"
                      color="primary"
                      className={`flex items-center whitespace-nowrap relative`}
                      onClick={() => {
                        toggleImgUploadModal(eoplProgramData?.id);
                      }}
                    >
                      Images
                      <PhotographIcon className="h-4 w-4 ml-2" />
                      {imageCount > 0 && (
                        <span className="absolute pointer-events-none text-white rounded-full w-5 h-5 text-center text-xs flex justify-center items-center p-1 bg-danger left-0 -ml-2 -mt-7">
                          {imageCount}
                        </span>
                      )}
                    </Button>
                    {((currentUser.role === Role.view &&
                      data.shoppingListIssueDate !== null) ||
                      currentUser.role !== Role.view) && (
                      <Button
                        className={`bg-primary flex items-center whitespace-nowrap`}
                        tippy="Download"
                        color="primary"
                        onClick={() => {
                          excelDownloadHandler();
                        }}
                      >
                        Download Programme Info &nbsp;
                        <DownloadIcon className="h-4 w-4" />
                      </Button>
                    )}
                  </div>
                </>
              </div>
            </>
          );
        }}
      </Formik>
      {visibleImageUpload === true && imageData !== null && (
        <ImageUploadModal
          data={imageData}
          closeModal={() => toggleImgUploadModal()}
          eoplProgramId={eoplProgramData?.eoplProgramRefId}
        />
      )}
    </>
  );
};

export default MainDetails;
