import React, { useContext, useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
// eslint-disable-next-line no-unused-vars
import { Chart as ChartJS } from "chart.js/auto";
import { ThemeContext } from "../../../components/layout/ThemeProvider";

const BarChart = (props) => {
  const { theme } = useContext(ThemeContext);
  let tickColor = "rgb(30,30,30)";

  switch (theme) {
    case "theme3":
    case "theme4":
      // tickColor = "rgb(255,255,255)";
      break;
    default:
      // tickColor = "rgb(30,30,30)";
      break;
  }

  const { data } = props;

  useEffect(() => {
    if (!data) return;
    if (data) {
      const updateObject = {
        labels: [
          `Active Model Year (${data?.ActiveModelYear})`,
          `Active Run Outs (${data?.ActiveRunOuts})`,
          `Total Projects (${data?.TotalProjects})`,
        ],
        datasets: [
          {
            data: [
              `${data?.ActiveModelYear}`,
              `${data?.ActiveRunOuts}`,
              `${data?.TotalProjects}`,
            ],
            backgroundColor: ["#6C4646", "#BD845B", "#C5AD7E", "#E2D5BE"],
          },
        ],
      };
      setChartData(updateObject);
    }
  }, [data]);

  const options = {
    plugins: {
      legend: {
        display: false,
        labels: {
          color: tickColor,
        },
      },
    },
    // height: "500px",
    // width: "200px",
    maintainAspectRatio: false,
    scales: {
      y: {
        ticks: { color: tickColor },
      },
      x: {
        ticks: { color: tickColor },
      },
    },
  };

  const [chartData, setChartData] = useState({
    labels: [
      "Active Model Year (0)",
      "Active Run Outs (0)",
      "Total Projects (0)",
    ],
    datasets: [
      {
        data: [0, 0, 0],
        backgroundColor: ["#6C4646", "#BD845B", "#C5AD7E", "#E2D5BE"],
        // borderColor: "#525252",
        // borderWidth: 2,
      },
    ],
  });

  return <Bar data={chartData} options={options} />;
};

export default BarChart;
