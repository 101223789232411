import { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getEoplData, resetEoplData } from "../../store/eoplActions";

import EOPLForm from "./form/Form";
import SearchHeader from "../../components/ui/SearchHeader";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "../../packages/modal/Modal";
import useToggle from "../../packages/_utils/useToggle";
import Button from "../../packages/button/Button";
import { XIcon } from "@heroicons/react/solid";

const Edit = () => {
  let params = useParams();

  const location = useLocation();
  const navigate = useNavigate();
  const { eoplProgramRefId, id } = params;
  const byId = id;
  const dispatch = useDispatch();
  const eopl = useSelector((state) => state.eopl.data);
  const [, setEoplNo] = useState(eoplProgramRefId);

  const [customTypes] = useState([
    "misc. Other",
    "misc. Propulsion",
    "misc. BiW",
  ]);

  //#region search header
  const [showSearch, setShowSearch] = useState(!eopl?.eoplProgramRefId);
  const [showCustomOnlyMsg, setShowCustomOnlyMsg] = useState(false);
  const [autoHide, setAutoHide] = useState(true);
  const [searchValue, setSearchValue] = useState(
    eoplProgramRefId || (eopl?.eoplProgramRefId ?? "")
  );

  useEffect(() => {
    if (eopl?.id) {
      dispatch(resetEoplData());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!eopl?.id) {
      setSearchValue("");
    }
  }, [eopl]);

  useEffect(() => {
    return () => {
      dispatch(resetEoplData());
      setEoplNo(null);
    };
  }, [dispatch]);

  const searchClickHandler = (e) => {
    setAutoHide(true);
    if (!e || e === "") {
      setPathName();
      dispatch(resetEoplData());
      setEoplNo(null);
    } else {
      setPathName(e);
      setShowSearch(false);
      dispatch(getEoplData(e));
    }
  };

  const searchChangeHandler = (e) => {
    if (e === "") {
      if (eopl.id) {
        toggle();
        return;
      }
      dispatch(resetEoplData());
      setEoplNo(null);
    }
    setSearchValue(e);
  };

  const setPathName = (spoNum) => {
    let pathname = location.pathname.substring(
      0,
      location.pathname.indexOf("create") + 6
    );
    if (spoNum) pathname += "/" + spoNum;
    navigate(pathname, { replace: true });
  };

  useEffect(() => {
    return () => {
      dispatch(resetEoplData());
    };
  }, [dispatch]);

  useEffect(() => {
    if (autoHide) {
      setShowSearch(!(eopl.id && searchValue !== ""));
    }
  }, [eopl, searchValue, autoHide]);
  //#endregion

  const [toggle, isOpen] = useToggle();

  const confirmClick = () => {
    toggle();
    setShowCustomOnlyMsg(false);
    setPathName();
    dispatch(resetEoplData());
    setEoplNo(null);
    setSearchValue("");
    setShowSearch(true);
  };

  useEffect(() => {
    if (showCustomOnlyMsg) return;
    const isCustomType =
      !customTypes?.includes(eopl?.type.trim()) && eopl?.type !== "";
    if (isCustomType) setShowCustomOnlyMsg(!showCustomOnlyMsg);
  }, [customTypes, eopl?.type, showCustomOnlyMsg]);

  return (
    <>
      <SearchHeader
        header="EOPL Programme Create/Edit"
        label="Custom Programme Reference"
        onClick={searchClickHandler}
        onChange={searchChangeHandler}
        value={searchValue}
        isSearch={true}
        showSearch={showSearch}
        setShowSearch={(e) => {
          setShowSearch(e);
          setAutoHide(false);
        }}
      />

      {showCustomOnlyMsg && (
        <div className="text-primary-1 bg-warning/50 py-2 px-4 mb-2 !-mt-6 bg-white rounded shadow-md">
          <div className="flex items-center justify-between">
            <div className="text-sm">
              Only Custom Programmes of type&nbsp;
              <span className="font-bold">{customTypes.join(", ")}</span> can be
              edited from this page.
            </div>
            <XIcon
              className="h-4 text-danger cursor-pointer"
              onClick={() => {
                dispatch(resetEoplData());
                setShowCustomOnlyMsg(!showCustomOnlyMsg);
              }}
            />
          </div>
        </div>
      )}
      <EOPLForm programData={eopl} id={id} byId={byId} />
      {isOpen && (
        <Modal
          isOpen={isOpen}
          toggle={toggle}
          animate={true}
          closeOnClickOutside={true}
        >
          <ModalHeader>Clear EOPL Project</ModalHeader>
          <ModalBody>
            <div className="d-flex flex-row bd-highlight mb-3">
              <div className="p-2 bd-highlight">
                Clear EOPL Project form and start over
                <p className="text-xs">
                  No changes will be made to the database.
                </p>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button onClick={toggle} color="danger">
              Cancel
            </Button>
            <Button onClick={confirmClick} color="primary" className="mr-1">
              Clear
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};

export default Edit;
