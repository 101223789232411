import React, { useEffect, useState } from "react";
import Window from "../../components/explorer/Window";
import { useDispatch, useSelector } from "react-redux";
import {
  createExplorerWindow,
  deleteExplorerFile,
  deleteExplorerFolder,
  deleteExplorerWindow,
  getExplorerData,
} from "../../store/explorerActions";
import { BsWindowPlus } from "react-icons/bs";
import Button from "../../packages/button/Button";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "../../packages/modal/Modal";
import { FormField } from "../../packages/form/FormField";
import useToggle from "../../packages/_utils/useToggle";
import { toProperCase } from "../../utils/utility";
import ContextMenu from "../../components/ui/ContextMenu";

const Explorer = () => {
  const dispatch = useDispatch();

  const [toggleCreate, isOpenCreate] = useToggle();

  const [toggleConfirm, confirmIsOpen] = useToggle();
  const [addWindowName, setAddWindowName] = useState("");
  const [currentWindowName, setCurrentWindowName] = useState(""); // Add state for current window name

  useEffect(() => {
    dispatch(getExplorerData());
  }, [dispatch]);

  const explorer = useSelector((state) => state.explorer.data);

  const [windowsData, setWindowsData] = useState({});
  const [addNew, setAddNew] = useState();
  const [deleteItem, setDeleteItem] = useState();

  const getQuarter = (month) => {
    if (month >= 1 && month <= 3) return "Q1";
    if (month >= 4 && month <= 6) return "Q2";
    if (month >= 7 && month <= 9) return "Q3";
    if (month >= 10 && month <= 12) return "Q4";
  };

  useEffect(() => {
    if (explorer.length === 0) return;
    // console.log("explorer has changed", explorer);
    const windows = {};
    let globalFolderId = 1; // Global counter for unique folder IDs

    explorer.forEach((item) => {
      const windowId = item.windowId;
      if (!windows[windowId]) {
        windows[windowId] = {
          windowId: windowId,
          windowName: item.windowName,
          folders: {},
          files: [],
          windowAuditUsername: item.windowAuditUsername,
        };
      }

      if (item.folderId) {
        if (!windows[windowId].folders[item.folderId]) {
          windows[windowId].folders[item.folderId] = {
            folderId: item.folderId,
            folderName: item.folderName,
            parentFolderId: item.parentFolderId,
            folderAuditUsername: item.folderAuditUsername,
            children: [],
            files: [],
          };
        }
      }

      if (item.fileId) {
        const file = {
          fileId: item.fileId,
          fileName: item.fileName,
          fileIcon: item.fileIcon,
          fileUrl: item.fileUrl,
          filePath: item.filePath,
          fileDownloadPath: item.fileDownloadPath,
          parentFolderId: item.fileFolderId,
          fileAuditUsername: item.fileAuditUsername,
          fileAuditDateTime: item.fileAuditDateTime,
          // base64Url: item.base64Url,
        };

        if (item.windowName === "Cycle Plan Report Archive") {
          const match = file.fileName.match(/v(\d{2})(\d{2})/);
          if (match) {
            const year = `20${match[1]}`;
            const month = parseInt(match[2], 10);
            const quarter = getQuarter(month);
            const folderName = `Cycle Plan ${quarter} ${year}`;
            if (!windows[windowId].folders[folderName]) {
              windows[windowId].folders[folderName] = {
                folderId: globalFolderId++, // Use global counter for unique folder ID

                folderName: folderName,
                parentFolderId: null,
                folderAuditUsername: item.folderAuditUsername,
                children: [],
                files: [],
              };
            }
            file.parentFolderId =
              windows[windowId].folders[folderName].folderId;

            // console.log(windows[windowId].folders[folderName].folderId);
            windows[windowId].folders[folderName].files.push(file);
            // console.log(`Added file ${file.fileName} to folder ${folderName}`);
          } else {
            // console.log(`File ${file.fileName} does not match the pattern`);
          }
        } else {
          if (item.fileFolderId) {
            if (windows[windowId].folders[item.fileFolderId]) {
              windows[windowId].folders[item.fileFolderId].files.push(file);
            }
          } else {
            windows[windowId].files.push(file);
          }
        }
      }
    });

    // Nest folders within their parent folders
    Object.values(windows).forEach((window) => {
      const folders = window.folders;
      Object.values(folders).forEach((folder) => {
        if (folder.parentFolderId) {
          const parentFolder = folders[folder.parentFolderId];
          if (parentFolder) {
            parentFolder.children.push(folder);
          }
        }
      });
    });

    // Sort the children within each folder by the version number
    Object.values(windows).forEach((window) => {
      if (window.windowName === "Cycle Plan Report Archive") {
        Object.values(window.folders).forEach((folder) => {
          folder.files.sort((a, b) => {
            const versionA = a.fileName.match(/v(\d{2})(\d{2})/);
            const versionB = b.fileName.match(/v(\d{2})(\d{2})/);
            if (versionA && versionB) {
              return parseInt(versionA[2], 10) - parseInt(versionB[2], 10);
            }
            return 0;
          });
        });
      }
    });

    // console.log("windows", windows);
    setWindowsData(windows);
  }, [explorer]);

  useEffect(() => {
    if (deleteItem?.type && !confirmIsOpen) {
      toggleConfirm();
    }
  }, [confirmIsOpen, deleteItem, toggleConfirm]);

  const getConfimrationMessage = (deleteItem) => {
    let name;

    if (deleteItem?.windowId) {
      name = deleteItem?.windowName;
    } else if (deleteItem?.folderId) {
      name = deleteItem?.folderName;
    } else if (deleteItem?.fileId) {
      name = deleteItem?.fileName;
    }

    return (
      <div className="d-flex flex-col bd-highlight mb-3 p-2">
        <p>
          Are you sure you want to delete the {deleteItem?.type.toLowerCase()},{" "}
          <span className="font-semibold">'{name}'</span> ?
        </p>
      </div>
    );
  };

  return (
    <>
      <div className="overflow-auto p-4 text-heading bg-primary/20 rounded-lg min-h-[calc(100vh-10rem)]">
        <div className="flex items-center justify-between mb-3">
          <h1 className="text-2xl font-semibold">EOPL Explorer</h1>
          <div className="flex items-center space-x-4">
            <Button
              className="bg-primary text-typography-2 font-semibold flex items-center space-x-2"
              onClick={() => {
                toggleCreate();
              }}
            >
              <BsWindowPlus className="h-6 w-6" /> <span>Add Window</span>
            </Button>
          </div>
        </div>
        <hr className="border-2 border-primary mb-4" />

        <div className="grid grid-cols-1 md:grid-cols-2 flex-wrap lg:grid-cols-3 xl:grid-cols-4 gap-4 items-start">
          {Object.keys(windowsData).map((windowId) => {
            const windowData = windowsData[windowId];
            let allowAddNew = true;
            if (windowData.windowName === "Cycle Plan Report Archive") {
              allowAddNew = false;
            }
            return (
              <Window
                key={windowId}
                data={windowData}
                setAddNew={allowAddNew && setAddNew}
                addNew={allowAddNew && addNew}
                setDeleteItem={setDeleteItem}
                onContextMenu={(e) => {
                  setCurrentWindowName(windowData.windowName);
                }}
              />
            );
          })}
        </div>
      </div>

      <ContextMenu
        setDeleteItem={setDeleteItem}
        windowName={currentWindowName}
      />

      <Modal
        isOpen={isOpenCreate}
        toggle={toggleCreate}
        animate={true}
        closeOnClickOutside={true}
      >
        <ModalHeader>Create Window</ModalHeader>
        <ModalBody>
          <div className="d-flex flex-col bd-highlight mb-3 p-2">
            <FormField
              labelClassName="text-typography-1 pb-1 pl-1"
              autoFocus
              label={`Enter New Window Name`}
              name={`addNewWindow`}
              placeholder="Window name"
              value={addWindowName}
              onChange={(e) => setAddWindowName(e.target.value)}
              autoComplete="off"
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={toggleCreate}
            color="danger"
            className="ignore-click-outside"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              dispatch(createExplorerWindow(addWindowName));
              toggleCreate();
            }}
            color="primary"
            className="mr-1 ignore-click-outside"
          >
            Create Window
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={confirmIsOpen}
        toggle={toggleConfirm}
        animate={true}
        closeOnClickOutside={true}
      >
        <ModalHeader>Delete {deleteItem?.type}</ModalHeader>
        <ModalBody>{getConfimrationMessage(deleteItem)}</ModalBody>
        <ModalFooter>
          <Button
            onClick={() => {
              setDeleteItem(() => {});
              toggleConfirm();
            }}
            color="danger"
            className="ignore-click-outside"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              if (deleteItem?.windowId) {
                dispatch(deleteExplorerWindow(deleteItem));
              } else if (deleteItem?.folderId) {
                dispatch(deleteExplorerFolder(deleteItem));
              } else if (deleteItem?.fileId) {
                dispatch(deleteExplorerFile(deleteItem));
              }
              setDeleteItem(() => {});
              toggleConfirm();
            }}
            color="primary"
            className="mr-1 ignore-click-outside"
          >
            Delete{" "}
            {toProperCase(
              deleteItem?.type === "windowName"
                ? "Window"
                : deleteItem?.type || ""
            )}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default Explorer;
