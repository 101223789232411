import React, { useState, useEffect, useRef, useCallback } from "react";
import * as d3 from "d3";
import { useSelector } from "react-redux";
import { useLayoutEffect } from "react";
import EoplDrawer from "../../../pages/eopl/EoplDrawer";
import useToggle from "../../../packages/_utils/useToggle";
import ReactDOMServer from "react-dom/server";
import { BookmarkIcon, DocumentTextIcon } from "@heroicons/react/solid";

export const jlr_colours = {
  primary1: "#131313",
  primary2: "#FFFFFF",
  secondary1: "#525252",
  secondary2: "#8C8C8C",
  secondary3: "#C4C4C4",
  secondary4: "#E8E8E8",
  tertiaryCool1: "#417DA5",
  tertiaryCool2: "#5a96be",
  tertiaryCool3: "#7faecd",
  tertiaryCool4: "#a4c5db",
  tertiaryCool5: "#c8dce9",
  tertiaryWarm1: "#E8AB59",
  tertiaryWarm2: "#e9b062",
  tertiaryWarm3: "#efc68f",
  tertiaryWarm4: "#f6ddbc",
  tertiaryWarm5: "#fcf4e9",
  yellow: "#e5e500",
  blue: "#7093b7",
  danger: "#e50000",
  green: "#009900",
  header: "#d2aa87",
  info: "#2563eb",
};

const initialSwimlanes = {
  Runouts: {
    header: "Aged",
    id: "sl-1",
    color: jlr_colours.secondary2,
    tasks: [],
    sublanes: [],
  },
  Active: {
    header: "Active",
    id: "sl-2",
    color: jlr_colours.tertiaryCool1,
    tasks: [],
    sublanes: [],
  },
  Future: {
    header: "Future",
    id: "sl-3",
    color: jlr_colours.tertiaryWarm1,
    tasks: [],
    sublanes: [],
  },
};

const swimlaneColors = ["#A6C3C1", "#a3a3a3"];
const swimlaneDetailColors = ["#dbe7e6", "#d1d1d1"];
const sublaneColors = {
  "#A6C3C1": ["#c0d5d3", "#e4edec"],
  "#a3a3a3": ["#c5c5c5", "#e8e8e8"],
};
const sublaneDetailColors = {
  "#A6C3C1": ["#c9dbd9", "#edf3f2"],
  "#a3a3a3": ["#dcdcdc", "#f3f3f3"],
};
const colorMap = {
  "#8C8C8C": "#595959",
  "#417DA5": "#326180",
  "#E8AB59": "#9d6316",
};
const defaultTaskHeight = 20;
const borderColor = jlr_colours.secondary2;
const borderWidth = "0.5px";

const Timeline = (props) => {
  // state variable for eopl drawer
  const [toggle, isOpen] = useToggle();
  const [eoplProgramId, setEoplProgramId] = useState();
  const changed = useSelector((state) => state.eopl.changed);

  // refs for the timeline height
  const maximumHeightRef = useRef(null);
  const initialHeightRef = useRef(null);

  const wrapperRef = useRef(null); // Reference for the wrapper div
  const wrapperContainerRef = useRef(null); // Reference for the wrapper container div
  const svgRef = useRef(null); // Reference for the SVG
  const headerRef = useRef(null); // Reference for the SVG
  const xScaleRef = useRef(null); // Reference for the SVG

  // const [useBrush] = useState(true);
  const brushRef = useRef(null); // Reference for the SVG
  const miniXScaleRef = useRef(null); // Reference for the SVG

  // timeline variables
  const { data } = props;
  const customDates = useSelector((state) => state.dashboard.customDates);
  const fitToWrapper = useSelector((state) => state.dashboard.fitToWrapper);
  const drawSubmittedIcon = useSelector(
    (state) => state.dashboard.drawSubmittedIcon
  );
  const drawReviewIcons = useSelector(
    (state) => state.dashboard.drawReviewIcons
  );
  const drawAftercare = useSelector((state) => state.dashboard.drawAftercare);

  // Static values that are unlikely to change
  const [headerHeight] = useState(22);
  const [swimlanePadding] = useState(5);
  const [sublanePadding] = useState(2);
  const [taskPadding] = useState(2);
  const [swimlaneHeaderWidth] = useState(230);
  const [sublaneHeaderWidth] = useState(150);
  const [headerPosition] = useState("left"); // Options: "left", "right", "both"
  const [radius] = useState(5);
  // State variables for dynamic settings
  const [timelineData, setTimelineData] = useState();
  // const [customDates] = useState(); // Custom dates that don't change
  const [allSwimlanes, setAllSwimlanes] = useState(); // Manage allSwimlanes in state
  const [filteredSwimlanes, setFilteredSwimlanes] = useState(); // Start with allSwimlanes and filter later
  const [width, setWidth] = useState(1000);
  const [height, setHeight] = useState(300);
  const [heightScaleFactor, setHeightScaleFactor] = useState();
  const [taskHeight, setTaskHeight] = useState();
  const [remainingWidth, setRemainingWidth] = useState(); // Calculated based on headerPosition
  const [activeTasksOnly] = useState(false);
  // header states
  const [showYears] = useState(true);
  const [showQuarters] = useState(true);
  const [showMonths] = useState(false);
  const [showWeeks] = useState(false);
  const [showDays] = useState(false);
  // date states
  const [asOfDate] = useState();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [currentStartDate, setCurrentStartDate] = useState(null);
  const [currentEndDate, setCurrentEndDate] = useState(null);
  const [scalesReady, setScalesReady] = useState(false);
  // for testing sample div at bottom
  // const [windowHeight, setWindowHeight] = useState(0);
  // const [divTop, setDivTop] = useState(0);
  const [remainingSpace, setRemainingSpace] = useState(0);

  const taskPositions = {}; // Store Y positions of tasks by ID for reference

  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  // Set Timeline Data Effect
  useEffect(() => {
    if (data) {
      // console.log("Setting Timeline Data...", data);
      setTimelineData([...data]);
    }
  }, [customDates, data]);

  // Populate the Swimlane Data Effect
  useEffect(() => {
    if (!timelineData) return;
    // console.log("Populate Swimlanes...", timelineData);
    populateSwimlanes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asOfDate, timelineData]);

  // Set the startDate/endDate and currentStartDate/currentEndDate
  useEffect(() => {
    if (!timelineData || timelineData?.length === 0) return;
    const { minDate, maxDate } = findDateRangeSimple(timelineData);
    if (maxDate < minDate) return;

    if (
      minDate instanceof Date &&
      !isNaN(minDate) &&
      maxDate instanceof Date &&
      !isNaN(maxDate)
    ) {
      setStartDate(minDate);
      setEndDate(maxDate);

      // Jan 1st of the previous year (could be anything - just to set the brush to something)
      const startOfYear = new Date(new Date().getFullYear() - 1, 0, 1);

      if (startOfYear < minDate) {
        setCurrentStartDate(minDate);
      } else {
        setCurrentStartDate(startOfYear);
      }

      let adjustedEndDate = adjustEndDateToEndOfDay(maxDate);

      setCurrentEndDate(adjustEndDateToEndOfDay(adjustedEndDate));
    }
  }, [timelineData, filteredSwimlanes]);

  // Save the scales in refs, so they persist without causing re-renders
  useEffect(() => {
    if (timelineData && currentStartDate && currentEndDate) {
      // console.log("Setting xScaleRef with Start/End Dates:", currentStartDate, currentEndDate, "and Remaining Width:", remainingWidth);

      const xScale = d3
        .scaleTime()
        .domain([currentStartDate, currentEndDate])
        .range([0, remainingWidth]);

      const miniXScale = d3
        .scaleTime()
        .domain([startDate, currentEndDate])
        .range([0, remainingWidth]);

      if (xScale && miniXScale) {
        xScaleRef.current = xScale;
        miniXScaleRef.current = miniXScale;
        setScalesReady(true);
      }
    }
  }, [
    startDate,
    currentStartDate,
    currentEndDate,
    remainingWidth,
    timelineData,
  ]);

  // Draw Timeline Effect
  useEffect(() => {
    if (!scalesReady || !allSwimlanes || !heightScaleFactor || !timelineData)
      return;
    // console.log("Draw Timeline...");

    // debouncedDrawTimeline(currentStartDate, currentEndDate);
    drawTimeline(currentStartDate, currentEndDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    scalesReady,
    showYears,
    showQuarters,
    showMonths,
    showWeeks,
    showDays,
    currentStartDate,
    currentEndDate,
    filteredSwimlanes,
    heightScaleFactor,
    // asOfDate,
    // width,
    height,
    // useBrush,
    timelineData,
    drawReviewIcons,
    drawSubmittedIcon,
    drawAftercare,
    remainingWidth,
  ]);

  // Render Brush Effect
  useEffect(() => {
    if (!remainingWidth || !timelineData) return;
    renderBrush();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    remainingWidth,
    showYears,
    showQuarters,
    showMonths,
    showWeeks,
    showDays,
    width,
    height,
    timelineData,
    filteredSwimlanes,
  ]);

  // Return the mapped color or the original color if not found
  const getCustomColor = (color) => {
    return colorMap[color] || color;
  };

  const calculateDimensions = () => {
    // console.log("Calculate Dimensions...");
    const { height: wrapperContainer, width: wrapperContainerWidth } =
      wrapperContainerRef.current.getBoundingClientRect();
    let availableHeight =
      wrapperContainer - headerHeight * 2 - swimlanePadding * 2;
    const availableWidth = wrapperContainerWidth * 0.95;

    setWidth(availableWidth);
    setHeight(availableHeight - headerHeight * 2 - swimlanePadding - 1);

    const windowHeight = window.innerHeight;
    // setWindowHeight(windowHeight);

    const div = document.getElementById("wrapper-container");
    if (div) {
      const divRect = div.getBoundingClientRect();
      // const scrollOffsetY = window.scrollY;
      const adjustedDivTop = divRect.top; // + scrollOffsetY;
      // setDivTop(adjustedDivTop);
      const remainingSpace = windowHeight - adjustedDivTop - 5;

      const maximumHeight = maximumHeightRef.current;
      if (remainingSpace < maximumHeight || !maximumHeight) {
        setRemainingSpace(remainingSpace);
      } else {
        setRemainingSpace(
          initialHeightRef.current + headerHeight * 4 + swimlanePadding * 2 + 5
        );
      }
    }
  };

  // useLayoutEffect(() => {
  //   calculateDimensions(); // Trigger handleResize on initial render
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const debouncedCalculateDimensions = debounce(calculateDimensions, 100);

  useEffect(() => {
    calculateDimensions();
    window.addEventListener("resize", debouncedCalculateDimensions);
    return () => {
      window.removeEventListener("resize", debouncedCalculateDimensions);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    debouncedCalculateDimensions();
  }, [debouncedCalculateDimensions, fitToWrapper]);

  // Calculate the height of swimlanes and sublanes including all tasks and padding
  const calculateSwimlaneHeight = useCallback(
    (swimlane, isSublane = false, isDefault = false) => {
      const th = isDefault ? defaultTaskHeight : taskHeight;
      const effectiveTasks = swimlane.tasks.filter(
        (task) => !task.displayWithId || task.displayWithId === task.id
      );
      let taskTotalHeight = effectiveTasks.length * (th + taskPadding);
      taskTotalHeight += effectiveTasks.length > 0 ? taskPadding : 0;

      if (isSublane) {
        // If it's a sublane, return only the tasks' height without considering sublanes within sublanes
        return taskTotalHeight;
      }

      let sublaneTasks = [];
      // For a swimlane, calculate the total height of its sublanes as well
      const sublaneHeights = swimlane?.sublanes?.reduce((total, sublane) => {
        sublaneTasks = sublane.tasks.filter(
          (task) => !task.displayWithId || task.displayWithId === task.id
        );
        let sublaneHeight = sublaneTasks.length * (th + taskPadding);
        sublaneHeight += sublaneTasks.length > 0 ? taskPadding : 0;
        return total + sublaneHeight + sublanePadding;
      }, 0);

      return (
        taskTotalHeight +
        sublaneHeights +
        (swimlane.sublanes?.length > 0 &&
        effectiveTasks.length === 0 &&
        sublaneTasks.length > 0
          ? sublanePadding
          : 0)
      );
    },
    [sublanePadding, taskHeight, taskPadding]
  );

  // Calculate the Height Scale Factor of the tasks to fill the wrapper
  const calculateHeightScaleFactor = useCallback(
    (totalSVGHeight, availableHeight) => {
      const totalTaskCount = countTotalTasks(filteredSwimlanes);

      if (totalTaskCount === 0 || defaultTaskHeight === 0) {
        return 1;
      }

      const constantHeight =
        totalSVGHeight - defaultTaskHeight * totalTaskCount;
      const scalableHeight = availableHeight - constantHeight;

      return scalableHeight / (defaultTaskHeight * totalTaskCount);
    },
    [filteredSwimlanes]
  );

  // Calculate the total header height (only relevant when user options present, leaving as is.)
  const getHeaderHeight = useCallback(() => {
    let startingYPosition = 0;

    if (showYears) startingYPosition += headerHeight;
    if (showQuarters) startingYPosition += headerHeight;
    if (showMonths) startingYPosition += headerHeight;
    if (showWeeks) startingYPosition += headerHeight;
    if (showDays) startingYPosition += headerHeight;

    return startingYPosition;
  }, [headerHeight, showDays, showMonths, showQuarters, showWeeks, showYears]);

  // Recalculate height scale factor whenever fitToWrapper changes, or the is a width change
  useLayoutEffect(() => {
    if (wrapperRef.current && filteredSwimlanes) {
      const { height: wrapperHeight, width: wrapperWidth } =
        wrapperRef.current.getBoundingClientRect();

      const totalHeaderHeight = getHeaderHeight();

      // Calculate total height based on swimlanes with
      let totalHeight = 0;
      filteredSwimlanes.forEach((swimlane) => {
        const swimlaneHeight = calculateSwimlaneHeight(swimlane, false, true);
        if (swimlaneHeight > 0) totalHeight += swimlaneHeight + swimlanePadding;
      });

      const minHeight =
        totalHeight + totalHeaderHeight * 2 + swimlanePadding + 1;
      const scaleFactor = calculateHeightScaleFactor(minHeight, wrapperHeight);

      const th = Math.max(
        defaultTaskHeight,
        Math.min(defaultTaskHeight * scaleFactor, 40)
      );
      setTaskHeight(th);

      // Calculate total height based on swimlanes with new task height
      let newTotalHeight = 0;
      filteredSwimlanes.forEach((swimlane) => {
        const swimlaneHeight = calculateSwimlaneHeight(swimlane, false, false);
        if (swimlaneHeight > 0)
          newTotalHeight += swimlaneHeight + swimlanePadding;
      });

      const maxHeight =
        newTotalHeight + totalHeaderHeight * 2 + swimlanePadding + 1;

      const calculatedRemainingWidth =
        wrapperWidth -
        (headerPosition === "both"
          ? swimlaneHeaderWidth * 2
          : headerPosition !== "none"
          ? swimlaneHeaderWidth
          : 0);

      if (!initialHeightRef.current) {
        initialHeightRef.current = wrapperHeight - headerHeight * 4;
        // console.log("Setting Initial Height...", initialHeightRef.current);
      }

      setHeightScaleFactor(scaleFactor);
      maximumHeightRef.current = maxHeight;
      setRemainingWidth(calculatedRemainingWidth);
    }
  }, [
    fitToWrapper,
    filteredSwimlanes,
    height,
    width,
    remainingSpace,
    getHeaderHeight,
    swimlanePadding,
    calculateHeightScaleFactor,
    headerPosition,
    swimlaneHeaderWidth,
    headerHeight,
    calculateSwimlaneHeight,
  ]);

  const createSublane = (task, swimlane, sublaneId) => {
    return {
      id: sublaneId,
      header: task.header,
      color:
        swimlane.id === "sl-1"
          ? jlr_colours.secondary3
          : swimlane.id === "sl-2"
          ? jlr_colours.tertiaryCool4
          : jlr_colours.tertiaryWarm4,
      tasks: [
        {
          id: task.id,
          start: task.start,
          end: task.end,
          title: task.title,
          color: swimlane.color,
          weight: 700,
          displayWithId: task.displayWithId,
          submitted: task.submitted,
          reviewOverdue: task.reviewOverdue,
          reviewDue: task.reviewDue,
        },
      ],
    };
  };

  // Populate Swimlanes from Timeline Data
  const populateSwimlanes = () => {
    // Helper function to check task categories
    function categorizeTask(asOfDate, start, end) {
      const asOf = !asOfDate ? new Date() : new Date(asOfDate);
      const startDate = new Date(start);
      const endDate = !end ? startDate : new Date(end);

      if (endDate < asOf) return "Runouts";
      if (startDate <= asOf && endDate >= asOf) return "Active";
      if (startDate > asOf) return "Future";
    }

    // Clone the initialSwimlanes to avoid mutation issues
    // const swimlanesClone = JSON.parse(JSON.stringify(initialSwimlanes));
    const swimlanesClone = structuredClone(initialSwimlanes);

    // Sorting timelineData by 'start' date
    const sortedTimelineData = timelineData.sort((a, b) => {
      const dateA = new Date(a.start);
      const dateB = new Date(b.start);

      return dateA - dateB; // Sorts in ascending order (earlier dates first)
    });

    // Loop through input data and categorize tasks
    sortedTimelineData.forEach((task, index) => {
      const category = categorizeTask(asOfDate, task.start, task.end);
      const swimlane = swimlanesClone[category];
      const sublaneId = `${swimlane.id}-${swimlane.sublanes.length + 1}`;
      const sublane = createSublane(task, swimlane, sublaneId);
      swimlane.sublanes.push(sublane);
    });

    setAllSwimlanes(Object.values(swimlanesClone));
  };

  //#region Swimlane Colors
  const getSwimlaneColors = (index) => {
    const swimlaneColor = swimlaneColors[index % swimlaneColors.length];
    const swimlaneDetailColor =
      swimlaneDetailColors[index % swimlaneDetailColors.length];
    return { swimlaneColor, swimlaneDetailColor };
  };

  const getSublaneColors = (swimlaneColor, sublaneIndex) => {
    const sublaneColor =
      sublaneColors[swimlaneColor][
        sublaneIndex % sublaneColors[swimlaneColor].length
      ];
    const sublaneDetailColor =
      sublaneDetailColors[swimlaneColor][
        sublaneIndex % sublaneDetailColors[swimlaneColor].length
      ];
    return { sublaneColor, sublaneDetailColor };
  };
  //#endregion Swimlane Colors

  // Get the minDate/maxDate from the swimlanes object - not needed as we can use simple method
  // const findDateRange = (swimlanes) => {
  //   let minDate = new Date();
  //   let maxDate = new Date(0);

  //   swimlanes.forEach((lane) => {
  //     lane.tasks.forEach((task) => {
  //       const startDate = new Date(task.start);
  //       const endDate = new Date(task.end);
  //       if (startDate < minDate) minDate = startDate;
  //       if (endDate > maxDate) maxDate = endDate;
  //     });
  //     if (lane.sublanes && lane.sublanes.length > 0) {
  //       lane.sublanes.forEach((sublane) => {
  //         sublane.tasks.forEach((task) => {
  //           const startDate = new Date(task.start);
  //           const endDate = new Date(task.end);
  //           if (startDate < minDate) minDate = startDate;
  //           if (endDate > maxDate) maxDate = endDate;
  //         });
  //       });
  //     }
  //   });
  //   return { minDate, maxDate };
  // };

  // Get the minDate/maxDate from the swimlanes object
  const findDateRangeSimple = (inputData) => {
    let minDate = new Date();
    let maxDate = new Date(0);

    inputData.forEach((task) => {
      const startDate = new Date(task.start);
      const submitted = task.submitted
        ? new Date(task.submitted)
        : new Date(task.start);
      const endDate = new Date(task.end);

      const useStart = submitted < startDate ? submitted : startDate;
      const useEnd = submitted > endDate ? submitted : endDate;

      if (useStart < minDate) minDate = useStart;
      if (useEnd > maxDate) maxDate = useEnd;
    });

    return { minDate, maxDate };
  };

  // Helper function to adjust the end date to the last second of the day (23:59:59)
  const adjustEndDateToEndOfDay = (date) => {
    date.setHours(23, 59, 59, 999); // Set the time to 23:59:59:999
    return date;
  };

  // Draw the entire Timeline and Headers
  const drawTimeline = () => {
    if (!maximumHeightRef.current) return;
    // console.log("Drawing Timeline...", maximumHeightRef.current);

    // Clear the existing chart by removing the SVG
    d3.select("#header-container").selectAll("svg").remove();
    d3.select("#canvas-container").selectAll("svg").remove();

    // Re-render the main chart with the new date range
    renderSwimlanes();

    // Re-render headers (if necessary)
    renderYearHeaders();
    renderQuarterHeaders();
    renderMonthHeaders();
    renderWeekHeaders();
    renderDayHeaders();
  };

  function renderYearHeaders() {
    if (!showYears) return;

    headerRef.current = d3
      .select("#header-container")
      .append("svg")
      .attr("width", width + 2)
      .attr("height", headerHeight * 2)
      .attr("id", "timeline-svg"); // Add an id to track the SVG element

    const headerGroup = headerRef.current
      .append("g")
      .attr("class", "year-header")
      .attr(
        "transform",
        `translate(${
          headerPosition === "left" || headerPosition === "both"
            ? swimlaneHeaderWidth
            : 0
        }, 0)`
      );

    const years = d3.timeYears(startDate, endDate);

    // Manually check for the partial year at the start of the range
    const firstYear = d3.timeYear.floor(startDate);
    if (firstYear < startDate) {
      years.unshift(firstYear); // Add the partial start month
    }

    // Manually check for the partial year at the end of the range
    const lastYear = d3.timeYear.floor(endDate);
    if (lastYear < endDate) {
      years.push(lastYear); // Add the partial end month
    }

    const yearScale = xScaleRef.current; //d3.scaleTime().domain([startDate, endDate]).range([0, remainingWidth]);

    years.forEach((year, index) => {
      let yearStartX = yearScale(year);
      const nextYear = d3.timeYear.offset(year, 1);
      let yearEndX = yearScale(nextYear);
      let yearWidth = yearEndX - yearStartX;

      if (yearStartX < 0) {
        yearWidth += yearStartX;
        yearStartX = 0;
      }

      if (yearEndX > remainingWidth) {
        yearWidth = remainingWidth - yearStartX;
      }

      // Ensure the quarterWidth is non-negative
      if (yearWidth <= 0) {
        return;
      }

      // Define a clipping path for the year text (only clip text inside the year box)
      headerRef.current
        .append("defs")
        .append("clipPath")
        .attr("id", `clip-year-${index}`)
        .append("rect")
        .attr("x", yearStartX)
        .attr("y", 0)
        .attr("width", yearWidth)
        .attr("height", headerHeight);

      headerGroup
        .append("rect")
        .attr("x", yearStartX)
        .attr("y", 0)
        .attr("width", yearWidth)
        .attr("height", headerHeight)
        .attr("fill", jlr_colours.header)
        .attr("stroke", borderColor)
        .attr("stroke-width", borderWidth);

      headerGroup
        .append("text")
        .attr("x", yearStartX + yearWidth / 2)
        .attr("y", headerHeight / 2)
        .attr("dy", ".35em")
        .attr("text-anchor", "middle")
        .text(d3.timeFormat("%Y")(year))
        .style("font-size", "12px")
        .style("font-weight", "700")
        .style("fill", jlr_colours.primary1)
        .attr("clip-path", `url(#clip-year-${index})`);
    });
  }

  function renderQuarterHeaders() {
    if (!showQuarters) return;

    let startingYPosition = showYears ? headerHeight : 0;

    const headerGroup = headerRef.current
      .append("g")
      .attr("class", "quarter-header")
      .attr(
        "transform",
        `translate(${
          headerPosition === "left" || headerPosition === "both"
            ? swimlaneHeaderWidth
            : 0
        }, ${startingYPosition})`
      );

    // Calculate scale for quarters
    const quarterScale = xScaleRef.current; //d3.scaleTime().domain([currentStartDate, currentEndDate]).range([0, remainingWidth]);

    // Get the start of the first full visible quarter and the end of the last full quarter
    const firstFullQuarter = d3.timeMonth.offset(
      d3.timeMonth.floor(startDate),
      -(d3.timeMonth.floor(startDate).getMonth() % 3)
    );
    const lastPartialQuarter = d3.timeMonth.ceil(endDate);

    // Create an array of all full quarters within the visible range
    let quarters = d3.timeMonth
      .every(3)
      .range(firstFullQuarter, lastPartialQuarter);

    // Add the first visible quarter if it's a partial one
    if (currentStartDate < firstFullQuarter) {
      quarters.unshift(d3.timeMonth.floor(currentStartDate));
    }

    // Add last partial quarter if it extends past the visible range
    if (lastPartialQuarter > currentEndDate) {
      quarters.push(lastPartialQuarter);
    }

    let useAbbreviatedLabels = false;

    // Loop through each quarter and draw the quarter block and text
    quarters.forEach((quarter, index) => {
      let quarterStartX = quarterScale(quarter);
      const nextQuarter = d3.timeMonth.offset(quarter, 3);
      let quarterEndX = quarterScale(nextQuarter);
      let quarterWidth = quarterEndX - quarterStartX;

      // Handle partial quarters at the start
      if (quarterStartX < 0) {
        quarterWidth += quarterStartX;
        quarterStartX = 0;
      }

      // Handle partial quarters at the end
      if (quarterEndX > remainingWidth) {
        quarterWidth = remainingWidth - quarterStartX;
      }

      // Ensure the quarterWidth is non-negative
      if (quarterWidth <= 0) {
        return;
      }

      // Define a clipping path for the quarter text (only clip text inside the quarter box)
      headerRef.current
        .append("defs")
        .append("clipPath")
        .attr("id", `clip-quarter-${index}`)
        .append("rect")
        .attr("x", quarterStartX)
        .attr("y", 0)
        .attr("width", quarterWidth)
        .attr("height", headerHeight);

      // Draw the rectangle for each quarter
      headerGroup
        .append("rect")
        .attr("x", quarterStartX)
        .attr("y", 0)
        .attr("width", quarterWidth)
        .attr("height", headerHeight)
        .attr("fill", jlr_colours.header)
        .attr("stroke", borderColor)
        .attr("stroke-width", borderWidth);

      // Adjust the text's x position to center it within the visible portion of the quarter
      const textXPosition = quarterStartX + quarterWidth / 2;

      let label = "Q" + (Math.floor(quarter.getMonth() / 3) + 1);
      let textSize = "10px";

      const tempText = headerGroup
        .append("text")
        .attr("x", textXPosition)
        .attr("y", headerHeight / 2)
        .attr("dy", ".35em")
        .attr("text-anchor", "middle")
        .text(d3.timeFormat("%b")(label))
        .style("font-size", textSize);

      const textWidth = tempText.node().getComputedTextLength();
      tempText.remove();

      if (quarterWidth < textWidth || useAbbreviatedLabels) {
        label = "q" + (Math.floor(quarter.getMonth() / 3) + 1);
        textSize = "8px";
        useAbbreviatedLabels = true;
      }

      // Draw the abbreviated quarter label in the middle of the quarter block
      headerGroup
        .append("text")
        .attr("x", textXPosition)
        .attr("y", headerHeight / 2)
        .attr("dy", ".35em")
        .attr("text-anchor", "middle")
        .text(label)
        .style("font-size", textSize)
        .style("fill", jlr_colours.primary1)
        .attr("clip-path", `url(#clip-quarter-${index})`);
    });
  }

  function renderMonthHeaders() {
    if (!showMonths) return;

    let startingYPosition = showYears ? headerHeight : 0;
    startingYPosition += showQuarters ? headerHeight : 0;

    // Create a group for the month headers at the top
    const headerGroup = headerRef.current
      .append("g")
      .attr("class", "month-header")
      .attr(
        "transform",
        `translate(${
          headerPosition === "left" || headerPosition === "both"
            ? swimlaneHeaderWidth
            : 0
        }, ${startingYPosition})`
      );

    // Create a scale that maps months to their position in the swimlane area
    const monthScale = xScaleRef.current; //d3.scaleTime().domain([currentStartDate, currentEndDate]).range([0, remainingWidth]);

    // Get the full months in the visible date range
    const months = d3.timeMonths(startDate, endDate);

    // Manually check for the partial month at the start of the range
    const firstMonth = d3.timeMonth.floor(startDate);
    if (firstMonth < startDate) {
      months.unshift(firstMonth);
    }

    // Manually check for the partial month at the end of the range
    const lastMonth = d3.timeMonth.floor(endDate);
    if (lastMonth < endDate) {
      months.push(lastMonth);
    }

    let useAbbreviatedLabels = false;

    // Loop through each month and draw a rectangle and its label
    months.forEach((month, index) => {
      let monthStartX = monthScale(month);
      const nextMonth = d3.timeMonth.offset(month, 1);
      let monthEndX = monthScale(nextMonth);
      let monthWidth = monthEndX - monthStartX;

      // Handle partial months at the start of the visible range
      if (monthStartX < 0) {
        monthWidth += monthStartX;
        monthStartX = 0;
      }

      // Handle partial months at the end of the visible range
      if (monthEndX > remainingWidth) {
        monthWidth = remainingWidth - monthStartX;
      }

      // Ensure the monthWidth is non-negative
      if (monthWidth <= 0) {
        return;
      }

      // Define a clipping path for the month text (only clip text inside the month box)
      headerRef.current
        .append("defs")
        .append("clipPath")
        .attr("id", `clip-month-${index}`)
        .append("rect")
        .attr("x", monthStartX)
        .attr("y", 0)
        .attr("width", monthWidth)
        .attr("height", headerHeight);

      // Draw the rectangle for each month (month block)
      headerGroup
        .append("rect")
        .attr("x", monthStartX)
        .attr("y", 0) // Start at the top
        .attr("width", monthWidth)
        .attr("height", headerHeight)
        .attr("fill", jlr_colours.header)
        .attr("stroke", borderColor)
        .attr("stroke-width", borderWidth);

      let label = d3.timeFormat("%b")(month);
      let textSize = "12px";

      const tempText = headerGroup
        .append("text")
        .attr("x", monthStartX + monthWidth / 2)
        .attr("y", headerHeight / 2)
        .attr("dy", ".35em")
        .attr("text-anchor", "middle")
        .text(label)
        .style("font-size", textSize);

      const textWidth = tempText.node().getComputedTextLength();
      tempText.remove();

      if (monthWidth < textWidth || useAbbreviatedLabels) {
        // Choose the text format based on the width
        label = label.charAt(0);
        textSize = "8px";
        useAbbreviatedLabels = true;
      }

      if (monthWidth < 5) return;

      // Draw the abbreviated month name in the middle of the month block
      headerGroup
        .append("text")
        .attr("x", monthStartX + monthWidth / 2)
        .attr("y", headerHeight / 2)
        .attr("dy", ".35em")
        .attr("text-anchor", "middle")
        .text(label)
        .style("font-size", textSize)
        .style("fill", jlr_colours.primary1)
        .attr("clip-path", `url(#clip-month-${index})`);
    });
  }

  function renderWeekHeaders() {
    if (!showWeeks) return;

    let startingYPosition = showYears ? headerHeight : 0;
    startingYPosition += showQuarters ? headerHeight : 0;
    startingYPosition += showMonths ? headerHeight : 0;

    const headerGroup = headerRef.current
      .append("g")
      .attr("class", "week-header")
      .attr(
        "transform",
        `translate(${
          headerPosition === "left" || headerPosition === "both"
            ? swimlaneHeaderWidth
            : 0
        }, ${startingYPosition})`
      );

    // Get all weeks in the visible date range
    const weeks = d3.timeWeeks(startDate, endDate);

    // Create a scale for weeks
    const weekScale = xScaleRef.current; //d3.scaleTime().domain([currentStartDate, currentEndDate]).range([0, remainingWidth]);

    // Handle partial weeks at the start and end
    const firstVisibleWeek = d3.timeWeek.floor(startDate);
    const lastVisibleWeek = d3.timeWeek.ceil(endDate);

    if (firstVisibleWeek < startDate) {
      weeks.unshift(firstVisibleWeek);
    }

    if (lastVisibleWeek > endDate) {
      weeks.push(lastVisibleWeek);
    }

    weeks.forEach((week, index) => {
      let weekStartX = weekScale(week);
      const nextWeek = d3.timeWeek.offset(week, 1);
      let weekEndX = weekScale(nextWeek);
      let weekWidth = weekEndX - weekStartX;

      if (weekStartX < 0) {
        weekWidth += weekStartX;
        weekStartX = 0;
      }

      if (weekEndX > remainingWidth) {
        weekWidth = remainingWidth - weekStartX;
      }

      // Ensure the weekWidth is non-negative
      if (weekWidth <= 0) {
        return;
      }

      // Define a clipping path for the year text (only clip text inside the year box)
      headerRef.current
        .append("defs")
        .append("clipPath")
        .attr("id", `clip-week-${index}`)
        .append("rect")
        .attr("x", weekStartX)
        .attr("y", 0)
        .attr("width", weekWidth)
        .attr("height", headerHeight);

      headerGroup
        .append("rect")
        .attr("x", weekStartX)
        .attr("y", 0)
        .attr("width", weekWidth)
        .attr("height", headerHeight)
        .attr("fill", jlr_colours.header)
        .attr("stroke", borderColor)
        .attr("stroke-width", borderWidth);

      if (weekWidth < 5) return;
      headerGroup
        .append("text")
        .attr("x", weekStartX + weekWidth / 2)
        .attr("y", headerHeight / 2)
        .attr("dy", ".35em")
        .attr("text-anchor", "middle")
        .text(d3.format("d")(d3.timeFormat("%U")(week)))
        .style("font-size", "10px")
        .style("fill", jlr_colours.primary1)
        .attr("clip-path", `url(#clip-week-${index})`);
    });
  }

  function renderDayHeaders() {
    if (!showDays) return;

    let startingYPosition = showYears ? headerHeight : 0;
    startingYPosition += showQuarters ? headerHeight : 0;
    startingYPosition += showMonths ? headerHeight : 0;
    startingYPosition += showWeeks ? headerHeight : 0;

    const headerGroup = headerRef.current
      .append("g")
      .attr("class", "day-header")
      .attr(
        "transform",
        `translate(${
          headerPosition === "left" || headerPosition === "both"
            ? swimlaneHeaderWidth
            : 0
        }, ${startingYPosition})`
      );

    // Get all days in the visible date range
    const days = d3.timeDays(currentStartDate, currentEndDate);

    // Create a scale for days
    const dayScale = xScaleRef.current; //d3.scaleTime().domain([currentStartDate, currentEndDate]).range([0, remainingWidth]);

    // Handle partial days at the start and end
    const firstVisibleDay = d3.timeDay.floor(currentStartDate);
    const lastVisibleDay = d3.timeDay.ceil(currentEndDate);

    if (firstVisibleDay < currentStartDate) {
      days.unshift(firstVisibleDay);
    }

    if (lastVisibleDay > currentEndDate) {
      days.push(lastVisibleDay);
    }

    days.forEach((day, index) => {
      let dayStartX = dayScale(day);
      const nextDay = d3.timeDay.offset(day, 1);
      let dayEndX = dayScale(nextDay);
      let dayWidth = dayEndX - dayStartX;

      if (dayStartX < 0) {
        dayWidth += dayStartX;
        dayStartX = 0;
      }

      if (dayEndX > remainingWidth) {
        dayWidth = remainingWidth - dayStartX;
      }

      // Ensure the dayWidth is non-negative
      if (dayWidth <= 0) {
        return;
      }

      // Define a clipping path for the year text (only clip text inside the year box)
      headerRef.current
        .append("defs")
        .append("clipPath")
        .attr("id", `clip-day-${index}`)
        .append("rect")
        .attr("x", dayStartX)
        .attr("y", 0)
        .attr("width", dayWidth)
        .attr("height", headerHeight);

      headerGroup
        .append("rect")
        .attr("x", dayStartX)
        .attr("y", 0)
        .attr("width", dayWidth)
        .attr("height", headerHeight)
        .attr("fill", jlr_colours.header)
        .attr("stroke", borderColor)
        .attr("stroke-width", borderWidth);

      if (dayWidth < 5) return;

      headerGroup
        .append("text")
        .attr("x", dayStartX + dayWidth / 2)
        .attr("y", headerHeight / 2)
        .attr("dy", ".35em")
        .attr("text-anchor", "middle")
        .text(d3.format("d")(d3.timeFormat("%d")(day)))
        .style("font-size", "10px")
        .style("fill", jlr_colours.primary1)
        .attr("clip-path", `url(#clip-day-${index})`);
    });
  }

  // Count all the tasks in the swimlanes that require their own row (doesn't include rolled up tasks)
  function countTotalTasks(swimlanes) {
    let totalTaskCount = 0;

    swimlanes.forEach((lane) => {
      // Count tasks in the main lane
      totalTaskCount += lane.tasks.filter(
        (task, i) =>
          task.displayWithId === undefined && task.displayWithId !== i
      ).length;

      // Count tasks in each sublane
      lane.sublanes.forEach((sublane) => {
        totalTaskCount += sublane.tasks.filter(
          (task, j) =>
            task.displayWithId === undefined && task.displayWithId !== j
        ).length;
      });
    });

    return totalTaskCount;
  }

  // Filter swimlanes by date - not used unless user options active - leaving as is
  function filterSwimlanesByDate(swimlanes, startDate, endDate) {
    const start = new Date(startDate);
    const end = new Date(endDate);

    return swimlanes
      .map((lane) => {
        // Filter tasks within the swimlane
        const filteredTasks = lane.tasks.filter((task) => {
          const taskStart = new Date(task.start);
          const taskEnd = task.end ? new Date(task.end) : taskStart;

          return taskEnd >= start && taskStart <= end;
        });

        // Filter sublanes and their tasks within the swimlane
        const filteredSublanes = lane.sublanes
          .map((sublane) => {
            const filteredSublaneTasks = sublane.tasks.filter((task) => {
              const taskStart = new Date(task.start);
              const taskEnd = task.end ? new Date(task.end) : taskStart;

              return taskEnd >= start && taskStart <= end;
            });
            return {
              ...sublane,
              tasks: filteredSublaneTasks,
            };
          })
          .filter((sublane) => sublane.tasks.length > 0);

        // Return the swimlane with filtered tasks and sublanes
        return {
          ...lane,
          tasks: filteredTasks,
          sublanes: filteredSublanes,
        };
      })
      .filter((lane) => lane.tasks.length > 0 || lane.sublanes.length > 0);
  }

  useEffect(() => {
    if (!allSwimlanes) return;
    // console.log("Populating Filtered Swimlanes...");

    if (allSwimlanes && allSwimlanes.length > 0) {
      // Check if allSwimlanes is populated
      if (activeTasksOnly) {
        setFilteredSwimlanes(
          filterSwimlanesByDate(allSwimlanes, currentStartDate, currentEndDate)
        );
      } else {
        setFilteredSwimlanes(allSwimlanes);
      }
    }
  }, [allSwimlanes, activeTasksOnly, currentStartDate, currentEndDate]);

  // Calculate entire Timeline height
  const getTimelineHeight = () => {
    // let totalHeight = swimlanePadding; // Reset total height;
    let totalHeight = getHeaderHeight() + swimlanePadding; // Reset total height;
    filteredSwimlanes.forEach((swimlane) => {
      const swimlaneHeight = calculateSwimlaneHeight(swimlane);
      if (swimlaneHeight > 0) totalHeight += swimlaneHeight + swimlanePadding;
    });
    return totalHeight;
  };

  // Set up the SVG filters for drop shadow
  // const setUpSvgFilters = () => {
  //   // Define the drop shadow filter in the defs section
  //   const defs = svgRef.current.append("defs");

  //   const filter = defs.append("filter").attr("id", "dropshadow").attr("height", "130%");

  //   // Define the shadow color using feFlood
  //   filter
  //     .append("feFlood")
  //     .attr("flood-color", "rgba(0, 0, 0, 0.8)") // Shadow color (black with 80% opacity)
  //     .attr("result", "flood");

  //   // Apply the composite operation to merge the flood color with the alpha of the source
  //   filter.append("feComposite").attr("in", "flood").attr("in2", "SourceAlpha").attr("operator", "in").attr("result", "mask");

  //   // Apply Gaussian blur to the mask
  //   filter
  //     .append("feGaussianBlur")
  //     .attr("in", "mask")
  //     .attr("stdDeviation", 1) // Blur radius
  //     .attr("result", "blurredShadow");

  //   // Apply an offset to the shadow
  //   filter
  //     .append("feOffset")
  //     .attr("in", "blurredShadow")
  //     .attr("dx", 1) // Horizontal offset
  //     .attr("dy", 1) // Vertical offset
  //     .attr("result", "offsetShadow");

  //   const feMerge = filter.append("feMerge");

  //   feMerge.append("feMergeNode").attr("in", "offsetBlur");
  //   feMerge.append("feMergeNode").attr("in", "SourceGraphic");
  // };

  function renderSwimlanes() {
    // console.log("renderSwimlanes...");

    const totalHeight = getTimelineHeight();

    // Create an SVG container with the correct height
    svgRef.current = d3
      .select("#canvas-container")
      .append("svg")
      .attr("width", width + 2)
      .attr("height", totalHeight - headerHeight * 2)
      .attr("id", "timeline-svg");

    d3.select("#canvas-container").style(
      "height",
      svgRef.current.attr("height") + "px"
    );
    d3.select("#canvas-container").style("width", width + 2 + "px");

    // setUpSvgFilters();

    let currentYPosition = 0; // Starting Y position including padding before the first swimlane

    // Draw the Swimlanes and Sublanes headers and detail
    filteredSwimlanes.forEach((swimlane, index) => {
      if (swimlane.sublanes.length === 0) return;
      currentYPosition += swimlanePadding;

      let { swimlaneColor, swimlaneDetailColor } = getSwimlaneColors(index);

      if (swimlane.color) {
        swimlaneColor = swimlane.color;
        swimlaneDetailColor = swimlane.color;
      }

      const swimlaneHeight = calculateSwimlaneHeight(swimlane, false);
      const effectiveTasks = swimlane.tasks.filter(
        (task) => !task.displayWithId || task.displayWithId === task.id
      );

      let effectiveTasksHeight =
        effectiveTasks.length * (taskHeight + taskPadding);
      effectiveTasksHeight += effectiveTasks.length > 0 ? taskPadding : 0;

      const taskStartX =
        headerPosition === "left" || headerPosition === "both"
          ? swimlaneHeaderWidth
          : 0;

      // Draw the swimlane detail background
      svgRef.current
        .append("rect")
        .attr("x", taskStartX)
        .attr("y", currentYPosition)
        .attr("width", remainingWidth + 2)
        .attr("height", swimlaneHeight)
        .attr("fill", swimlaneDetailColor)
        .attr("stroke", borderColor)
        .attr("stroke-width", borderWidth);

      // Draw swimlane header (if applicable)
      if (headerPosition === "left" || headerPosition === "both") {
        drawRoundedRect(
          svgRef.current,
          0,
          currentYPosition,
          swimlaneHeaderWidth,
          swimlaneHeight,
          swimlaneColor,
          swimlaneColor,
          radius,
          "left"
        );

        const headerX = (swimlaneHeaderWidth - sublaneHeaderWidth) / 2;

        const textElement = svgRef.current
          .append("text")
          .attr("x", headerX)
          .attr(
            "y",
            currentYPosition +
              (effectiveTasks.length > 0
                ? effectiveTasksHeight / 2
                : swimlaneHeight / 2)
          )
          .attr("width", 100)
          .attr("dy", ".35em")
          .attr("text-anchor", "middle")
          .text(swimlane.header)
          .style("font-size", "18px")
          .style("font-weight", "600")
          .style("fill", jlr_colours.primary1);

        // Call the wrapText function with a max width of 100 (or swimlaneHeaderWidth / 2)
        wrapText(textElement, swimlaneHeaderWidth - sublaneHeaderWidth);
      }

      // Draw the swimlane header on the right
      if (headerPosition === "right" || headerPosition === "both") {
        drawRoundedRect(
          svgRef.current,
          width - swimlaneHeaderWidth,
          currentYPosition,
          swimlaneHeaderWidth,
          swimlaneHeight,
          swimlaneColor,
          swimlaneColor,
          radius,
          "right"
        );

        const headerX = width - (swimlaneHeaderWidth - sublaneHeaderWidth) / 2;

        const textElement = svgRef.current
          .append("text")
          .attr("x", headerX)
          .attr(
            "y",
            currentYPosition +
              (effectiveTasks.length > 0
                ? effectiveTasksHeight / 2
                : swimlaneHeight / 2)
          )
          .attr("dy", ".35em")
          .attr("text-anchor", "middle")
          .text(swimlane.header)
          .style("font-size", "18px")
          .style("font-weight", "600")
          .style("fill", jlr_colours.primary1);

        // Call the wrapText function with a max width of 100 (or swimlaneHeaderWidth / 2)
        wrapText(textElement, swimlaneHeaderWidth - sublaneHeaderWidth);
      }

      let sublaneYPosition =
        currentYPosition + effectiveTasks.length * (taskHeight + taskPadding);
      sublaneYPosition += effectiveTasks.length > 0 ? taskPadding : 0;

      // Loop through sublanes and draw tasks
      if (swimlane.sublanes.length > 0) {
        sublaneYPosition += effectiveTasks.length > 0 ? 0 : sublanePadding;

        swimlane.sublanes.forEach((sublane, sublaneIndex) => {
          // Declare sublaneColor and sublaneDetailColor before the if-else blocks
          let sublaneColor, sublaneDetailColor;

          if (!swimlane.color) {
            // If swimlane doesn't have a specific color, get colors dynamically for the sublane
            ({ sublaneColor, sublaneDetailColor } = getSublaneColors(
              swimlaneColor,
              sublaneIndex
            )); // Deconstruct colors from the function
          } else {
            // If swimlane has a color, use that color for the sublane
            if (sublane.color) {
              // If sublane has a specific color, use it
              sublaneColor = sublane.color;
              sublaneDetailColor = sublane.color;
            } else {
              // Otherwise, use the swimlane color
              sublaneColor = swimlane.color;
              sublaneDetailColor = swimlane.color;
            }
          }

          const effectiveTasks = sublane.tasks.filter(
            (task) => !task.displayWithId || task.displayWithId === task.id
          );
          if (effectiveTasks.length === 0) return;

          let effectiveTasksHeight =
            effectiveTasks.length * (taskHeight + taskPadding);
          effectiveTasksHeight += effectiveTasks.length > 0 ? taskPadding : 0;

          // Draw the sublane detail background
          svgRef.current
            .append("rect")
            .attr("x", taskStartX)
            .attr("y", sublaneYPosition)
            .attr("width", remainingWidth)
            .attr("height", effectiveTasksHeight)
            .attr("fill", sublaneDetailColor)
            .attr("stroke", borderColor)
            .attr("stroke-width", borderWidth);

          if (headerPosition === "both" || headerPosition === "left") {
            drawRoundedRect(
              svgRef.current,
              swimlaneHeaderWidth - sublaneHeaderWidth,
              sublaneYPosition,
              sublaneHeaderWidth - sublanePadding,
              effectiveTasksHeight,
              sublaneColor,
              sublaneColor,
              radius / 2,
              "left"
            );

            const headerX = swimlaneHeaderWidth - sublaneHeaderWidth / 2;

            const taskEnd = sublane.tasks[0].end
              ? new Date(sublane.tasks[0].end)
              : null;

            let sublaneHeaderLabel = sublane.header;

            if (taskEnd) {
              // Calculate 90 days ago
              const aftercareStart = new Date(taskEnd);
              aftercareStart.setDate(taskEnd.getDate() - 90);
            }

            // Append the text element

            const textElement = svgRef.current
              .append("text")
              .attr("x", headerX)
              .attr("width", sublaneHeaderWidth - sublanePadding)
              .attr("y", sublaneYPosition + effectiveTasksHeight / 2)
              .attr("dy", ".35em")
              .attr("text-anchor", "middle")
              .text(sublaneHeaderLabel)
              .style("font-size", "12px")
              .style("fill", jlr_colours.primary1)
              .style("cursor", "pointer")
              .style("hover", "pointer")
              .on("click", () => {
                handleClick(sublane.tasks[0].id);
              })
              .on("mouseover", function () {
                d3.select(this)
                  .style("fill", jlr_colours.info) // Change fill color on hover
                  .style("font-weight", "bold"); // Change font weight on hover
              })
              .on("mouseout", function () {
                d3.select(this)
                  .style("fill", jlr_colours.primary1) // Revert fill color when not hovered
                  .style("font-weight", "normal"); // Revert font weight when not hovered
              });

            // Call the wrapText function with a max width of 100 (or swimlaneHeaderWidth / 2)
            wrapText(textElement, sublaneHeaderWidth);
          }

          if (headerPosition === "right" || headerPosition === "both") {
            // Draw the sublane header
            drawRoundedRect(
              svgRef.current,
              width - swimlaneHeaderWidth,
              sublaneYPosition,
              sublaneHeaderWidth - sublanePadding,
              effectiveTasksHeight,
              sublaneColor,
              sublaneColor,
              radius / 2,
              "right"
            );

            const headerX =
              width - (swimlaneHeaderWidth - sublaneHeaderWidth / 2);

            const textElement = svgRef.current
              .append("text")
              .attr("x", headerX)
              .attr("y", sublaneYPosition + effectiveTasksHeight / 2)
              .attr("width", sublaneHeaderWidth - sublanePadding)
              .attr("dy", ".35em")
              .attr("text-anchor", "middle")
              .text(sublane.header)
              .style("font-size", "12px")
              .style("fill", jlr_colours.primary1);

            // Call the wrapText function with a max width of 100 (or swimlaneHeaderWidth / 2)
            wrapText(textElement, swimlaneHeaderWidth);
          }

          sublaneYPosition += effectiveTasksHeight + sublanePadding;
        });

        currentYPosition = sublaneYPosition;
      } else {
        currentYPosition = sublaneYPosition;
      }
    });

    currentYPosition = 0; //getHeaderHeight(); // reset the Y position before drawing the tasks

    const clippingHeight =
      totalHeight -
      headerHeight -
      swimlanePadding * (filteredSwimlanes.length * 2);

    const drawClippingArea = false;
    if (drawClippingArea) {
      // Create a visible rectangle for debugging the clipping area
      svgRef.current
        .append("rect")
        .attr(
          "x",
          headerPosition === "left" || headerPosition === "both"
            ? swimlaneHeaderWidth
            : 0
        )
        .attr("y", currentYPosition + swimlanePadding)
        .attr("width", remainingWidth)
        .attr("height", clippingHeight)
        .style("fill", "rgba(255, 0, 0, 0.4)")
        .style("stroke", jlr_colours.danger)
        .style("stroke-width", "1px");
    }

    //Define clipping path to prevent tasks from overlapping headers
    svgRef.current
      .append("defs")
      .append("clipPath")
      .attr("id", "clip-path-tasks")
      .append("rect")
      .attr(
        "x",
        headerPosition === "left" || headerPosition === "both"
          ? swimlaneHeaderWidth
          : 0
      )
      .attr("y", currentYPosition + swimlanePadding)
      .attr("width", remainingWidth) // The width excluding the headers
      .attr("height", clippingHeight);

    // After rendering swimlanes and tasks
    drawCustomDates(customDates, filteredSwimlanes);

    // Create a group for all tasks and apply the clipping path
    const timelineGroup = svgRef.current
      .append("g")
      .attr("clip-path", "url(#clip-path-tasks)");

    filteredSwimlanes.forEach((swimlane, index) => {
      if (swimlane.sublanes.length === 0) return;
      currentYPosition += swimlanePadding;

      const effectiveTasks = swimlane.tasks.filter(
        (task) => !task.displayWithId || task.displayWithId === task.id
      );
      // Draw tasks
      drawTasks(timelineGroup, swimlane.tasks, currentYPosition + taskPadding);

      let sublaneYPosition =
        currentYPosition + effectiveTasks.length * (taskHeight + taskPadding);
      sublaneYPosition += effectiveTasks.length > 0 ? taskPadding : 0;

      // Loop through sublanes and draw tasks
      if (swimlane.sublanes.length > 0) {
        sublaneYPosition += effectiveTasks.length > 0 ? 0 : sublanePadding;

        swimlane.sublanes.forEach((sublane, sublaneIndex) => {
          const effectiveTasks = sublane.tasks.filter(
            (task) => !task.displayWithId || task.displayWithId === task.id
          );
          let effectiveTasksHeight =
            effectiveTasks.length * (taskHeight + taskPadding);
          effectiveTasksHeight +=
            effectiveTasks.length > 0 ? taskPadding : -taskPadding;

          drawTasks(
            timelineGroup,
            sublane.tasks,
            sublaneYPosition + taskPadding
          );

          sublaneYPosition += effectiveTasksHeight + sublanePadding;
        });

        currentYPosition = sublaneYPosition; // + sublanePadding;
      } else {
        currentYPosition = sublaneYPosition; // + taskPadding; (double check)
      }
    });

    // After rendering swimlanes and tasks
    drawCustomDates(customDates, filteredSwimlanes, true);

    drawAsOfDate(filteredSwimlanes);
  }

  function drawRoundedRect(
    svg,
    x,
    y,
    width,
    height,
    fill,
    stroke,
    radius,
    side
  ) {
    let shape;

    if (side === "left" || side === "right") {
      let path;
      if (side === "left") {
        // Rounded corners on the left side
        path = `
        M${x + radius},${y}
        h${width - radius}
        v${height}
        h-${width - radius}
        a${radius},${radius} 0 0 1 -${radius},-${radius}
        v-${height - radius * 2}
        a${radius},${radius} 0 0 1 ${radius},-${radius}
      `;
      } else if (side === "right") {
        // Rounded corners on the right side
        path = `
        M${x},${y}
        h${width - radius}
        a${radius},${radius} 0 0 1 ${radius},${radius}
        v${height - radius * 2}
        a${radius},${radius} 0 0 1 -${radius},${radius}
        h-${width - radius}
        v-${height}
      `;
      }
      shape = svg
        .append("path")
        .attr("d", path)
        .attr("fill", fill)
        .attr("stroke", borderColor)
        .attr("stroke-width", borderWidth);
    } else {
      shape = svg
        .append("rect")
        .attr("x", x)
        .attr("y", y)
        .attr("width", width) // Minimum width for milestones
        .attr("height", height)
        .attr("fill", fill)
        .attr("stroke", stroke)
        .attr("stroke-width", borderWidth)
        .attr("rx", radius)
        .attr("ry", radius)
        .attr("filter", "url(#dropshadow)");
    }

    return shape;
  }

  function wrapText(textElement, maxWidth) {
    const words = textElement.text().split(/\s+/).reverse();
    let word;
    let line = [];
    let lineNumber = 0;
    const lineHeight = 1.1; // ems
    const y = parseFloat(textElement.attr("y"));
    const dy = parseFloat(textElement.attr("dy")) || 0;

    // Temporarily append text to calculate number of lines
    let testLine = [];
    let numLines = 0;
    words.forEach((word) => {
      testLine.push(word);
      const testText = textElement.text(testLine.join(" ")).node();
      if (testText.getComputedTextLength() > maxWidth) {
        numLines++;
        testLine = [word];
      }
    });
    numLines++; // One more line for the last segment

    let adjustedY = y;
    if (numLines > 1) {
      //Calculate the y-offset to center the text vertically
      const totalTextHeight = numLines * lineHeight * 10; // Assuming 10px per em
      adjustedY = y - totalTextHeight / 2; // Adjust for centering
    }

    let tspan = textElement
      .text(null)
      .append("tspan")
      .attr("x", textElement.attr("x"))
      .attr("y", adjustedY)
      .attr("dy", dy + "em");

    while ((word = words.pop())) {
      line.push(word);
      tspan.text(line.join(" "));
      if (tspan.node().getComputedTextLength() > maxWidth) {
        line.pop();
        tspan.text(line.join(" "));
        line = [word];
        tspan = textElement
          .append("tspan")
          .attr("x", textElement.attr("x"))
          .attr("y", adjustedY)
          .attr("dy", ++lineNumber * lineHeight + dy + "em")
          .text(word);
      }
    }
  }

  // Function to draw the info box
  const drawInfoBox = (taskShape, task, x, y, radius) => {
    removeInfoBox();

    // Select the canvas-container or another dedicated div
    const container = d3.select("#wrapper");

    // Get the dimensions of the wrapper
    const wrapperWidth = container.node().getBoundingClientRect().width;
    const wrapperHeight = container.node().getBoundingClientRect().height;

    // Define the dimensions of the info box
    let infoItemX = 15; // y position of the first item
    let infoItemGap = 20; // Gap between items
    const infoBoxItems = 4; // Number of items in the info box
    const infoBoxWidth = 195; // Width of the info box
    const infoBoxHeight = infoBoxItems * infoItemGap + infoItemX - 5;

    // Get the scroll offset of the timeline-container

    // y not needed from the mouse event as we are using the task shape
    let yPosition =
      parseFloat(taskShape.attr("y")) -
      headerHeight * 2 -
      swimlanePadding +
      taskHeight * 0.25;

    const scrollOffsetY = d3.select("#timeline-container").node().scrollTop;
    yPosition -= scrollOffsetY;

    let newX = x - infoBoxWidth / 2; // Center the info box horizontally
    let newY = yPosition;

    // Adjust the position if the info box overflows the wrapper
    if (newX + infoBoxWidth > wrapperWidth) {
      newX = wrapperWidth - infoBoxWidth;
    }
    if (newX < swimlaneHeaderWidth) {
      newX = swimlaneHeaderWidth;
    }
    if (newY + infoBoxHeight > wrapperHeight) {
      newY = wrapperHeight - infoBoxHeight;
    }
    if (newY < headerHeight * 2) {
      newY =
        yPosition +
        taskHeight +
        infoBoxHeight -
        swimlanePadding -
        taskHeight * 0.25;
    }

    // Create a group for the info box
    const infoboxGroup = container
      .append("svg")
      .attr("class", "info")
      .style("position", "absolute")
      .style("width", `${infoBoxWidth}px`)
      .style("height", `${infoBoxHeight}px`)
      .style("left", `${newX}px`) // Use the new X position
      .style("top", `${newY}px`) // Use the new Y position
      .style("overflow", "visible")
      .style("pointer-events", "none");

    const infobox = drawRoundedRect(
      infoboxGroup,
      0, // X position within the SVG
      0, // Y position within the SVG
      infoBoxWidth,
      infoBoxHeight,
      "#fff",
      "#000",
      radius,
      "both",
      2 // Border width
    );

    if (infobox) {
      infobox.attr("stroke-width", "1px");
    }

    // add submitted text to infobox
    infoBoxItem(
      infoboxGroup,
      task.submitted,
      "Submitted :",
      infoItemX,
      task.weight
    );

    infoBoxItem(
      infoboxGroup,
      task.end,
      "Aftercare :",
      (infoItemX += infoItemGap),
      task.weight
    );

    infoBoxItem(
      infoboxGroup,
      task.reviewOverdue,
      "Review Overdue :",
      (infoItemX += infoItemGap),
      task.weight,
      task.reviewOverdue && jlr_colours.danger
    );

    infoBoxItem(
      infoboxGroup,
      task.reviewDue,
      "Review Due :",
      (infoItemX += infoItemGap),
      task.weight,
      task.reviewDue && jlr_colours.info
    );
  };

  const infoBoxItem = (element, taskDate, labelHeader, y, weight, color) => {
    const itemDate = taskDate && new Date(taskDate);
    let label = "";

    if (itemDate) {
      label += itemDate.toLocaleDateString("GB");
    } else {
      if (
        labelHeader === "Review Overdue :" ||
        labelHeader === "Review Overdue :"
      ) {
        label += "N/A";
      } else {
        label += "TBC";
      }
    }

    element
      .append("text")
      .attr("x", 10)
      .attr("y", y)
      .attr("dy", "0.35em")
      .attr("text-anchor", "start")
      .text(labelHeader)
      .style("fill", color || jlr_colours.primary1)
      .style("font-weight", weight || 400) // Set the font to bold
      .style("font-size", "12px");

    element
      .append("text")
      .attr("x", 115)
      .attr("y", y)
      .attr("dy", "0.35em")
      .attr("text-anchor", "start")
      .text(label)
      .style("fill", color || jlr_colours.primary1)
      .style("font-weight", weight || 400) // Set the font to bold
      .style("font-size", "12px");
  };

  // Function to remove the info box
  const removeInfoBox = () => {
    d3.select("#wrapper").selectAll(".info").remove();
  };

  // Draw tasks for each swimlane and sublane
  function drawTasks(timelineGroup, tasks, yPosition) {
    let currentIndex = 0; // Track the index for vertical positioning

    tasks.forEach((task, i) => {
      const taskStart = new Date(task.start);

      const taskEnd = task.end
        ? adjustEndDateToEndOfDay(new Date(task.end))
        : null; // Adjust task end to end of day

      // Calculate 90 days ago
      const aftercareStart = new Date(taskEnd);
      aftercareStart.setDate(taskEnd.getDate() - 90);

      // // Calculate 3 months ago (assuming 30 days per month)
      // const aftercareStart = new Date(taskEnd);
      // aftercareStart.setMonth(taskEnd.getMonth() - 3);

      const taskX = xScaleRef.current(taskStart); // X position of the task start date
      const taskWidth = task.end ? xScaleRef.current(taskEnd) - taskX : null; // Width of the rectangle (task duration)

      // Default taskY calculation
      let taskY = yPosition + currentIndex * (taskHeight + taskPadding);

      // Check for `displayWithId`
      if (task.displayWithId && task.displayWithId !== task.id) {
        if (taskPositions[task.displayWithId]) {
          taskY = taskPositions[task.displayWithId]; // Use the Y position of the referenced task
        } else {
          return; // Skip if referenced task hasn't been drawn yet
        }
      } else {
        taskPositions[task.id] = taskY; // Store the Y position of the task
        currentIndex++; // Only increment for non-referenced tasks
      }

      const headerOffset =
        headerPosition === "both" || headerPosition === "left"
          ? swimlaneHeaderWidth
          : 0;

      // Adjust taskX and taskWidth for clipping
      let adjustedTaskX = taskX;
      let adjustedTaskWidth = taskWidth;

      const taskGroup = timelineGroup.append("g").attr("class", "task");

      let taskShape;

      if (!taskEnd) {
        const triangleHeight = taskHeight;
        const triangleWidth = taskHeight / 2; // Equilateral triangle with width half the height

        const polygonPoints = [
          [headerOffset + taskX, taskY].join(","),
          [headerOffset + taskX + triangleWidth, taskY + triangleHeight].join(
            ","
          ),
          [headerOffset + taskX - triangleWidth, taskY + triangleHeight].join(
            ","
          ),
        ].join(" ");

        taskShape = taskGroup
          .append("polygon")
          .attr("x", headerOffset + adjustedTaskX)
          .attr("y", taskY)
          .attr("points", polygonPoints)
          .attr("fill", task.color || "yellow")
          .attr("stroke", borderColor)
          .attr("stroke-width", "0px")
          .attr("filter", "url(#dropshadow)"); // Optional shadow effect
      } else {
        // Determine if task exceeds the bounds on the left or right
        const isLeftClipped = taskStart < currentStartDate;
        const isAftercareLeftClipped = aftercareStart < currentStartDate;
        const isRightClipped = taskEnd > currentEndDate;

        if (isLeftClipped) {
          adjustedTaskX = 0; // Start at the visible left edge
          adjustedTaskWidth = xScaleRef.current(taskEnd); // Adjust the width accordingly
        }

        // if (isAftercareLeftClipped) {
        //   adjustedTaskX = 0; // Start at the visible left edge
        //   adjustedTaskWidth = xScaleRef.current(taskEnd); // Adjust the width accordingly
        // }

        if (isRightClipped) {
          // Adjust width to not extend past the right boundary
          adjustedTaskWidth =
            Math.min(xScaleRef.current(taskEnd), remainingWidth) -
            adjustedTaskX;
        }

        if (adjustedTaskWidth <= 0) {
          return; // Skip if the task is completely out of view
        }

        const pointWidth = 5;
        const y = taskY;
        const h = taskHeight;

        let x = headerOffset + adjustedTaskX;
        let w = adjustedTaskWidth;

        let rightPathData = `
                 M${x},${y + radius}
                 A${radius},${radius} 0 0 1 ${x + radius},${y}
                 H${x + w - pointWidth}
                 L${x + w},${y + h / 2}
                 L${x + w - pointWidth},${y + h}
                 H${x + radius}
                 A${radius},${radius} 0 0 1 ${x},${y + h - radius}
                 Z
               `;

        let leftPathData = `
                 M${x + pointWidth},${y}
                 L${x},${y + h / 2}
                 L${x + pointWidth},${y + h}
                 H${x + w - radius}
                 A${radius},${radius} 0 0 0 ${x + w},${y + h - radius}
                 V${y + radius}
                 A${radius},${radius} 0 0 0 ${x + w - radius},${y}
                 Z
               `;

        let leftRightPathData = `
                 M${x + pointWidth},${y}
                 L${x},${y + h / 2}
                 L${x + pointWidth},${y + h}
                 H${x + w - pointWidth}
                 L${x + w},${y + h / 2}
                 L${x + w - pointWidth},${y}
                 Z
               `;

        let pathData;
        if (
          isLeftClipped &&
          !isRightClipped &&
          adjustedTaskWidth > pointWidth * 1.8
        ) {
          pathData = leftPathData;
        } else if (
          !isLeftClipped &&
          isRightClipped &&
          adjustedTaskWidth > pointWidth * 1.8
        ) {
          pathData = rightPathData;
        } else if (
          isLeftClipped &&
          isRightClipped &&
          adjustedTaskWidth > pointWidth * 1.8
        ) {
          pathData = leftRightPathData;
        }

        if (pathData) {
          // Draw shape with pathdata
          taskShape = taskGroup
            .append("path")
            .attr("x", headerOffset + adjustedTaskX)
            .attr("y", taskY)
            .attr("d", pathData)
            .attr("fill", task.color || "green")
            .attr("stroke", jlr_colours.primary2)
            .attr("stroke-width", borderWidth)
            .attr("filter", "url(#dropshadow)");
        } else {
          // Draw regular rect shape
          taskShape = taskGroup
            .append("rect")
            .attr("x", headerOffset + adjustedTaskX)
            .attr("y", taskY)
            .attr("width", adjustedTaskWidth > 0 ? adjustedTaskWidth : 1) // Minimum width for milestones
            .attr("height", taskHeight)
            .attr("fill", task.color || "green")
            .attr("stroke", jlr_colours.primary2)
            .attr("stroke-width", borderWidth)
            .attr("rx", radius)
            .attr("ry", radius)
            .attr("filter", "url(#dropshadow)");
        }

        // draw the aftercare shape
        if (drawAftercare) {
          let aftercareX = xScaleRef.current(aftercareStart); // X position of the task aftercare date
          let aftercareWidth = task.end
            ? xScaleRef.current(taskEnd) - aftercareX
            : null; // Width of the rectangle (task duration)
          if (aftercareX < adjustedTaskX) {
            aftercareX = adjustedTaskX;
          }

          if (adjustedTaskWidth < aftercareWidth) {
            aftercareWidth = adjustedTaskWidth;
          }

          x = headerOffset + aftercareX;
          w = aftercareWidth;

          pathData = "";
          rightPathData = `
                 M${x},${y + radius}
                 A${radius},${radius} 0 0 1 ${x + radius},${y}
                 H${x + w - pointWidth}
                 L${x + w},${y + h / 2}
                 L${x + w - pointWidth},${y + h}
                 H${x + radius}
                 A${radius},${radius} 0 0 1 ${x},${y + h - radius}
                 Z
               `;

          leftPathData = `
                 M${x + pointWidth},${y}
                 L${x},${y + h / 2}
                 L${x + pointWidth},${y + h}
                 H${x + w - radius}
                 A${radius},${radius} 0 0 0 ${x + w},${y + h - radius}
                 V${y + radius}
                 A${radius},${radius} 0 0 0 ${x + w - radius},${y}
                 Z
               `;

          leftRightPathData = `
                 M${x + pointWidth},${y}
                 L${x},${y + h / 2}
                 L${x + pointWidth},${y + h}
                 H${x + w - pointWidth}
                 L${x + w},${y + h / 2}
                 L${x + w - pointWidth},${y}
                 Z
               `;

          if (
            isAftercareLeftClipped &&
            !isRightClipped &&
            aftercareWidth > pointWidth * 1.8
          ) {
            pathData = leftPathData;
          } else if (
            !isAftercareLeftClipped &&
            isRightClipped &&
            aftercareWidth > pointWidth * 1.8
          ) {
            pathData = rightPathData;
          } else if (
            isAftercareLeftClipped &&
            isRightClipped &&
            aftercareWidth > pointWidth * 1.8
          ) {
            pathData = leftRightPathData;
          }

          if (pathData) {
            taskGroup
              .append("path")
              .attr("d", pathData)
              .attr("fill", getCustomColor(task.color))
              .attr("stroke", jlr_colours.primary2)
              .attr("stroke-width", borderWidth * 2)
              .attr("stroke-dasharray", "3,3,3,3") // Create a dashed line with alternating 3-pixel segments
              .attr("filter", "url(#dropshadow)");
            // .attr("opacity", "0.8");
          } else {
            // draw aftercare shape
            taskGroup
              .append("rect")
              .attr("x", headerOffset + aftercareX)
              .attr("y", taskY)
              .attr("width", aftercareWidth > 0 ? aftercareWidth : 1)
              .attr("height", taskHeight)
              .attr("fill", getCustomColor(task.color))
              .attr("stroke", "#000")
              .attr("stroke-width", 0.5)
              .attr("rx", radius)
              .attr("ry", radius)
              .attr("stroke-dasharray", "2,2")
              .attr("filter", "url(#dropshadow)")
              .style("pointer-events", "none");

            // draw aftercare divider
            taskGroup
              .append("rect")
              .attr("x", headerOffset + aftercareX)
              .attr("y", taskY)
              .attr("width", 3)
              .attr("height", taskHeight)
              .attr("fill", getCustomColor(task.color))
              .attr("stroke", "#000")
              .attr("stroke-width", 0.5)
              .style("pointer-events", "none");
          }
        }
      }

      // add mouseover to the taskshape
      taskShape
        .on("mousemove", function (event) {
          // draw the info box
          const [mouseX, mouseY] = d3.pointer(event); // Get the mouse position relative to the element
          // Draw the info box at the mouse position
          drawInfoBox(taskShape, task, mouseX, mouseY, radius);
        })
        .on("mouseout", function () {
          // remove the info box
          removeInfoBox(taskGroup);
        });

      const iconSize = taskHeight * 0.65; // Size of the icon

      if (drawSubmittedIcon) {
        const submittedStart = task.submitted ? new Date(task.submitted) : null;

        let submittedX = xScaleRef.current(submittedStart); // X position of the task submitted date

        // submittedStart draw bookmark icon
        if (submittedStart && submittedX > -(taskHeight / 2)) {
          // Render the React component to a string
          const iconSvgString = ReactDOMServer.renderToString(
            <BookmarkIcon
              className="text-yellow-400 stroke-primary-1"
              style={{ strokeWidth: 0.8 }}
            />
          );

          // Append the rendered SVG string to the D3 selection
          taskGroup
            .append("foreignObject")
            .attr("x", headerOffset + submittedX - iconSize / 2)
            .attr("y", taskY + taskHeight / 2 - iconSize / 2)
            .attr("width", iconSize)
            .attr("height", iconSize)
            .html(iconSvgString)
            .attr("filter", "url(#dropshadow)")
            .style("pointer-events", "none");
        }
      }

      if (drawReviewIcons) {
        const reviewOverdueStart = task.reviewOverdue
          ? new Date(task.reviewOverdue)
          : null;
        let reviewOverdueX = xScaleRef.current(reviewOverdueStart); // X position of the review overdue date

        // reviewOverdueStart draw a document icon
        if (reviewOverdueStart && reviewOverdueX) {
          // Render the React component to a string
          const iconSvgString = ReactDOMServer.renderToString(
            <DocumentTextIcon
              className="text-danger stroke-primary-1"
              style={{ strokeWidth: 0.8, transform: "rotate(10deg)" }}
            />
          );

          // Append the rendered SVG string to the D3 selection
          taskGroup
            .append("foreignObject")
            .attr("x", headerOffset + reviewOverdueX - iconSize / 2)
            .attr("y", taskY + taskHeight / 2 - iconSize / 2)
            .attr("width", iconSize)
            .attr("height", iconSize)
            .html(iconSvgString)
            .attr("filter", "url(#dropshadow)")
            .style("pointer-events", "none");
        }

        const reviewDueStart = task.reviewDue ? new Date(task.reviewDue) : null;
        let reviewDueX = xScaleRef.current(reviewDueStart); // X position of the review due date

        // reviewDueStart draw a document icon
        if (reviewDueStart && reviewDueX) {
          // Render the React component to a string
          const iconSvgString = ReactDOMServer.renderToString(
            <DocumentTextIcon
              className="text-white stroke-primary-1"
              style={{ strokeWidth: 0.8, transform: "rotate(10deg)" }}
            />
          );

          // Append the rendered SVG string to the D3 selection
          taskGroup
            .append("foreignObject")
            .attr("x", headerOffset + reviewDueX - iconSize / 2)
            .attr("y", taskY + taskHeight / 2 - iconSize / 2)
            .attr("width", iconSize)
            .attr("height", iconSize)
            .html(iconSvgString)
            .attr("filter", "url(#dropshadow)")
            .style("pointer-events", "none");
        }
      }

      let taskEndDateLabel, taskDateLabel;

      let taskStartDateLabel = taskStart.toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "2-digit",
      });

      if (taskEnd) {
        taskEndDateLabel = aftercareStart.toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "2-digit",
        });
      }

      let taskTitleLabel = task.title;
      let taskLabel;

      let taskLabelY = taskY + taskHeight / 2;
      let taskDateLabelY = taskLabelY;

      const includeDates = true;
      const multiline = false;

      if (includeDates && multiline) {
        taskLabelY = taskY + 8;
        taskDateLabelY = taskY + taskHeight - 8;
        taskDateLabel += `${taskStartDateLabel}-${taskEndDateLabel}`;
      } else if (includeDates && !multiline) {
        taskLabel = `${taskStartDateLabel}\u00A0\u00A0\u00A0\u00A0${taskTitleLabel}\u00A0\u00A0\u00A0\u00A0${taskEndDateLabel}`;
      } else {
        taskLabel = taskTitleLabel;
      }
      // Add task text
      taskGroup
        .append("text")
        .attr("x", headerOffset + adjustedTaskX + adjustedTaskWidth / 2)
        .attr("y", taskLabelY)
        .attr("dy", "0.35em")
        .attr("text-anchor", "middle")
        .text(taskLabel)
        .style("fill", jlr_colours.primary1)
        .style("font-weight", task.weight || 400) // Set the font to bold
        .style("font-size", "12px")
        .style("pointer-events", "none");

      if (multiline) {
        // Add task text
        taskGroup
          .append("text")
          .attr("x", headerOffset + adjustedTaskX + adjustedTaskWidth / 2)
          .attr("y", taskDateLabelY)
          .attr("dy", "0.35em")
          .attr("text-anchor", "middle")
          .text(taskDateLabel)
          .style("fill", jlr_colours.primary1)
          .style("font-weight", task.weight || 400) // Set the font to bold
          .style("font-size", "11px")
          .style("pointer-events", "none");
      }

      // this is the old method, will remove later, for now, just stop it rendering.
      const drawInfoBoxes = false;

      if (drawInfoBoxes) {
        const submittedStart = task.submitted ? new Date(task.submitted) : null;
        let submittedX = xScaleRef.current(submittedStart); // X position of the task submitted date

        let aftercareX = xScaleRef.current(aftercareStart); // X position of the task aftercare date

        let aftercareWidth = task.end
          ? xScaleRef.current(taskEnd) - aftercareX
          : null;
        if (aftercareX < adjustedTaskX) {
          aftercareX = adjustedTaskX;
        }

        if (adjustedTaskWidth < aftercareWidth) {
          aftercareWidth = adjustedTaskWidth;
        }

        // Submitted label
        if (submittedStart && submittedX > -(taskHeight / 2)) {
          const triangleWidth = taskHeight / 2; // Equilateral triangle with width half the height
          const submittedLabel =
            "Submitted on: " +
            submittedStart.toLocaleDateString("GB", {
              day: "2-digit",
              month: "2-digit",
              year: "2-digit",
            });

          const { width: textWidth, height: textHeight } = getTextBoxDims(
            timelineGroup,
            headerOffset + submittedX + triangleWidth / 2,
            taskY,
            submittedLabel,
            task.weight,
            "12px"
          );

          let xPos = headerOffset + submittedX - textWidth / 2;

          if (xPos > headerOffset - textWidth) {
            if (xPos < headerOffset) xPos = headerOffset;
            if (xPos + textWidth > width) xPos = width - textWidth;

            const submittedGroup = taskGroup.append("g").attr("class", "info");

            drawRoundedRect(
              submittedGroup,
              xPos - 3,
              taskY + taskHeight / 2 - textHeight / 2,
              textWidth + 6,
              textHeight,
              "#fff",
              "#000",
              radius,
              "both"
            );

            submittedGroup
              .append("text")
              .attr("x", xPos)
              .attr("y", taskY + taskHeight / 2)
              .attr("dy", "0.35em")
              .attr("text-anchor", "start")
              .text(submittedLabel)
              .style("fill", jlr_colours.primary1)
              .style("font-weight", task.weight || 400)
              .style("font-size", "12px");
          }
        }

        // Aftercare label
        const aftercareLabel =
          "Aftercare End " +
          taskEnd.toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "2-digit",
            year: "2-digit",
          });

        const { width: textWidth, height: textHeight } = getTextBoxDims(
          timelineGroup,
          headerOffset + aftercareX + aftercareWidth / 2,
          taskY,
          aftercareWidth,
          task.weight,
          "12px"
        );

        let xPos = headerOffset + aftercareX + 10;

        if (xPos > headerOffset - textWidth) {
          if (xPos < headerOffset) xPos = headerOffset;
          if (xPos + textWidth > width) xPos = width - textWidth;

          const aftercareGroup = taskGroup.append("g").attr("class", "info");

          drawRoundedRect(
            aftercareGroup,
            xPos - 3,
            taskY + taskHeight / 2 - textHeight / 2 - 1,
            textWidth + 26,
            textHeight + 2,
            "#fff",
            "#000",
            radius,
            "both"
          );

          aftercareGroup
            .append("text")
            .attr("x", xPos)
            .attr("y", taskY + taskHeight / 2)
            .attr("dy", "0.35em")
            .attr("text-anchor", "start")
            .text(aftercareLabel)
            .style("fill", jlr_colours.primary1)
            .style("font-weight", task.weight || 400)
            .style("font-size", "12px");
        }
      }
    });
  }

  const getTextBoxDims = (svg, x, y, label, weight, size) => {
    const tempText = svg
      .append("text")
      .attr("x", x)
      .attr("y", y)
      .attr("dy", "0.35em")
      .attr("text-anchor", "middle")
      .text(label)
      .style("font-weight", weight)
      .style("font-size", size);

    const bbox = tempText.node().getBBox();
    const width = bbox.width;
    const height = bbox.height;
    tempText.remove();
    return { width, height };
  };

  function calculateYStartById(laneId) {
    const idParts = laneId.split("-");
    let y = swimlanePadding;

    if (idParts.length === 2) {
      // It's a swimlane (one dash in the ID)
      const swimlaneId = laneId;
      const swimlaneIndex = filteredSwimlanes.findIndex(
        (lane) => lane.id === swimlaneId
      );
      if (swimlaneIndex === -1) return y;

      // Add height of swimlanes before the current swimlane
      for (let i = 0; i < swimlaneIndex; i++) {
        y += calculateSwimlaneHeight(filteredSwimlanes[i]) + swimlanePadding;
      }
      return y;
    } else if (idParts.length === 3) {
      // It's a sublane (two dashes in the ID)
      const swimlaneId = `${idParts[0]}-${idParts[1]}`;
      const sublaneId = laneId;

      const swimlane = filteredSwimlanes.find((lane) => lane.id === swimlaneId);
      if (!swimlane) return y;

      // Calculate Y position for the swimlane itself
      const swimlaneIndex = filteredSwimlanes.findIndex(
        (lane) => lane.id === swimlaneId
      );
      for (let i = 0; i < swimlaneIndex; i++) {
        y +=
          calculateSwimlaneHeight(filteredSwimlanes[i], true) + swimlanePadding;
      }

      // Find sublane within the swimlane and calculate its Y position
      let sublaneY = y;
      for (let j = 0; j < swimlane.sublanes.length; j++) {
        const sublane = swimlane.sublanes[j];
        if (sublane.id === sublaneId) {
          return sublaneY;
        }
        sublaneY +=
          calculateSwimlaneHeight({ tasks: sublane.tasks }, true) +
          sublanePadding;
      }
    }

    // Default to returning the initial Y position if no swimlane/sublane is found
    return y;
  }

  function drawAsOfDate() {
    const customDatesGroup = svgRef.current
      .append("g")
      .attr("class", `as-of-date`);

    // Parse the date strings into Date objects
    const startDate = !asOfDate ? new Date() : new Date(asOfDate);

    // Calculate X positions for start and end dates
    const headerOffset =
      headerPosition === "left" || headerPosition === "both"
        ? swimlaneHeaderWidth
        : 0;
    const xStart = headerOffset + xScaleRef.current(startDate);

    let yStart;
    let height;

    yStart = swimlanePadding * 2;
    height = svgRef.current.attr("height") - yStart - swimlanePadding;

    if (
      !height ||
      xStart < headerOffset ||
      xStart > remainingWidth + headerOffset
    )
      return;

    // Draw a dotted line if there is no end date
    customDatesGroup
      .append("line")
      .attr("x1", xStart)
      .attr("y1", yStart)
      .attr("x2", xStart)
      .attr("y2", yStart + height)
      .attr("stroke", jlr_colours.danger)
      .attr("stroke-width", 2)
      .attr("stroke-dasharray", "4,4");

    let taskDateLabel = "As of";
    let today = new Date();
    const isToday =
      startDate.toLocaleDateString() === today.toLocaleDateString();

    if (isToday) {
      taskDateLabel = "Today";
    } else {
      taskDateLabel = `As of ${startDate.toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "2-digit",
      })}`;
    }

    // Step 1: Create a temporary text element to calculate its size
    const tempText = customDatesGroup
      .append("text")
      .attr("x", xStart)
      .attr("y", yStart + height / 2)
      .attr("dy", ".35em")
      .attr("text-anchor", "start")
      .text(taskDateLabel)
      .style("font-size", "12px");

    // Step 2: Get the computed width and height of the text
    const textWidth = tempText.node().getComputedTextLength() + 10;
    const textHeight = 12 + 5;

    // Step 3: Remove the temporary text element after getting its size
    tempText.remove();

    // Append the rectangle around the text
    const textRect = customDatesGroup
      .append("rect")
      .attr("fill", "rgb(185,28,28)")
      .attr("stroke", "rgb(185,28,28)")
      .attr("stroke-width", 1)
      .attr("opacity", 1)
      .attr("rx", radius)
      .attr("ry", radius);

    textRect.attr("x", xStart - textWidth / 2); // Adjust to position the rectangle left of the rotated text
    textRect.attr("y", yStart - textHeight / 2); // Center it vertically around the text
    textRect.attr("width", textWidth); // Width becomes the height because of the 90-degree rotation
    textRect.attr("height", textHeight); // Height becomes the width based on text width
    // textRect.attr("opacity", 0.8);

    // Append the text
    const textElement = customDatesGroup
      .append("text")
      .attr("x", xStart)
      .attr("dy", ".35em")
      .attr("text-anchor", "middle")
      .text(taskDateLabel)
      .style("fill", "white")
      .style("font-size", "12px")
      .style("pointer-events", "none")
      .attr("opacity", 0.8);

    textElement.attr("y", yStart);
  }

  function drawCustomDates(customDates, swimlanes, overlay) {
    if (!customDates || customDates.length === 0) return;
    const customDatesGroup = svgRef.current
      .append("g")
      .attr("class", `custom-dates${overlay && "-overlay"}`);

    customDates.forEach((cd) => {
      // Parse the date strings into Date objects
      const startDate = cd.start
        ? new Date(cd.start)
        : !asOfDate
        ? new Date()
        : new Date(asOfDate);
      const endDate = cd.end ? new Date(cd.end) : null;

      // Calculate X positions for start and end dates
      const headerOffset =
        headerPosition === "left" || headerPosition === "both"
          ? swimlaneHeaderWidth
          : 0;
      const xStart = headerOffset + xScaleRef.current(startDate);
      const xEnd =
        headerOffset +
        (endDate ? xScaleRef.current(endDate) : xScaleRef.current(startDate));

      // Adjust xStart and xEnd to ensure custom dates are clipped correctly
      let adjustedXStart = xStart;
      let adjustedWidth = xEnd - xStart;

      // Clip if the start date is before the visible range
      if (xStart < headerOffset) {
        adjustedXStart = headerOffset;
        adjustedWidth = xEnd - adjustedXStart;
      }

      // Clip if the end date is beyond the visible range
      if (xEnd > headerOffset + remainingWidth) {
        adjustedWidth = headerOffset + remainingWidth - adjustedXStart;
      }

      if (adjustedWidth < 0) return;

      let yStart;
      let height;

      // Check if the swimlane ID represents a swimlane or a sublane
      const swimlaneIdParts = cd.swimlane?.split("-") || [];

      if (swimlaneIdParts.length === 2) {
        // It is a swimlane (only one dash)
        const swimlane = swimlanes.find((lane) => lane.id === cd.swimlane);
        if (swimlane) {
          height = calculateSwimlaneHeight(swimlane);
        }
        yStart = calculateYStartById(cd.swimlane); // Swimlane with ID 'sl-1'
      } else if (swimlaneIdParts.length === 3) {
        // It is a sublane (two dashes)
        const swimlane = swimlanes.find(
          (lane) => lane.id === swimlaneIdParts.slice(0, 2).join("-")
        );
        if (swimlane) {
          const sublane = swimlane.sublanes.find(
            (sub) => sub.id === cd.swimlane
          );
          if (sublane) {
            height = calculateSwimlaneHeight(sublane, true); // Passing `true` to indicate sublane height
          }
        }

        yStart = calculateYStartById(cd.swimlane); // Swimlane with ID 'sl-1'
      } else {
        yStart = swimlanePadding * 2; // Start from below the headers
        height = svgRef.current.attr("height") - yStart - swimlanePadding; // Full height of the swimlane section
      }

      let customDate;

      if (!height) return;

      // Check if there is no end date
      if (!endDate) {
        // Draw a dotted line if there is no end date
        customDate = customDatesGroup
          .append("line")
          .attr("x1", adjustedXStart)
          .attr("y1", yStart)
          .attr("x2", adjustedXStart)
          .attr("y2", yStart + height)
          .attr("stroke", cd.stroke || "#000")
          .attr("stroke-width", 2)
          .attr("stroke-dasharray", "4,4");
      } else {
        // Draw the custom date rectangle if there is an end date
        customDate = customDatesGroup
          .append("rect")
          .attr("x", adjustedXStart)
          .attr("y", yStart)
          .attr("width", adjustedWidth)
          .attr("height", height)
          .attr("fill", cd.fill)
          .attr("stroke", cd.stroke)
          .attr("stroke-width", 1)
          .attr("opacity", cd.opacity);
      }

      if (overlay) {
        if (endDate) {
          customDate.attr("opacity", cd.opacity / 2);
        }

        let taskDateLabel = cd.title;
        const includeDates = true;

        if (includeDates) {
          let today = new Date();
          let dateText =
            startDate.toLocaleDateString() === today.toLocaleDateString()
              ? "Today"
              : startDate.toLocaleDateString("en-GB", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "2-digit",
                });
          taskDateLabel += `\u00A0${dateText}`;
        }

        // Step 1: Create a temporary text element to calculate its size
        const tempText = customDatesGroup
          .append("text")
          .attr("x", adjustedXStart) // Align text at the left edge of the custom date
          .attr("y", yStart + height / 2) // Vertically center the text within the custom date
          .attr("dy", ".35em") // Adjust vertical alignment for better centering
          .attr("text-anchor", "start") // Align the text starting from the left side
          .text(taskDateLabel)
          .style("font-size", "12px");

        if (endDate) {
          tempText.attr(
            "transform",
            `rotate(-90, ${adjustedXStart}, ${yStart + height / 2})`
          ); // Rotate 90 degrees counter-clockwise
        }
        // Step 2: Get the computed width and height of the text
        const textWidth = tempText.node().getComputedTextLength() + 10; // Width of the text
        const textHeight = 12 + 5; // Based on the font-size (12px)

        // Step 3: Remove the temporary text element after getting its size
        tempText.remove();

        // Append the rectangle around the text
        const textRect = customDatesGroup
          .append("rect")
          .attr("fill", cd.fill) // Transparent fill
          .attr("stroke", cd.stroke) // Border color matching the text
          .attr("stroke-width", 1) // Border width
          .attr("opacity", 1) // Set rectangle opacity
          .attr("rx", radius)
          .attr("ry", radius);

        if (endDate) {
          textRect.attr("width", textHeight); // Width becomes the height because of the 90-degree rotation
          textRect.attr("height", textWidth); // Height becomes the width based on text width
          textRect.attr("x", adjustedXStart - textHeight / 2); // Adjust to position the rectangle left of the rotated text
          textRect.attr("y", yStart + height / 2 - textWidth / 2); // Center it vertically around the text
          textRect.attr("opacity", 0.8);
        } else {
          textRect.attr("x", adjustedXStart - textWidth / 2); // Adjust to position the rectangle left of the rotated text
          textRect.attr("y", yStart - textHeight / 2); // Center it vertically around the text
          textRect.attr("width", textWidth); // Width becomes the height because of the 90-degree rotation
          textRect.attr("height", textHeight); // Height becomes the width based on text width
        }
        // .attr("transform", `rotate(-90, ${adjustedXStart}, ${yStart + height / 2})`); // Rotate rectangle to match text

        // Append the text
        const textElement = customDatesGroup
          .append("text")
          .attr("x", adjustedXStart) // Align text at the left edge of the custom date
          .attr("dy", ".35em") // Adjust vertical alignment for better centering
          .attr("text-anchor", "middle") // Align the text starting from the left side
          .text(taskDateLabel)
          .style("fill", cd.color)
          .style("font-size", "12px")
          .style("pointer-events", "none")
          .attr("opacity", 0.8);

        if (endDate) {
          textElement.attr(
            "transform",
            `rotate(-90, ${adjustedXStart}, ${yStart + height / 2})`
          ); // Rotate 90 degrees counter-clockwise
          textElement.attr("y", yStart + height / 2); // Vertically center the text within the custom date
        } else {
          textElement.attr("y", yStart); // Vertically center the text within the custom date
        }
      }
    });
  }

  //#region Brush
  function renderBrush() {
    // console.log("rendering Brush...");

    const brushHandle = 6;
    const brushStartX =
      headerPosition === "left" || headerPosition === "both"
        ? swimlaneHeaderWidth
        : 0;
    const brushWidth = remainingWidth;
    const brushHeight = headerHeight * 2;

    // Clear the previous brush container to prevent multiple brushes being drawn
    d3.select("#brush-container").selectAll("*").remove();

    // Recreate the brush SVG inside the container
    brushRef.current = d3
      .select("#brush-container")
      .append("svg")
      .style("position", "relative")
      .attr("width", brushWidth)
      .attr("height", brushHeight)
      .style("left", `${brushStartX - brushHandle / 2}px`);

    const brushSvg = brushRef.current;

    d3.select("#brush-container").style(
      "height",
      brushSvg.attr("height") + "px"
    );

    let adjustedEndDate = adjustEndDateToEndOfDay(endDate);
    adjustedEndDate = new Date(
      adjustedEndDate.getFullYear(),
      adjustedEndDate.getMonth() + 1,
      0
    );

    // Scales for each time unit
    const yearScale = d3
      .scaleTime()
      .domain([startDate, adjustedEndDate])
      .range([0, brushWidth]);
    const quarterScale = d3
      .scaleTime()
      .domain([startDate, adjustedEndDate])
      .range([0, brushWidth]);
    const monthScale = d3
      .scaleTime()
      .domain([startDate, adjustedEndDate])
      .range([0, brushWidth]);

    // Get date ranges for each time unit
    const years = d3.timeYears(startDate, adjustedEndDate);

    // Manually check for the partial year at the start of the range
    const firstYear = d3.timeYear.floor(startDate);
    if (firstYear < startDate) {
      years.unshift(firstYear); // Add the partial start month
    }

    // Manually check for the partial year at the end of the range
    const lastYear = d3.timeYear.floor(adjustedEndDate);
    if (lastYear < adjustedEndDate) {
      years.push(lastYear); // Add the partial end month
    }

    const months = d3.timeMonths(startDate, adjustedEndDate);

    // Get the start of the first full visible quarter and the end of the last full quarter
    const firstFullQuarter = d3.timeMonth.offset(
      d3.timeMonth.floor(startDate),
      -(d3.timeMonth.floor(startDate).getMonth() % 3)
    );
    const lastPartialQuarter = d3.timeMonth.ceil(adjustedEndDate);

    // Create an array of all full quarters within the visible range
    let quarters = d3.timeMonth
      .every(3)
      .range(firstFullQuarter, lastPartialQuarter);

    // Add the first visible quarter if it's a partial one
    if (startDate < firstFullQuarter) {
      quarters.unshift(d3.timeMonth.floor(startDate));
    }

    // Add last partial quarter if it extends past the visible range
    if (lastPartialQuarter > adjustedEndDate) {
      quarters.push(lastPartialQuarter);
    }

    let currentYPosition = 0; // Initialize starting Y position

    // Conditionally render each row based on checkbox and adjust the Y position accordingly
    if (showYears) {
      renderTimeScaleRow(
        brushSvg,
        years,
        yearScale,
        currentYPosition,
        "%Y",
        d3.timeYear
      );
      currentYPosition += headerHeight; // Move Y position down by headerHeight after rendering years
    }
    if (showQuarters) {
      renderTimeScaleRow(
        brushSvg,
        quarters,
        quarterScale,
        currentYPosition,
        "Q%d",
        d3.timeMonth,
        (d) => "Q" + (Math.floor(d.getMonth() / 3) + 1),
        true
      );
      currentYPosition += headerHeight; // Move Y position down by headerHeight after rendering quarters
    }
    if (showMonths) {
      renderTimeScaleRow(
        brushSvg,
        months,
        monthScale,
        currentYPosition,
        "%b",
        d3.timeMonth
      );
      currentYPosition += headerHeight; // Move Y position down by headerHeight after rendering months
    }

    addBrush();
  }

  const addBrush = () => {
    if (!scalesReady) return;
    // console.log("addBrush...");
    const brushHandle = 6; // Add padding to make room for the brush handles
    const brushWidth = remainingWidth; //- brushHandle; // Subtract handle padding from remaining width
    const brushHeight = headerHeight * 2;

    let adjustedEndDate = adjustEndDateToEndOfDay(currentEndDate);
    adjustedEndDate = new Date(
      adjustedEndDate.getFullYear(),
      adjustedEndDate.getMonth() + 1,
      0
    );

    // Clear the previous brush container to prevent multiple brushes being drawn
    d3.select(".brush").selectAll("*").remove(); // Make sure to remove all child elements to clear the brush

    const brushSvg = brushRef.current;
    // const monthScale = xScaleRef.current;
    const monthScale = miniXScaleRef.current; // xScaleRefd3.scaleTime().domain([startDate, adjustedEndDate]).range([0, brushWidth]);
    const xStart = monthScale(currentStartDate); // Convert the start date to pixels
    const xEnd = monthScale(adjustedEndDate); // Convert the end date to pixels

    // Brush interaction
    const brush = d3.brushX().extent([
      [0, 0],
      [brushWidth, brushHeight],
    ]);

    brushSvg
      .append("g")
      .attr("class", "brush")
      .call(brush)
      .call(brush.move, [xStart, xEnd - brushHandle]);

    // Select the brush selection area and change its fill color
    d3.select(".selection")
      .attr("stroke-width", "4")
      .attr("stroke", jlr_colours.tertiaryCool1)
      .attr("fill", jlr_colours.primary2)
      .attr("fill-opacity", 0.1);

    d3.selectAll(".handle--w")
      .attr("fill", jlr_colours.tertiaryCool1) // Change handle color
      .attr("width", brushHandle)
      .attr("x", xStart);

    d3.selectAll(".handle--e")
      .attr("fill", jlr_colours.tertiaryCool1) // Change handle color
      .attr("width", brushHandle)
      .attr("x", xEnd - brushHandle);

    let overlayLeftWidth = xStart - 2;
    if (overlayLeftWidth < 0) overlayLeftWidth = 0;

    // Create the darker overlay areas outside the selection
    const overlayLeft = brushSvg
      .append("rect")
      .attr("class", "overlay-left")
      .attr("x", 0)
      .attr("y", 0)
      .attr("width", overlayLeftWidth) // Cover the left side before the selection
      .attr("height", brushHeight)
      .attr("fill", "rgba(0, 0, 0, 0.5)"); // Darker overlay (50% opacity)

    let overlayRightWidth = brushWidth - xEnd;
    if (overlayRightWidth < 0) overlayRightWidth = 0;

    const overlayRight = brushSvg
      .append("rect")
      .attr("class", "overlay-right")
      .attr("x", xEnd + brushHandle / 2) // Start from the end of the selection
      .attr("y", 0)
      .attr("width", overlayRightWidth) // Cover the right side after the selection
      .attr("height", brushHeight)
      .attr("fill", "rgba(0, 0, 0, 0.5)"); // Darker overlay (50% opacity)

    // Update the overlay rectangles when the brush is moved
    brush.on("brush end", function (event) {
      const selection = event.selection;
      if (selection) {
        const [start, end] = selection.map(monthScale.invert);
        // Update the currentStartDate and currentEndDate state
        setCurrentStartDate(start);
        setCurrentEndDate(adjustEndDateToEndOfDay(end));
      }
      overlayLeftWidth = selection[0] - brushHandle / 2;
      if (overlayLeftWidth < 0) overlayLeftWidth = 0;
      overlayLeft.attr("width", overlayLeftWidth); // Adjust left overlay width

      overlayRightWidth = brushWidth - selection[1];
      if (overlayRightWidth < 0) overlayRightWidth = 0;
      overlayRight
        .attr("x", selection[1] + brushHandle / 2) // Adjust right overlay position
        .attr("width", overlayRightWidth); // Adjust right overlay width
    });
  };

  function renderTimeScaleRow(
    brush,
    dates,
    scale,
    rowY,
    format,
    timeInterval,
    customLabelFunc,
    isQuarter = false
  ) {
    const rowHeight = headerHeight; // Set fixed height for each row

    dates.forEach((date, index) => {
      let startX = scale(date);
      const nextDate = isQuarter
        ? d3.timeMonth.offset(date, 3)
        : timeInterval.offset(date, 1); // Use 3 months for quarters
      let endX = scale(nextDate);
      let width = endX - startX;

      // Handle partial units at the start and end
      if (startX < 0) {
        width += startX;
        startX = 0;
      }
      if (endX > remainingWidth) {
        width = remainingWidth - startX;
      }

      // Only render blocks with positive width
      if (width > 0) {
        brush
          .append("rect")
          .attr("x", startX)
          .attr("y", rowY) // Use rowY passed from renderBrush
          .attr("width", width)
          .attr("height", rowHeight)
          .attr("fill", jlr_colours.header)
          .attr("stroke", borderColor)
          .attr("stroke-width", "0.2px");

        brush
          .append("text")
          .attr("x", startX + width / 2) // Center the text within the block
          .attr("y", rowY + rowHeight / 2)
          .attr("dy", ".35em")
          .attr("text-anchor", "middle")
          .text(
            customLabelFunc
              ? customLabelFunc(date)
              : d3.timeFormat(format)(date)
          )
          .style("font-size", rowY === 0 ? "12px" : "10px")
          .style("font-weight", rowY === 0 ? "700" : "400")
          .style("fill", jlr_colours.primary1);
      }
    });
  }
  //#endregion Brush

  const handleClick = useCallback(
    (e) => {
      setEoplProgramId(e);
      toggle();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [toggle]
  );

  return (
    <>
      <div
        id="wrapper-container"
        ref={wrapperContainerRef}
        className="flex items-start justify-center w-full h-full mx-auto p-2"
        style={{
          height: `${fitToWrapper ? `100%` : `${remainingSpace}px`}`,
        }}
      >
        <div
          id="wrapper"
          ref={wrapperRef}
          className="relative h-full"
          style={{
            width: `${width}px`,
          }}
        >
          <div
            id="header-container"
            className="w-full m-0 p-0 select-none"
          ></div>
          <div
            id="timeline-container" //
            className={`relative overflow-y-auto overflow-x-hidden`}
            style={{
              width: `${width}px`,
              height: `${
                fitToWrapper
                  ? `${initialHeightRef.current}px`
                  : `${
                      remainingSpace - headerHeight * 4 - swimlanePadding * 3
                    }px`
              }`,
            }}
          >
            <div
              id="canvas-container"
              className="w-full m-0 p-0 select-none "
            ></div>
          </div>
          <div
            id="brush-container"
            className="w-full m-0 p-0 select-none"
          ></div>
        </div>
      </div>
      <EoplDrawer
        isOpen={isOpen}
        toggle={toggle}
        changed={changed}
        eoplProgramId={eoplProgramId}
      />
      {/* Sample div for position test in dev
      <div
        className={`fixed flex items-center justify-center text-center text-white bg-danger z-50 top-${divTop}px left-2 p-2`}
        style={{
          top: `${divTop}px`,
          height: `${remainingSpace}px`,
        }}
      >
        <div>
          <p>Window Height: {windowHeight}px</p>
          <p>Maximum Height: {maximumHeightRef.current}px</p>
          <p>Div Top: {divTop}px</p>
          <p>Remaining Space: {remainingSpace}px</p>
          <p>Initial Height: {initialHeightRef.current}px</p>
        </div>
      </div>
       */}
    </>
  );
};

export default Timeline;
