import { createSlice } from "@reduxjs/toolkit";

const initialValues = {
  data: {
    // commentCounts: null,
    imageCounts: null,
  },
  loading: false,
};

const programInfoSlice = createSlice({
  name: "programInfo",
  initialState: initialValues,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    replaceProgramInfo(state, action) {
      state.data = action.payload;
    },
    removeImages(state, action) {
      if (action.payload === "childPartsList") {
        state.data.imageCounts = state.data.imageCounts.filter(
          (m) => m.eoplChildPartId !== null
        );
      } else if (action.payload === "shoppingList") {
        state.data.imageCounts = state.data.imageCounts.filter(
          (m) => m.eoplShoppingListId !== null
        );
      }
    },
  },
});

export const programInfoActions = programInfoSlice.actions;

export default programInfoSlice;
