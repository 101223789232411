import { getRequest, postRequest, deleteRequest, patchRequest } from "../axios";
import { authActions } from "./authSlice";
import Toast from "../components/ui/Toast";

export const setLoading = (state) => {
  return (dispatch) => {
    dispatch(authActions.setLoading(state));
  };
};
export const login = (userData) => {
  return async (dispatch) => {
    try {
      const user = await postRequest("users/login", userData);
      dispatch(authActions.replaceUser(user));
    } catch (error) {
      console.error("login: " + error.message);
      Toast({ status: "error", message: error.message });
    }
  };
};

export const logout = () => {
  return async (dispatch) => {
    try {
      const response = await postRequest("users/logout", {});
      Toast({ status: "info", message: response.message });
      dispatch(authActions.replaceUser({}));
    } catch (error) {
      dispatch(authActions.replaceUser({}));
      console.error("logout: " + error.message);
      Toast({ status: "error", message: error.message });
    }
  };
};

export const getUsers = () => {
  return async (dispatch) => {
    try {
      const users = await getRequest("users/");
      dispatch(
        authActions.replaceUsers({
          users: users || [],
        })
      );
    } catch (error) {
      console.error("getUsers: " + error.message);
      Toast({ status: "error", message: error.message });
    }
  };
};

export const getFilteredUsers = (queryString) => {
  return async (dispatch) => {
    try {
      // dispatch(authActions.replaceFilteredUser([]));
      const filteredUsers = await getRequest(`users/filtered/${queryString}`);
      dispatch(authActions.replaceFilteredUser(filteredUsers));
    } catch (error) {
      dispatch(authActions.setNetworkError());
      console.error("getUser: " + error.message);
      if (
        error.message.substring(0, 14) === "Request failed" ||
        error.message.substring(0, 2) === "40"
      ) {
        dispatch(authActions.setLoading(false));
      }
      if (
        error.message.substring(0, 14) !== "Request failed" &&
        error.message.substring(0, 2) !== "40"
      )
        Toast({ status: "error", message: error.message });
    }
  };
};

export const getUser = (userProfile) => {
  return async (dispatch) => {
    try {
      if (process.env.REACT_APP_BUILD_TYPE === "DEV") {
        const user = {
          first_name: "EOPL",
          last_name: "User",
          email: "devuser@jaguarlandrover.com",
          username: "devuser",
          password: "devuser",
          role: "Admin",
          token: null,
        };
        dispatch(authActions.replaceUser(user));
      } else {
        dispatch(authActions.replaceUser(userProfile));
      }
    } catch (error) {
      dispatch(authActions.setNetworkError());
      console.error("getUser: " + error.message);
      if (
        error.message.substring(0, 14) === "Request failed" ||
        error.message.substring(0, 2) === "40"
      ) {
        dispatch(authActions.setLoading(false));
      }
      if (
        error.message.substring(0, 14) !== "Request failed" &&
        error.message.substring(0, 2) !== "40"
      )
        Toast({ status: "error", message: error.message });
    }
  };
};

export const createUser = (userData) => {
  return async (dispatch) => {
    try {
      const user = await postRequest("users/", userData);
      Toast({
        status: "success",
        message: `User: ${user.username} successfully registered`,
      });
      dispatch(authActions.updateUser(user));
    } catch (error) {
      console.error("createUser: " + error.message);
      Toast({ status: "error", message: error.message });
    }
  };
};

export const updateUser = (userData) => {
  return async (dispatch) => {
    try {
      const response = await patchRequest(`users/${userData.id}/`, userData);
      Toast({
        status: "success",
        message: `User ${userData.username} updated successfully`,
      });
      dispatch(authActions.updateUser(response));
    } catch (error) {
      console.error("updateUser: " + error.message);
      Toast({ status: "error", message: error.message });
    }
  };
};

export const deleteUser = (id) => {
  return async (dispatch) => {
    try {
      await deleteRequest(`users/${id}/`);
      Toast({ status: "success", message: `User deleted` });
      dispatch(authActions.removeUser({ id }));
    } catch (error) {
      console.error("deleteUser: " + error.message);
      Toast({ status: "error", message: error.message });
    }
  };
};
