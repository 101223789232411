import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import {
  getDashboard,
  resetDashboard,
  setDrawAftercare,
  setDrawReviewIcons,
  setDrawSubmittedIcon,
  setFitToWrapper,
  setLoaded,
} from "../../store/dashboardActions";
import Timeline from "../../components/dashboard/timeline";
import BarChart from "./charts/BarChart";
import YearlyData from "../../components/dashboard/yearlyData";
import { getFormattedDate, getUniqueEntries } from "../../utils/utility";
import SelectInput from "../../components/ui/SelectInput";
import Button from "../../packages/button/Button";
import { InformationCircleIcon, XIcon } from "@heroicons/react/solid";
import SpinnerIcon from "../../packages/button/SpinnerIcon";

const Dashboard = () => {
  const dashboardData = useSelector((state) => state.dashboard);
  const dashboardDataLoaded = useSelector((state) => state.dashboard.loaded);
  const fitToWrapper = useSelector((state) => state.dashboard.fitToWrapper);
  const drawSubmittedIcon = useSelector(
    (state) => state.dashboard.drawSubmittedIcon
  );
  const drawReviewIcons = useSelector(
    (state) => state.dashboard.drawReviewIcons
  );
  const drawAftercare = useSelector((state) => state.dashboard.drawAftercare);
  const loading = useSelector((state) => state.dashboard.loading);

  const [dashboardGroupData, setDashboardGroupData] = useState();
  const dispatch = useDispatch();

  /* Filter state */
  const [filterString, setFilterString] = useState("");

  const [filters, setFilters] = useState({
    type: ["EoP"],
    brand: [],
    manufacturingYear: [],
    architecturePlatform: [],
    vehicleLine: [],
  });

  const [typeOptions, setTypeOptions] = useState([]);
  const [brandOptions, setBrandOptions] = useState([]);
  const [MYOptions, setMYOptions] = useState([]);
  const [archOptions, setArchOptions] = useState([]);
  const [VLOptions, setVLOptions] = useState([]);
  const [resettingFilters, setResettingFilters] = useState(false);
  const [currentTimeline, setCurrentTimeline] = useState();
  /* End Filter options */

  useEffect(() => {
    dispatch(getDashboard());
    return () => {
      dispatch(resetDashboard());
    };
  }, [dispatch]);

  const [hasRun, setHasRun] = useState(false); // Flag to track if the effect has run

  useEffect(() => {
    if (
      dashboardData?.timeline?.length > 0 &&
      !hasRun &&
      !dashboardDataLoaded
    ) {
      setDashboardGroupData(dashboardData);
      const filteredData = dashboardData?.timeline.filter(
        (item) => item.type === "EoP"
      );
      setCurrentTimeline(filteredData);
      setHasRun(true); // Set the flag to true after running the effect

      if (dashboardData?.timeline && typeOptions.length === 0) {
        setTypeOptions(getUniqueEntries(dashboardData?.timeline, "type"));
        setBrandOptions(getUniqueEntries(dashboardData?.timeline, "brand"));
        setMYOptions(
          getUniqueEntries(dashboardData?.timeline, "manufacturingYear")
        );
        setArchOptions(
          getUniqueEntries(dashboardData?.timeline, "architecturePlatform")
        );
        setVLOptions(getUniqueEntries(dashboardData?.timeline, "vehicleLine"));
      }
      dispatch(setLoaded(true));
    }
  }, [dashboardData, dashboardDataLoaded, dispatch, hasRun, typeOptions]);

  useEffect(() => {
    updateFilters(filters, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty dependency array to run only once on initial render

  const handleFilterChange = (name, filter) => {
    const filterArray = Array.isArray(filter) ? filter : [filter];

    const newValue =
      filterArray.length === 1 && filterArray[0] === "" ? null : filterArray;

    setFilters((prevFilters) => {
      let newFilters = { ...prevFilters };

      if (
        newValue === null ||
        (Array.isArray(newValue) && newValue.length === 0)
      ) {
        delete newFilters[name];
      } else {
        newFilters[name] = newValue;
      }

      updateFilters(newFilters);
      return newFilters;
    });
  };

  const getSelectValue = (value, isMulti = true) => {
    if (!value || value === "") return null;
    if (isMulti) {
      return value;
    } else {
      return Array.isArray(value) ? value.join(", ") : value;
    }
  };

  const applyFilter = (timelineData, filterType, filtersArray) => {
    if (filtersArray && filtersArray.length > 0) {
      return timelineData.filter((item) =>
        filtersArray.includes(item[filterType])
      );
    }
    return timelineData;
  };

  const createFilterString = (filterType, filtersArray) => {
    const filterObject = {
      type: "Type",
      brand: "Brand",
      manufacturingYear: "Manufacturing Year",
      architecturePlatform: "Architecture Platform",
      vehicleLine: "Vehicle Line",
    };
    const isArray = Array.isArray(filtersArray);
    if (isArray && filtersArray.length > 0) {
      return `${filterObject[filterType]}: ${filtersArray.join(", ")} / `;
    }
    return filtersArray;
  };

  const updateFilters = (updatedFilters, updateTimeline = true) => {
    if (!dashboardData?.timeline) return;

    let filterString = "";
    let timelineData = dashboardData?.timeline;

    Object.keys(updatedFilters).forEach((filterType) => {
      const filtersArray = updatedFilters[filterType];
      timelineData = applyFilter(timelineData, filterType, filtersArray);
      filterString += createFilterString(filterType, filtersArray);
    });

    if (updateTimeline) {
      // Check if timelineData is different from currentTimeline
      if (JSON.stringify(timelineData) !== JSON.stringify(currentTimeline)) {
        setCurrentTimeline(timelineData);
      }
    }
    setFilterString(filterString.substring(0, filterString.length - 3));
  };

  const getFilterString = () => {
    return (
      <div className="font-bold">
        <div className="pt-0 px-2 text-sm flex">
          <div className="font-bold w-36 shrink-0">Filtering by :</div>
          <div>{filterString === "" ? "All" : filterString}</div>
        </div>
        <div className="pt-0 px-2 text-sm flex flex-1">
          <div className="font-bold w-36 shrink-0">Programme Count :</div>
          <div>{currentTimeline?.length ?? 0}</div>
        </div>
        <div className="flex">
          <div className="text-sm px-2 flex items-center">
            <label htmlFor="fitToWrapper" className="text-sm w-36">
              Fit to Wrapper :
            </label>
            <input
              type="checkbox"
              id="fitToWrapper"
              className="mt-1"
              onChange={() => {
                dispatch(setFitToWrapper(!fitToWrapper));
              }}
              checked={fitToWrapper}
            />
          </div>
          <div className="text-sm px-2 flex items-center whitespace-nowrap">
            <label htmlFor="drawAftercare" className="text-sm pr-2">
              Aftercare
            </label>
            <input
              type="checkbox"
              id="drawAftercare"
              className="mt-1"
              onChange={() => {
                dispatch(setDrawAftercare(!drawAftercare));
              }}
              checked={drawAftercare}
            />
          </div>
          <div className="text-sm px-2 flex items-center whitespace-nowrap">
            <label htmlFor="drawSubmittedIcon" className="text-sm pr-2">
              Submitted
            </label>
            <input
              type="checkbox"
              id="drawSubmittedIcon"
              className="mt-1"
              onChange={() => {
                dispatch(setDrawSubmittedIcon(!drawSubmittedIcon));
              }}
              checked={drawSubmittedIcon}
            />
          </div>
          <div className="text-sm px-2 flex items-center whitespace-nowrap">
            <label htmlFor="drawReviewIcons" className="text-sm pr-2">
              Reviews
            </label>
            <input
              type="checkbox"
              id="drawReviewIcons"
              className="mt-1"
              onChange={() => {
                dispatch(setDrawReviewIcons(!drawReviewIcons));
              }}
              checked={drawReviewIcons}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="overflow-auto p-4 text-heading bg-primary/20 rounded-lg">
        <div className="flex items-center justify-between">
          <h1 className="text-2xl font-semibold">EOPL Programme Dashboard</h1>
          <div className="flex items-center">
            <div className="text-md font-semibold">
              Reporting for EOPL Programmes can be found within our Tableau
              reporting suite {">"}
            </div>
            <div className="relative">
              <span className="absolute text-xs font-bold border border-2 border-black bg-success/60 px-2 py-1 -top-2 -right-4 rounded-full rotate-12">
                New
              </span>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a href={`${process.env.REACT_APP_TABLEAU_URL}`} target="blank">
                <img
                  className="bg-white rounded p-2 shadow-sm ml-4"
                  width="120px"
                  src="/tableau.png"
                  alt="tableau"
                />
              </a>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-4 my-4 hd-[500px] mdax-h-[288px] overflow-hidden xl:max-w-none max-w-fit w-full min-w-full">
          <div className="w-full col-span-12">
            <div className="relative flex flex-col flex-1 flex-grow shadow-md  w-full bg-primary relative h-full overflow-autoX">
              <div className="flex justify-between text-sm text-typography-2 h-20 py-2 px-4 font-semibold bg-primary shrink-0 items-center">
                <div className="flex-col flex items-start w-3/5 font-bold">
                  {getFilterString()}
                </div>
                <div className="flex items-start space-x-2 w-full justify-end ">
                  {/* <div>
                    <label className={style.label}>{"Start Date"} From</label>
                    <input
                      className={`${style.input} !w-auto`}
                      // min={}
                      // max={max.toISOString().slice(0, 10)}
                      onChange={(e) => {
                        dispatch(
                          setCurrentDates({
                            currentStartDate: e.currentTarget.value,
                          })
                        );
                      }}
                      type="date"
                      // value={minDate}
                    />
                  </div> */}

                  {typeOptions.length > 0 && (
                    <div>
                      <SelectInput
                        label="Type"
                        name="Select_Type"
                        // placeholder="Select Type"
                        menuPlacement="bottom"
                        value={getSelectValue(filters.type)}
                        options={typeOptions}
                        onChangeHandler={(e) => handleFilterChange("type", e)}
                        isMulti={true}
                        //className={"!max-w-[250px] !min-w-[150px]"}
                      />
                    </div>
                  )}
                  {brandOptions.length > 0 && (
                    <div>
                      <SelectInput
                        label="Brand"
                        name="Select_Brand"
                        // placeholder="Select Brand"
                        menuPlacement="bottom"
                        value={getSelectValue(filters.brand)}
                        options={brandOptions}
                        onChangeHandler={(e) => handleFilterChange("brand", e)}
                        isMulti={true}
                        //className={"!max-w-[250px] !min-w-[150px]"}
                      />
                    </div>
                  )}
                  {MYOptions.length > 0 && (
                    <div className="!text-white">
                      <SelectInput
                        label="MY"
                        name="Select_MY"
                        // placeholder="Select MY"
                        menuPlacement="bottom"
                        value={getSelectValue(filters.manufacturingYear)}
                        options={MYOptions}
                        onChangeHandler={(e) =>
                          handleFilterChange("manufacturingYear", e)
                        }
                        isMulti={true}
                        //className={"!max-w-[250px] !min-w-[150px]"}
                      />
                    </div>
                  )}
                  {archOptions.length > 0 && (
                    <div>
                      <SelectInput
                        label="ARCH"
                        name="Select_ARCH"
                        // placeholder="Select ARCH"
                        menuPlacement="bottom"
                        value={getSelectValue(filters.architecturePlatform)}
                        options={archOptions}
                        onChangeHandler={(e) =>
                          handleFilterChange("architecturePlatform", e)
                        }
                        isMulti={true}
                        //className={"!max-w-[250px] !min-w-[150px]"}
                      />
                    </div>
                  )}
                  {VLOptions.length > 0 && (
                    <div>
                      <SelectInput
                        label="Vehicle Line"
                        // placeholder="Select Vehicle Line"
                        labelClassName="text-typography-1"
                        name="Select_Vehicle_Line"
                        menuPlacement="bottom"
                        value={getSelectValue(filters.vehicleLine)}
                        options={VLOptions}
                        onChangeHandler={(e) =>
                          handleFilterChange("vehicleLine", e)
                        }
                        isMulti={true}
                        //className={"!max-w-[250px] !min-w-[150px]"}
                      />
                    </div>
                  )}
                  {typeOptions.length > 0 && (
                    <div className="w-10 flex flex-col items-center ">
                      <label
                        htmlFor={"resetFilters"}
                        className={`text-sm font-bold leading-tight tracking-normal mb-2`}
                      >
                        Reset
                      </label>
                      <Button
                        name="resetFilters"
                        onClick={() => {
                          dispatch(setFitToWrapper(true));
                          if (
                            filters["type"] &&
                            filters["type"].length === 1 &&
                            filters["type"].includes("EoP") &&
                            Object.keys(filters).every(
                              (key) =>
                                key === "type" || filters[key].length === 0
                            )
                          ) {
                            return;
                          }
                          setResettingFilters(true);
                          setFilters({ type: [] });
                          updateFilters({ type: [] });
                          setTimeout(() => {
                            setFilters({ type: ["EoP"] });
                            updateFilters({ type: ["EoP"] });
                            setResettingFilters(false);
                          }, 300);
                        }}
                        className=" !p-2 h-10 flsx items-end justify-end  !ring-0 !ring-offset-0"
                        color="info"
                      >
                        <XIcon className="h-4 w-4" />
                      </Button>
                    </div>
                  )}
                </div>
              </div>
              <div
                id="timeline-wrapper-container"
                className="flex flex-col overflow-x-hidden overflow-y-auto min-h-[444px] bg-gradient-to-br from-[RGB(39,75,99)] via-[RGB(196,196,196)] to-[RGB(166,195,193)]"
                // className="flex flex-col flex-grow flex-1 overflow-auto h-[calc(100%-56px)] min-h-[400px] bg-gradient-to-br from-[RGB(39,75,99)] via-[RGB(196,196,196)] to-[RGB(166,195,193)] "
              >
                {currentTimeline?.length > 0 ? (
                  <>
                    {resettingFilters ? (
                      // not really needed as it was so quick but leaving for now as clearing filters was playing up.
                      <div className="pointer-events-none z-40 w-full h-2/3 flex items-center justify-center text-2xl">
                        <div className="flex items-center justify-center bg-white text-xl px-8 py-3 rounded !shadow !shadow-gray-400 border border-gray-600">
                          <SpinnerIcon className={"!h-8 !w-8 mr-4 border-5"} />
                          <div>Resetting Filters...</div>
                        </div>
                      </div>
                    ) : (
                      <>
                        <Timeline data={currentTimeline} />
                      </>
                    )}
                  </>
                ) : (
                  <div className="flex items-center justify-center w-full h-2d/3 text-typography-1 text-lg font-semibold p-8">
                    <div className="flex flex-col items-center justify-center space-y-4">
                      <div className="pointer-events-none z-40 w-full h-full flex items-center justify-center">
                        <div className="flex itsems-center justify-center bg-white px-8 py-3 rounded !shadow !shadow-gray-400 border border-gray-600">
                          {loading ? (
                            <SpinnerIcon
                              className={"!h-8 !w-8 mr-4 border-3"}
                            />
                          ) : (
                            <InformationCircleIcon className="h-8 w-8 mr-4" />
                          )}
                          <div>
                            <div>
                              {loading
                                ? "Fetching Timeline Data..."
                                : "No programmes found matching criteria :"}
                            </div>
                            {dashboardDataLoaded && (
                              <div className="pt-4">
                                {filterString.split("/").map((filter, i) => {
                                  const parts = filter.split(":");
                                  if (parts[0].trim() === "") return null;
                                  return (
                                    <div key={i} className="text-typography-1">
                                      {parts[0]}:
                                      <span className="font-bold">
                                        {parts[1]}
                                      </span>
                                    </div>
                                  );
                                })}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-4 my-4 overflow-hidden xl:max-w-none max-w-fit w-full min-w-full">
          <div className="w-full col-span-12">
            <div className="flex flex-col flex-1 flex-grow shadow-md  w-full bg-white relative h-full overflow-audto">
              <div className="flex justify-between h5 w-full text-typography-2 p-3 px-4 font-semibold bg-primary">
                <div>Active Programmes</div>
              </div>
              <div className="flex justify-centeDr items-streDtch w-full p-2 my-3">
                <YearlyData data={dashboardGroupData?.quarterWiseData} />
              </div>
            </div>
          </div>
        </div>

        <div className="grid gdrid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-4 my-4 h-70 max-h-[280px] overflow-hidden xl:max-w-nodne dmax-w-fit">
          <div className="w-full col-span-1">
            <div className="shadow-md pb-6 w-full bg-white relative h-full">
              <div className="flex justify-between h5 w-full text-typography-2 p-3 px-4 font-semibold bg-primary whitespace-nowrap">
                <div className="overflow-hidden text-ellipsis">
                  Total EOPL Programmes
                </div>
                <div>
                  {dashboardGroupData?.currentQuarterProjectTotals?.Quarter}{" "}
                  {dashboardGroupData?.currentQuarterProjectTotals?.Year}
                </div>
              </div>
              <div className="flex justify-centeritems-stretch w-full p-3 ">
                {/* <PieChart /> */}
                {/* <LineChart /> */}
                <BarChart
                  data={dashboardGroupData?.currentQuarterProjectTotals}
                />
              </div>
            </div>
          </div>

          <div className="w-full col-span-1">
            <div className="shadow-md pb-6 w-full bg-white  relative font-bold h-full">
              <div className="flex justify-between h5 w-full text-typography-2 p-3 px-4 font-semibold bg-primary whitespace-nowrap">
                <div className="overflow-hidden text-ellipsis">
                  Total Active
                </div>
                <div>
                  {
                    dashboardGroupData?.currentQuarterBrandWiseTotals
                      ?.currentQuarter
                  }
                </div>
              </div>
              <div className="flex justify-center items-end w-full space-x-2 px-4 my-3">
                {!dashboardDataLoaded ? (
                  <SpinnerIcon className="!h-10 !w-10 !mr-0 !border-4 !border-typography-1/20 !border-t-typography-1" />
                ) : (
                  <div
                    className={`text-4xl w-full text-center ${
                      dashboardGroupData?.currentQuarterBrandWiseTotals
                        ?.currentQuarterCount !==
                      dashboardGroupData?.currentQuarterBrandWiseTotals
                        ?.countJaguar +
                        dashboardGroupData?.currentQuarterBrandWiseTotals
                          ?.countLandRover +
                        dashboardGroupData?.currentQuarterBrandWiseTotals
                          ?.countDual
                        ? "text-danger"
                        : "text-typography-1 dark:text-white"
                    }`}
                  >
                    {
                      dashboardGroupData?.currentQuarterBrandWiseTotals
                        ?.currentQuarterCount
                    }
                  </div>
                )}
              </div>
              <div className="flex items-efnd w-full px-2 my-3 items-stretch">
                <div className="text-sm w-full text-typography-1 dark:text-white text-center font-semibold">
                  <span className="pb-2 block">JAG</span>
                  <span className="flex justify-center text-md w-16 mx-auto rounded-full shadow-lg shadow-black bg-brand-1 text-white py-1 px-3">
                    {!dashboardDataLoaded ? (
                      <SpinnerIcon className=" !mr-0 !border-white/20 !border-t-white" />
                    ) : (
                      dashboardGroupData?.currentQuarterBrandWiseTotals
                        ?.countJaguar
                    )}
                  </span>
                </div>
                <div className="text-sm w-full text-typography-1 dark:text-white text-center font-semibold">
                  <span className="pb-2 block">LR</span>
                  <span className="flex justify-center text-md w-16 mx-auto rounded-full shadow-lg shadow-black bg-brand-2 text-white py-1 px-3">
                    {!dashboardDataLoaded ? (
                      <SpinnerIcon className=" !mr-0 !border-white/20 !border-t-white" />
                    ) : (
                      dashboardGroupData?.currentQuarterBrandWiseTotals
                        ?.countLandRover
                    )}
                  </span>
                </div>
                <div className="text-sm w-full text-typography-1 dark:text-white text-center font-semibold">
                  <span className="pb-2 block">Dual</span>
                  <span className="flex justify-center text-md w-16 mx-auto rounded-full shadow-lg shadow-black bg-secondary-1 text-white py-1 px-3">
                    {!dashboardDataLoaded ? (
                      <SpinnerIcon className=" !mr-0 !border-white/20 !border-t-white" />
                    ) : (
                      dashboardGroupData?.currentQuarterBrandWiseTotals
                        ?.countDual
                    )}
                  </span>
                </div>
              </div>
              <span className="text-typography-1 dark:text-white px-4 py-2 text-sm font-bold flex items-center justify-center">
                Active Projects End:{" "}
                {dashboardDataLoaded &&
                  getFormattedDate(
                    dashboardGroupData?.currentQuarterBrandWiseTotals
                      ?.activeProjectEnd
                  )}
              </span>
            </div>
          </div>

          <div className="w-full col-span-1">
            <div className="shadow-md pb-6 w-full bg-white relative font-bold h-full">
              <div className="flex justify-between h5 w-full text-typography-2 p-3 px-4 font-semibold bg-primary whitespace-nowrap">
                <div className="overflow-hidden text-ellipsis">
                  Next Quarter
                </div>
                <div>
                  {dashboardGroupData?.nextQuarterBrandWiseTotals?.nextQuarter}
                </div>
              </div>
              <div className="flex justify-center items-end w-full space-x-2 px-4 my-3">
                {!dashboardDataLoaded ? (
                  <SpinnerIcon className="!h-10 !w-10 !mr-0 !border-4 !border-typography-1/20 !border-t-typography-1" />
                ) : (
                  <div
                    className={`text-4xl w-full text-center ${
                      dashboardGroupData?.nextQuarterBrandWiseTotals
                        ?.nextQuarterProjectCount !==
                      dashboardGroupData?.nextQuarterBrandWiseTotals
                        ?.countJaguar +
                        dashboardGroupData?.nextQuarterBrandWiseTotals
                          ?.countLandRover +
                        dashboardGroupData?.nextQuarterBrandWiseTotals
                          ?.countDual
                        ? "text-danger"
                        : "text-typography-1 dark:text-white"
                    }`}
                  >
                    {
                      dashboardGroupData?.nextQuarterBrandWiseTotals
                        ?.nextQuarterProjectCount
                    }
                  </div>
                )}
              </div>
              <div className="flex items-efnd w-full px-2 my-3 items-stretch">
                <div className="text-sm w-full text-typography-1 dark:text-white text-center font-semibold">
                  <span className="pb-2 block">JAG</span>
                  <span className="flex justify-center text-md w-16 mx-auto rounded-full shadow-lg shadow-black bg-brand-1 text-white py-1 px-3">
                    {!dashboardDataLoaded ? (
                      <SpinnerIcon className=" !mr-0 !border-white/20 !border-t-white" />
                    ) : (
                      dashboardGroupData?.nextQuarterBrandWiseTotals
                        ?.countJaguar
                    )}
                  </span>
                </div>

                <div className="text-sm w-full text-typography-1 dark:text-white text-center font-semibold">
                  <span className="pb-2 block">LR</span>
                  <span className="flex justify-center text-md w-16 mx-auto rounded-full shadow-lg shadow-black bg-brand-2 text-white py-1 px-3">
                    {!dashboardDataLoaded ? (
                      <SpinnerIcon className=" !mr-0 !border-white/20 !border-t-white" />
                    ) : (
                      dashboardGroupData?.nextQuarterBrandWiseTotals
                        ?.countLandRover
                    )}
                  </span>
                </div>
                <div className="text-sm w-full text-typography-1 dark:text-white text-center font-semibold">
                  <span className="pb-2 block">Dual</span>
                  <span className="flex justify-center text-md w-16 text-center mx-auto rounded-full shadow-lg shadow-black bg-secondary-1 text-white py-1 px-3">
                    {!dashboardDataLoaded ? (
                      <SpinnerIcon className=" !mr-0 !border-white/20 !border-t-white" />
                    ) : (
                      dashboardGroupData?.nextQuarterBrandWiseTotals?.countDual
                    )}
                  </span>
                </div>
              </div>
              <span className="text-typography-1 dark:text-white px-4 py-2 text-sm font-bold flex items-center justify-center">
                First Project Starts:{" "}
                {dashboardDataLoaded &&
                  getFormattedDate(
                    dashboardGroupData?.nextQuarterBrandWiseTotals
                      ?.nextFutureProjectStart
                  )}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
