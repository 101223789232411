import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getAllProgramData,
  replaceEoplAllData,
  resetUploaded,
} from "../../store/eoplActions";

import Table, {
  SelectColumnFilter,
  StatusPillInScope,
  StatusPillVersion,
  DateRangeColumnFilter,
  dateBetweenFilterFn,
  exactMatchMultiSelectFilter,
} from "../../components/ui/Table";

import useToggle from "../../packages/_utils/useToggle";
import { getFormattedDate } from "../../utils/utility";

import EoplDrawer from "./EoplDrawer";

const AllData = () => {
  const [toggle, isOpen] = useToggle();
  const changed = useSelector((state) => state.eopl.changed);
  const allData = useSelector((state) => state.eopl.allData);
  const searchValue = useSelector((state) => state.eopl.searchValue);
  const gblFilter = useSelector((state) => state.eopl.gblFilter);
  const dispatch = useDispatch();
  const [tableIndex, setTableIndex] = useState();

  const handleClick = useCallback(
    (e) => {
      setTableIndex(e);
      toggle();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [toggle]
  );

  useEffect(() => {}, [changed]);

  useEffect(() => {
    dispatch(resetUploaded());
    dispatch(getAllProgramData());
    return () => {
      // console.log("Unmounting All Data");
      dispatch(replaceEoplAllData());
    };
  }, [dispatch]);

  const columns = useMemo(
    () => [
      {
        Header: "Programme Reference",
        accessor: "eoplProgramRefId",
        Cell: ({ cell }) => {
          return (
            <div className="flex-none">
              <button
                className="font-bold text-md text-typography-1 hover:text-primary dark:hover:text-typography-2 text-center w-full pr-2 dark:group-hover:text-typography-2"
                value={cell.row.values.id}
                onClick={() => handleClick(cell.row.index)}
              >
                {cell.row.values.eoplProgramRefId}
              </button>
            </div>
          );
        },
        className: "w-16",
      },
      {
        Header: "Programme Title",
        accessor: "name",
        // Filter: SelectColumnFilter,
        // filter: exactMatchMultiSelectFilter,
        className: "w-24",
      },
      {
        Header: "Type",
        accessor: "type",
        // Filter: SelectColumnFilter,
        // filter: "includes",
        Filter: SelectColumnFilter,
        filter: exactMatchMultiSelectFilter,
        className: "w-12 text-center",
      },
      {
        Header: "S4P",
        accessor: "sp4",
        className: "w-12 text-center",
        show: false,
      },
      {
        Header: "JOB 1",
        accessor: "job1",
        Cell: (props) => {
          let date = new Date(props.value);
          return getFormattedDate(date);
        },
        className: "w-12 text-center",
      },
      {
        Header: "In Scope",
        accessor: "inScope",
        Filter: SelectColumnFilter,
        filter: "includes",
        Cell: StatusPillInScope,
        className: "w-16 text-center",
      },
      {
        Header: "Brand",
        accessor: "vehicleBrand",
        Filter: SelectColumnFilter,
        filter: exactMatchMultiSelectFilter,
        className: "w-16",
      },
      {
        Header: "Status",
        accessor: "status",
        Filter: SelectColumnFilter,
        filter: "includes",
        Cell: StatusPillInScope,
        // filter: exactMatchMultiSelectFilter,
        className: "w-16 text-center",
      },
      {
        Header: "MY",
        accessor: "manufacturingYear",
        Filter: SelectColumnFilter,
        filter: exactMatchMultiSelectFilter,
        className: "w-20 text-center",
      },
      {
        Header: "ARCH",
        accessor: "architecturePlatform",
        filter: exactMatchMultiSelectFilter,
        className: "w-16 text-center",
      },
      {
        Header: "VL",
        accessor: "vehicleLine",
        Filter: SelectColumnFilter,
        filter: exactMatchMultiSelectFilter,
        className: "w-16 text-center",
      },
      {
        Header: "Version",
        accessor: "version",
        className: "w-14 text-center",
        Cell: StatusPillVersion,
      },
      {
        Header: "EOPL Start Date",
        accessor: "eoplStartDate",
        Cell: (props) => {
          let date = new Date(props.value);
          const formattedDate = getFormattedDate(date);
          return formattedDate;
        },
        Filter: DateRangeColumnFilter,
        filter: dateBetweenFilterFn,
        className: "w-20 text-center",
      },
      {
        Header: "Created Date",
        accessor: "createdDateTime",
        Cell: (props) => {
          let date = new Date(props.value);
          return getFormattedDate(date);
        },
        className: "w-20 text-center",
      },
      {
        Header: "Last Updated",
        accessor: "auditDateTime",
        Cell: (props) => {
          let date = new Date(props.value);
          return getFormattedDate(date);
        },
        className: "w-20 text-center",
        show: false,
      },
    ],
    [handleClick]
  );

  const getByPartNumber = (e) => {
    dispatch(getAllProgramData(e));
  };

  const filters = useSelector((state) => state.eopl.filters);
  const filterValue =
    allData.length === 0 || (!searchValue && !gblFilter)
      ? ""
      : gblFilter || searchValue.toString();

  return (
    <>
      <EoplDrawer
        isOpen={isOpen}
        toggle={toggle}
        changed={changed}
        eoplProgramId={allData[tableIndex]?.id}
      />

      <Table
        columns={columns}
        data={allData}
        title="EOPL Programmes"
        filterValue={filterValue}
        getByPartNumber={getByPartNumber}
        filters={filters}
      />
    </>
  );
};

export default AllData;
