// import { getRequest } from '../axios';
// import Login from '../pages/auth/Login';
import { globalActions } from "./globalSlice";
import { profileActions } from "./profileSlice";

export const getActiveProfile = () => {
  return async (dispatch) => {
    // Capture the start time
    const startTime = new Date();
    const formattedStartTime = startTime.toLocaleString("en-GB");
    console.log(`Start Time: ${formattedStartTime}`);

    try {
      console.log("getActiveProfile");
      if (process.env.REACT_APP_BUILD_TYPE === "DEV") {
        dispatch(
          globalActions.setLoading({
            loading: true,
            label: "Checking Session...",
          })
        );

        const userObject = {
          username: "devuser",
          email: "devuser@jaguarlandrover.com",
          first_name: "Dev",
          last_name: "User",
          password: "devuser",
          role: "Admin",
          is_active: true,
        };

        // Simulate a long-running fetch request
        const simulatedFetch = new Promise((resolve) => {
          setTimeout(() => {
            resolve(userObject);
          }, 1000); // Simulate an X seconds delay
        });

        // Timeout promise
        const timeout = new Promise((resolve) => {
          setTimeout(() => {
            resolve({ timeout: true });
          }, 3000); // 3 seconds timeout
        });

        // Countdown timer
        let seconds = 3;
        dispatch(globalActions.setSeconds(seconds));
        const countdown = setInterval(() => {
          seconds -= 1;
          dispatch(globalActions.setSeconds(seconds));
          if (seconds <= 0) {
            clearInterval(countdown);
          }
        }, 1000);

        const result = await Promise.race([simulatedFetch, timeout]);

        if (result.timeout) {
          dispatch(
            globalActions.setTimeoutExceeded({
              loading: true,
              label: "Please login again",
              message: "Click to close",
            })
          );
        } else {
          dispatch(profileActions.updateLoginStatus(true));
          dispatch(profileActions.replaceProfiles(userObject));
          dispatch(globalActions.resetTimeoutExceeded());
          dispatch(globalActions.setLoading({ loading: false }));
        }
      } else {
        dispatch(
          globalActions.setLoading({
            loading: true,
            label: "Checking Session...",
          })
        );

        const timeout = new Promise((resolve) => {
          setTimeout(() => {
            resolve({ timeout: true });
          }, 3000); // 3 seconds timeout
        });

        // Countdown timer
        let seconds = 3;
        dispatch(globalActions.setSeconds(seconds));
        const countdown = setInterval(() => {
          seconds -= 1;
          dispatch(globalActions.setSeconds(seconds));
          if (seconds <= 0) {
            clearInterval(countdown);
          }
        }, 1000);

        const fetchProfile = fetch(
          process.env.REACT_APP_SERVER_URL + "/api/profile/",
          {
            credentials: "include",
            method: "GET",
          }
        ).then((response) => response.json());

        // const details = await fetch(
        //   process.env.REACT_APP_SERVER_URL + "/api/profile/",
        //   {
        //     credentials: "include",
        //     method: "GET",
        //   }
        // );

        // const json = await details.json();

        const result = await Promise.race([fetchProfile, timeout]);

        if (result.timeout) {
          dispatch(
            globalActions.setTimeoutExceeded({
              loading: true,
              label: "Please login again",
              message: "Click to close",
            })
          );
        } else {
          console.log("response details", result);

          if (result.username) {
            console.log("user info received", result);
            // dispatch(globalActions.setLoading({ loading: false }));
            dispatch(profileActions.updateLoginStatus(true));
            if (result.role === "View") {
              result.view = true;
              dispatch(profileActions.replaceProfiles(result));
            } else {
              dispatch(profileActions.replaceProfiles(result));
            }
          } else {
            console.log("haven't got user details", result);
            // window.location.replace(process.env.REACT_APP_SERVER_URL + '/saml2/login/');
          }
          dispatch(globalActions.setLoading({ loading: false }));
          dispatch(globalActions.resetTimeoutExceeded());
        }
      }
    } catch (error) {
      console.log("error details", error);
      dispatch(globalActions.setLoading({ loading: false }));
      dispatch(globalActions.resetTimeoutExceeded());
    } finally {
      // Capture the end time
      const endTime = new Date();
      const formattedEndTime = endTime.toLocaleString("en-GB");
      console.log(`End Time: ${formattedEndTime}`);

      // Calculate and log the time taken
      const timeTaken = (endTime - startTime) / 1000; // time taken in seconds
      console.log(`Time taken: ${timeTaken} seconds`);
    }
  };
};
