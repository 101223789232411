// import { getRequest } from "../axios";
import { dashboardActions } from "./dashboardSlice";
import Toast from "../components/ui/Toast";
import { getRequest } from "../axios";

// import { customDates, timelineData } from "../components/dashboard/swimlaneData"; // Import the data

// export const setLoading = (state) => {
//   return async (dispatch) => {
//     dispatch(dashboardActions.setLoading(state));
//   };
// };

export const setCurrentDates = (state) => {
  return async (dispatch) => {
    dispatch(dashboardActions.setCurrentDates(state));
  };
};

export const setLoaded = (state) => {
  return async (dispatch) => {
    dispatch(dashboardActions.setLoaded(state));
  };
};

export const setFitToWrapper = (state) => {
  return async (dispatch) => {
    dispatch(dashboardActions.setFitToWrapper(state));
  };
};

export const setDrawSubmittedIcon = (state) => {
  return async (dispatch) => {
    dispatch(dashboardActions.setDrawSubmittedIcon(state));
  };
};

export const setDrawReviewIcons = (state) => {
  return async (dispatch) => {
    dispatch(dashboardActions.setDrawReviewIcons(state));
  };
};

export const setDrawAftercare = (state) => {
  return async (dispatch) => {
    dispatch(dashboardActions.setDrawAftercare(state));
  };
};

export const resetDashboard = () => {
  return async (dispatch) => {
    dispatch(dashboardActions.replaceDashboard([]));
  };
};

export const getDashboard = (filters) => {
  return async (dispatch) => {
    try {
      dispatch(dashboardActions.setLoading(true));

      let dashboardTimeLine = await getRequest(`dashboard-timeline`);
      let dashboardQuarterWiseData = await getRequest("dashboardQuarters/");

      const dashboard = {
        timeline: dashboardTimeLine?.formated_data,
        allTypes: [],
        customDates: [],
        quarterWiseData: dashboardQuarterWiseData?.table_data,
        currentQuarterProjectTotals:
          dashboardQuarterWiseData?.total_eopl_current_quarter,
        currentQuarterBrandWiseTotals:
          dashboardQuarterWiseData?.current_quarter_brand,
        nextQuarterBrandWiseTotals:
          dashboardQuarterWiseData?.next_quarter_brand,
      };

      setTimeout(() => {
        dispatch(dashboardActions.replaceDashboard(dashboard));
        dispatch(dashboardActions.setLoading(false));
      }, 50);
    } catch (error) {
      console.error("getDashboard: " + error.message);
      Toast({ status: "error", message: error.message });
    }
  };
};
