import { createSlice } from "@reduxjs/toolkit";
// import produce, { current } from "immer";
// import { sortComments } from './eoplCommentsActions';

const initialValues = {
  eoplComment: {},
  headerComments: [],
  detailComments: [],
  loaderAdd: false,
  loaderEdit: false,
  loaderDelete: false,
  replyloaderAdd: false,
  token: null,
  toggleRowId: 0,
  allDataComments: null,
};
const eoplCommentsSlice = createSlice({
  name: "eoplComments",
  initialState: initialValues,
  reducers: {
    setLoaderAdd: (state, action) => {
      state.loaderAdd = action.payload ?? false;
    },
    setLoaderEdit: (state, action) => {
      state.loaderEdit = action.payload ?? false;
    },
    setLoaderDelete: (state, action) => {
      state.loaderDelete = action.payload ?? false;
    },
    setReplyloaderAdd: (state, action) => {
      state.replyloaderAdd = action.payload ?? false;
    },
    resetToggleId: (state) => {
      state.toggleRowId = 0;
    },
    replaceEoplComment: (state, action) => {
      state.eoplComment = action.payload.eoplComment ?? {};
    },
    replaceEoplComments: (state, action) => {
      state.allDataComments = action.payload ?? [];
    },
    removeEoplComment: (state, action) => {
      const { id, isReply } = action.payload;
      state.changed = true;

      let updatedComments;

      if (isReply) {
        // Remove the nested reply with the matching replyCommentId
        updatedComments = state.allDataComments.map((comment) => ({
          ...comment,
          replies: comment.replies.filter((reply) => reply.id !== id),
        }));
      } else {
        // Remove the top-level comment with the matching id
        updatedComments = state.allDataComments.filter(
          (eoplComment) => eoplComment.id !== id
        );
      }

      state.allDataComments = updatedComments;
    },
    updateEoplComment: (state, action) => {
      const { comment, isReply } = action.payload;

      if (isReply) {
        // Update the top-level comment or add it if not found
        let updatedComments = [];

        if (state.allDataComments && state.allDataComments.length > 0) {
          updatedComments = state.allDataComments.map((eoplComment) => {
            if (eoplComment.id === comment.CommentId) {
              eoplComment.replies = [
                ...eoplComment.replies.filter((m) => m.id !== comment.id),
                comment,
              ];
              return eoplComment;
            }
            return eoplComment;
          });
        } else {
          // If no comments exist, initialize with the new comment
          updatedComments.push(comment);
        }

        state.loaderAdd = false;
        state.allDataComments = updatedComments;
      } else {
        // Update the top-level comment or add it if not found
        let commentFound = false;
        let updatedComments = [];

        // Check if state.allDataComments is defined and has comments
        if (state.allDataComments && state.allDataComments.length > 0) {
          updatedComments = state.allDataComments.map((eoplComment) => {
            if (eoplComment.id === comment.id) {
              commentFound = true;
              return comment;
            }
            return eoplComment;
          });
        }

        // If the comment was not found, add it to updatedComments
        if (!commentFound) {
          updatedComments.push(comment);
        }

        state.loaderAdd = false;
        state.allDataComments = updatedComments;
      }
    },
    setEoplComments: (state, action) => {
      state.allDataComments = action.payload;
    },
    toggleRowId: (state, action) => {
      state.toggleRowId = action.payload ?? 0;
    },
  },
});

export const eoplCommentsActions = eoplCommentsSlice.actions;

export default eoplCommentsSlice;
