import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Formik, Field, ErrorMessage } from "formik";
import { FieldArray } from "formik";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "../../../packages/modal/Modal";
import useToggle from "../../../packages/_utils/useToggle";
import Button from "../../../packages/button/Button";
import { childPartListActions } from "../../../store/childPartListSlice";
import {
  getChildPartList,
  createChildPartList,
  editChildPartList,
  deleteChildPartItem,
} from "../../../store/childPartListActions";
import Comments from "../../../components/comments/Comments";
import {
  PencilIcon,
  TrashIcon,
  SaveIcon,
  ChatAlt2Icon,
  PhotographIcon,
  InformationCircleIcon,
} from "@heroicons/react/solid";
import { constructChildPartListObject } from "../../../utils/utility";
import { Role } from "../../../data/roles";
import Tippy from "@tippyjs/react";
import ImageUploadModal from "../../../components/imageUploadModal";
import { setChanged } from "../../../store/eoplActions";
import {
  resetToggleId,
  setToggleRowId,
} from "../../../store/eoplCommentsActions";

const ChildPartList = (props) => {
  const { data } = props;

  const [childPartData, setChildPartData] = useState([]);
  const [, setIsOpen] = useState(false);
  const [toggle, isOpenModal] = useToggle();
  const [toggle1, isOpenPrompt] = useToggle();
  const [item, setItem] = useState();
  const [itemId, setItemId] = useState();
  const [commentData, setCommentData] = useState([]);
  const dispatch = useDispatch();
  const childPartList = useSelector((state) => state.childPart.childPartList);
  const eoplProgramData = useSelector((state) => state.eopl.data);
  const currentUser = useSelector((state) => state.profile.profilesData);
  const loading = useSelector((state) => state.global.loading);
  // const [indexData, setIndexData] = useState();
  const [imageData, setImageData] = useState(null);
  const [visibleImageUpload, setVisibleImageUpload] = useState(false);

  const allComments = useSelector(
    (state) => state.eoplComments.allDataComments
  );

  const programInfoImageCounts = useSelector(
    (state) => state.programInfo.data.imageCounts
  );

  useEffect(() => {
    // console.log("Mounting Child Parts");
    if (childPartList.length === 0 && eoplProgramData?.eoplProgramRefId) {
      // console.log("Fetching Child Parts Data");
      dispatch(getChildPartList(eoplProgramData?.eoplProgramRefId));
    }
    return () => {
      // console.log("Unmounting Child Parts");
      // dispatch(resetChildPartsData());
      dispatch(resetToggleId());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    // console.log('programImageCounts has changed:', programInfoImageCounts);
  }, [programInfoImageCounts, dispatch]);

  useEffect(() => {
    setChildPartData(childPartList);
  }, [childPartList]);

  const handleRemove = (values) => {
    // setchildPartData(values);
    toggle();
    setItemId(values);
  };

  const removeItem = () => {
    dispatch(deleteChildPartItem(itemId));
    toggle();
  };

  function edit(index) {
    setCommentData([]);

    const updatedChildPartList = childPartList?.map((currElement, indx) => {
      if (index === indx) {
        return {
          ...currElement,
          isEditable: !currElement.isEditable,
          isSelected: !currElement.isEditable ? true : !currElement.isSelected,
        };
      } else {
        return { ...currElement, isEditable: false, isSelected: false };
      }
    });

    dispatch(
      childPartListActions.replaceChildPartListData({
        childPartList: updatedChildPartList,
      })
    );

    const updatedItem = updatedChildPartList[index];

    if (updatedItem?.isSelected && !updatedItem?.isEditable) {
      dispatch(setToggleRowId(updatedItem?.id));
    } else {
      dispatch(setToggleRowId(0)); // we set to zero as null could refer to a shopping list comment
    }

    setIsOpen((isOpen) => !isOpen);
  }

  function comment(item, index) {
    const updatedChildPartList = childPartList?.map((currElement, indx) => {
      if (index === indx) {
        return {
          ...currElement,
          isSelected: currElement.isEditable ? true : !currElement.isSelected,
          isEditable: false,
        };
      } else {
        return { ...currElement, isSelected: false, isEditable: false };
      }
    });

    dispatch(
      childPartListActions.replaceChildPartListData({
        childPartList: updatedChildPartList,
      })
    );

    const updatedItem = updatedChildPartList[index];

    setCommentData(updatedItem);

    if (updatedItem?.isSelected && !updatedItem?.isEditable) {
      dispatch(setToggleRowId(updatedItem?.id));
    } else {
      dispatch(setToggleRowId(0)); // we set to zero as null could refer to a shopping list comment
    }
  }

  function save(item, index) {
    toggle1();
    setIsOpen((isOpen) => !isOpen);
    // setIsReadOnly(!isReadOnly);
    setItem(item);
    dispatch(setChanged(false));
  }

  const saveItem = () => {
    const customChildPartListObj = constructChildPartListObject(item);
    dispatch(editChildPartList(customChildPartListObj, item.id)).then(() =>
      dispatch(getChildPartList(item.eoplProgramId))
    );
    dispatch(setChanged(false));
    toggle1();
  };

  const handleSubmit = (values) => {
    let childPartData = values.childPartList.map((v) => ({
      ...v,
      eoplProgramId: data.eoplProgramRefId,
    }));
    dispatch(createChildPartList(childPartData));
  };

  const errorField = () => {
    return (
      <Tippy
        arrow={true}
        content={
          <span className="bg-secondary text-sm rounded px-2 py-1 shadow text-danger">
            Required Field
          </span>
        }
      >
        <span className="text-white absolute pointer-evdents-none top-1 right-1 font-bold bg-danger rounded-full w-5 h-5 text-center">
          !
        </span>
      </Tippy>
    );
  };

  const changed = useSelector((state) => state.eopl.changed);
  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    if (isDirty && !changed) {
      dispatch(setChanged(true));
    }
  }, [isDirty, dispatch, changed]);

  const toggleImgUploadModal = (partData = null) => {
    if (partData !== null) {
      setVisibleImageUpload(true);
      setImageData({ ...partData, type: "childParts" });
    } else {
      setVisibleImageUpload(false);
      setImageData(null);
    }
  };

  return (
    <div>
      <Formik
        initialValues={{
          childPartList: childPartData,
        }}
        enableReinitialize={true}
        onSubmit={handleSubmit}
      >
        {({ submitForm, values, dirty }) => {
          if (dirty && !changed) {
            setTimeout(() => setIsDirty(true), 0); // Use setTimeout to defer the state update
          }

          return (
            <>
              <div className="whitespace-nowrap overflow-y-scroll mt-2 border-box">
                <FieldArray name="childPartData">
                  {() => (
                    <div>
                      <div style={{ display: "block", maxHeight: "260px" }}>
                        <table className="w-full whitespace-nowrap rounfded-tl-lg roundefd-tr-lg">
                          <thead className="whitespace-normal text-white sticky top-0 z-10 bg-primary dark:bg-secondary-3-5 dark:text-typography-1 font-large">
                            <tr className="hidden md:table-row">
                              <th className="pl-4 py-1 text-left text-xs w-20">
                                <div className="flex items-center justify-between">
                                  <span className="pl-2.5 pr-0 text-xs font-medium tracking-wider  ">
                                    Actions
                                  </span>
                                  {/* <Tippy
                                    arrow={true}
                                    content={
                                      <div className="bg-red-300 outline-none ring-none border border-2 border-danger  rounded px-2 py-1 shadow text-primary text-center">
                                        <div className="text-sm font-bold">
                                          Delete Child Parts List
                                        </div>
                                        <div className="text-danger text-xs font-bold">
                                          Use with care!
                                        </div>
                                      </div>
                                    }
                                  >
                                    <Button
                                      type="button"
                                      onClick={() => setIsDeleteModalOpen(true)}
                                      className=" !pr-2.5 focus:!ring-offset-0"
                                    >
                                      <TrashIcon className="h-7 transition hover:animate-pulse duration-300 bg-white/1 hover:bg-white rounded-full cursor-pointer active:text-secondary focus:text-primary hover:!text-danger text-seconadary/70" />
                                    </Button>
                                  </Tippy> */}
                                </div>
                              </th>
                              <th className="py-2.5 pl-2 text-left text-xs tracking-wider">
                                Version
                              </th>
                              <th className="py-2.5 pl-2 text-left text-xs tracking-wider">
                                Current EPN
                              </th>
                              <th className="py-2.5 pl-2 text-left text-xs tracking-wider">
                                Current APN
                              </th>
                              <th className="py-2.5 pl-2 text-left text-xs tracking-wider">
                                New EPN
                              </th>
                              <th className="py-2.5 pl-2 text-left text-xs tracking-wider">
                                Part Description
                              </th>
                              <th className="py-2.5 pl-2 text-left text-xs tracking-wider">
                                iCreate
                              </th>
                              <th className="py-2.5 pl-2 text-left text-xs tracking-wider">
                                Vendor
                              </th>
                              <th className="py-2.5 pl-2 text-left text-xs tracking-wider w-40">
                                Change Description
                              </th>
                              <th className="py-2.5 pl-2 text-left text-xs tracking-wider w-40">
                                Vehicle Applicability
                              </th>
                              <th className="py-2.5 pl-2 text-left text-xs tracking-wider w-40">
                                Backwards Compatible
                              </th>
                              <th className="py-2.5 pl-2 text-center text-xs tracking-wider">
                                EPN1
                              </th>
                              <th className="py-2.5 pl-2 text-center text-xs tracking-wider">
                                PF1
                              </th>
                              <th className="py-2.5 pl-2 text-center text-xs tracking-wider">
                                APN1
                              </th>
                              <th className="py-2.5 pl-2 text-center text-xs tracking-wider">
                                EPN2
                              </th>
                              <th className="py-2.5 pl-2 text-center text-xs tracking-wider">
                                PF2
                              </th>
                              <th className="py-2.5 pl-2 text-center text-xs tracking-wider">
                                APN2
                              </th>
                              <th className="py-2.5 pl-2 text-center text-xs tracking-wider">
                                EPN3
                              </th>
                              <th className="py-2.5 pl-2 text-center text-xs tracking-wider">
                                PF3
                              </th>
                              <th className="py-2.5 pl-2 text-center text-xs tracking-wider">
                                APN3
                              </th>
                            </tr>
                          </thead>
                          <tbody className="flex-1 md:flex-none bg-secondary-4 text-secondary-1 text-xs">
                            {values.childPartList.length ? (
                              values.childPartList.map((user, index) => {
                                const totalCommentsCount = allComments
                                  ? allComments
                                      .filter(
                                        (m) => m.eoplChildPartsId === user.id
                                      )
                                      .reduce((acc, item) => {
                                        const repliesCount = item.replies
                                          ? item.replies.length
                                          : 0;
                                        return acc + 1 + repliesCount;
                                      }, 0)
                                  : 0;

                                const imageCount =
                                  programInfoImageCounts.find(
                                    (m) =>
                                      m.id === user.id &&
                                      m.imageType === "childPartsList"
                                  )?.imageCount || 0;

                                return (
                                  <tr
                                    key={index}
                                    className={`Xhover:bg-primary/40 odd:bg-light bg-dark text-typography-1 Xdark:hover:text-typography-2 group
                                    ${
                                      user.isSelected
                                        ? `md:h-8 h-12 odd:!bg-primary/60 !bg-primary/60 dark:bg-secondary-1 dark:text-white text-typography-1`
                                        : `md:h-8 h-12 dark:bg-secondary-2 dark:text-white text-typography-1`
                                    }
                                  `}
                                  >
                                    <td className="w-4l0 flex">
                                      {currentUser.role !== Role.view && (
                                        <div className="flex w-full items-center justify-around">
                                          {user.isSelected &&
                                          user.isEditable ? (
                                            <SaveIcon
                                              className="h-7 mr-2 cursor-pointer active:text-primary focus:text-primary hover:text-info text-primary/70"
                                              onClick={() => {
                                                return save(user, index);
                                              }}
                                            />
                                          ) : (
                                            <TrashIcon
                                              className="h-7 mr-2 cursor-pointer active:text-primary focus:text-primary hover:!text-danger text-primary/70"
                                              onClick={() => handleRemove(user)}
                                            />
                                          )}
                                          {
                                            <div className="relative">
                                              <ChatAlt2Icon
                                                className={`${
                                                  user?.isSelected &&
                                                  !user?.isEditable
                                                    ? "!text-secondary"
                                                    : "text-primary/70"
                                                } h-7 mr-2 cursor-pointer active:text-primary focus:text-primary hover:text-info `}
                                                onClick={() =>
                                                  comment(user, index)
                                                }
                                              />
                                              {totalCommentsCount > 0 && (
                                                <span className="absolute pointer-events-none text-white rounded-full w-4 h-4 text-center text-xs flex justify-center items-center p-1 bg-danger ml-5 -mt-7">
                                                  {totalCommentsCount}
                                                </span>
                                              )}
                                            </div>
                                          }
                                          {currentUser.role !== Role.view && (
                                            <div className="relative">
                                              <PencilIcon
                                                className={`${
                                                  user?.isEditable
                                                    ? "!text-secondary"
                                                    : "text-primary/70"
                                                } h-7 mr-2 cursor-pointer active:text-primary focus:text-primary hover:text-info `}
                                                onClick={() => {
                                                  return edit(index);
                                                }}
                                              />
                                            </div>
                                          )}
                                          {
                                            <div className="relative">
                                              <PhotographIcon
                                                className="h-7 mr-2 cursor-pointer active:text-primary focus:text-primary hover:text-info text-primary/70"
                                                onClick={() => {
                                                  toggleImgUploadModal(user);
                                                }}
                                              />
                                              {imageCount > 0 && (
                                                <span className="absolute pointer-events-none text-white rounded-full w-4 h-4 text-center text-xs flex justify-center items-center p-1 bg-danger ml-5 -mt-7">
                                                  {imageCount}
                                                </span>
                                              )}
                                            </div>
                                          }
                                        </div>
                                      )}
                                    </td>
                                    <td className="relative">
                                      <Field
                                        type="text"
                                        className="w-14 text-center p-1"
                                        name="version"
                                        placeholder="Version"
                                        disabled={true}
                                        value={user?.version ?? ""}
                                      />
                                    </td>
                                    <td className="relative">
                                      <Field
                                        type="text"
                                        className="w-24 text-center p-1"
                                        name={`childPartList.${index}.currentEpn`}
                                        placeholder="Current EPN"
                                        title={user?.currentEpn}
                                        style={{
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                        }}
                                        disabled={true}
                                        value={user?.currentEpn ?? ""}
                                      />
                                      <ErrorMessage
                                        name={`childPartList.${index}.currentEpn`}
                                        render={() => errorField()}
                                      />
                                    </td>
                                    <td className="relative">
                                      <Field
                                        type="text"
                                        className="w-24 text-center p-1"
                                        name={`childPartList.${index}.currentApn`}
                                        placeholder="Current APN"
                                        title={user?.currentApn}
                                        style={{
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                        }}
                                        value={user?.currentApn ?? ""}
                                        disabled={true}
                                      />
                                      <ErrorMessage
                                        name={`childPartList.${index}.currentApn`}
                                        render={() => errorField()}
                                      />
                                    </td>
                                    <td className="relative">
                                      <Field
                                        type="text"
                                        className="w-24 text-center p-1"
                                        name={`childPartList.${index}.newEpn`}
                                        placeholder="New EPN"
                                        title={user?.newEpn}
                                        style={{
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                        }}
                                        value={user?.newEpn ?? ""}
                                        disabled={true}
                                      />
                                      <ErrorMessage
                                        name={`childPartList.${index}.newEpn`}
                                        render={() => errorField()}
                                      />
                                    </td>
                                    <td className="relative">
                                      <Field
                                        type="text"
                                        className="w-40 text-left p-1"
                                        name={`childPartList.${index}.partDescription`}
                                        placeholder="Part Description"
                                        title={user?.partDescription}
                                        style={{
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                        }}
                                        disabled={
                                          user.isSelected && !user.isEditable
                                        }
                                        value={user?.partDescription ?? ""}
                                      />
                                      <ErrorMessage
                                        name={`childPartList.${index}.partDescription`}
                                        render={() => errorField()}
                                      />
                                    </td>
                                    <td className="relative">
                                      <Field
                                        type="text"
                                        className="w-24 text-center p-1"
                                        name={`childPartList.${index}.iCreate`}
                                        placeholder="iCreate"
                                        title={user?.iCreate}
                                        style={{
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                        }}
                                        disabled={
                                          user.isSelected && !user.isEditable
                                        }
                                        value={user?.iCreate ?? ""}
                                      />
                                      <ErrorMessage
                                        name={`childPartList.${index}.iCreate`}
                                        render={() => errorField()}
                                      />
                                    </td>
                                    <td className="relative">
                                      <Field
                                        type="text"
                                        className="w-24 text-center p-1"
                                        name={`childPartList.${index}.vendor`}
                                        placeholder="Vendor"
                                        title={user?.vendor}
                                        style={{
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                        }}
                                        disabled={
                                          user.isSelected && !user.isEditable
                                        }
                                        value={user?.vendor ?? ""}
                                      />
                                      <ErrorMessage
                                        name={`childPartList.${index}.vendor`}
                                        render={() => errorField()}
                                      />
                                    </td>
                                    <td className="relative">
                                      <Field
                                        type="text"
                                        className="w-40 text-center p-1"
                                        name={`childPartList.${index}.changeDescription`}
                                        placeholder="Change Description"
                                        title={user?.changeDescription}
                                        style={{
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                        }}
                                        disabled={
                                          user.isSelected && !user.isEditable
                                        }
                                        value={user?.changeDescription ?? ""}
                                      />
                                      <ErrorMessage
                                        name={`childPartList.${index}.changeDescription`}
                                        render={() => errorField()}
                                      />
                                    </td>
                                    <td className="relative">
                                      <Field
                                        type="text"
                                        className="w-40 text-center p-1"
                                        name={`childPartList.${index}.vehicleApplicability`}
                                        placeholder="Vehicle Applicability"
                                        disabled={
                                          user.isSelected && !user.isEditable
                                        }
                                        value={user?.vehicleApplicability ?? ""}
                                      />
                                      <ErrorMessage
                                        name={`childPartList.${index}.vehicleApplicability`}
                                        render={() => errorField()}
                                      />
                                    </td>
                                    <td className="relative">
                                      <Field
                                        type="text"
                                        className="w-24 text-center p-1"
                                        name={`childPartList.${index}.backwardsCompatible`}
                                        placeholder="Backwards Compatible"
                                        disabled={
                                          user.isSelected && !user.isEditable
                                        }
                                        value={user?.backwardsCompatible ?? ""}
                                      />
                                      <ErrorMessage
                                        name={`childPartList.${index}.backwardsCompatible`}
                                        render={() => errorField()}
                                      />
                                    </td>
                                    <td className="relative">
                                      <Field
                                        type="text"
                                        className="w-24 text-center p-1"
                                        name={`childPartList.${index}.epn1`}
                                        placeholder="EPN1"
                                        title={user?.epn1}
                                        style={{
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                        }}
                                        disabled={
                                          user.isSelected && !user.isEditable
                                        }
                                        value={user?.epn1 ?? ""}
                                      />
                                      <ErrorMessage
                                        name={`childPartList.${index}.epn1`}
                                        render={() => errorField()}
                                      />
                                    </td>
                                    <td className="relative">
                                      <Field
                                        type="text"
                                        className="w-24 text-center p-1"
                                        name={`childPartList.${index}.pf1`}
                                        placeholder="PF1"
                                        title={user?.pf1}
                                        style={{
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                        }}
                                        disabled={
                                          user.isSelected && !user.isEditable
                                        }
                                        value={user?.pf1 ?? ""}
                                      />
                                      <ErrorMessage
                                        name={`childPartList.${index}.pf1`}
                                        render={() => errorField()}
                                      />
                                    </td>
                                    <td className="relative">
                                      <Field
                                        type="text"
                                        className="w-24 text-center p-1"
                                        name={`childPartList.${index}.apn1`}
                                        placeholder="APN1"
                                        disabled={
                                          user.isSelected && !user.isEditable
                                        }
                                        value={user?.apn1 ?? ""}
                                      />
                                      <ErrorMessage
                                        name={`childPartList.${index}.apn1`}
                                        render={() => errorField()}
                                      />
                                    </td>
                                    <td className="relative">
                                      <Field
                                        type="text"
                                        className="w-24 text-center p-1"
                                        name={`childPartList.${index}.epn2`}
                                        placeholder="EPN2"
                                        title={user?.epn2}
                                        style={{
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                        }}
                                        disabled={
                                          user.isSelected && !user.isEditable
                                        }
                                        value={user?.epn2 ?? ""}
                                      />
                                      <ErrorMessage
                                        name={`childPartList.${index}.epn2`}
                                        render={() => errorField()}
                                      />
                                    </td>
                                    <td className="relative">
                                      <Field
                                        type="text"
                                        className="w-24 text-center p-1"
                                        name={`childPartList.${index}.pf2`}
                                        placeholder="PF2"
                                        disabled={
                                          user.isSelected && !user.isEditable
                                        }
                                        value={user?.pf2 ?? ""}
                                      />
                                      <ErrorMessage
                                        name={`childPartList.${index}.pf2`}
                                        render={() => errorField()}
                                      />
                                    </td>
                                    <td className="relative">
                                      <Field
                                        type="text"
                                        className="w-24 text-center p-1"
                                        name={`childPartList.${index}.apn2`}
                                        placeholder="APN2"
                                        disabled={
                                          user.isSelected && !user.isEditable
                                        }
                                        value={user?.apn2 ?? ""}
                                      />
                                      <ErrorMessage
                                        name={`childPartList.${index}.apn2`}
                                        render={() => errorField()}
                                      />
                                    </td>
                                    <td className="relative">
                                      <Field
                                        type="text"
                                        className="w-24 text-center p-1"
                                        name={`childPartList.${index}.epn3`}
                                        placeholder="EPN3"
                                        disabled={
                                          user.isSelected && !user.isEditable
                                        }
                                        value={user?.epn3 ?? ""}
                                      />
                                      <ErrorMessage
                                        name={`childPartList.${index}.epn3`}
                                        render={() => errorField()}
                                      />
                                    </td>
                                    <td className="relative">
                                      <Field
                                        type="text"
                                        className="w-24 text-center p-1"
                                        name={`childPartList.${index}.pf3`}
                                        placeholder="PF3"
                                        disabled={
                                          user.isSelected && !user.isEditable
                                        }
                                        value={user?.pf3 ?? ""}
                                      />
                                      <ErrorMessage
                                        name={`childPartList.${index}.pf3`}
                                        render={() => errorField()}
                                      />
                                    </td>
                                    <td className="relative">
                                      <Field
                                        type="text"
                                        className="w-24 text-center p-1"
                                        name={`childPartList.${index}.apn3`}
                                        placeholder="APN3"
                                        disabled={
                                          user.isSelected && !user.isEditable
                                        }
                                        value={user?.apn3 ?? ""}
                                      />
                                      <ErrorMessage
                                        name={`childPartList.${index}.apn3`}
                                        render={() => errorField()}
                                      />
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td
                                  className="p-4 font-bold text-lg h-20"
                                  colSpan={10}
                                >
                                  {loading ? (
                                    "Loading Child Parts List. Please wait..."
                                  ) : (
                                    <span className="text-info flex items-center">
                                      <InformationCircleIcon className="h-7 mr-2" />
                                      No child parts list found for this
                                      programme..!
                                    </span>
                                  )}
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                </FieldArray>
              </div>
              {childPartData &&
                childPartData.length > 0 &&
                currentUser.role !== Role.view && (
                  <Comments
                    eoplProgramId={eoplProgramData.id}
                    commentType="childPartList"
                    comment={commentData}
                  />
                )}
            </>
          );
        }}
      </Formik>
      {data && isOpenModal && (
        <Modal
          isOpen={isOpenModal}
          toggle={toggle}
          animate={true}
          closeOnClickOutside={true}
        >
          <ModalHeader>Delete Item</ModalHeader>
          <ModalBody>
            <div className="d-flex flex-row bd-highlight mb-3">
              <div className="p-2 bd-highlight">
                Remove Item From ChildPart List
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button type="button" onClick={toggle} color="danger">
              Cancel
            </Button>
            <Button
              type="button"
              onClick={removeItem}
              color="primary"
              className="mr-1"
            >
              Remove
            </Button>
          </ModalFooter>
        </Modal>
      )}
      {data && isOpenPrompt && (
        <Modal
          isOpen={isOpenPrompt}
          toggle={toggle1}
          animate={true}
          closeOnClickOutside={true}
        >
          <ModalHeader>Version Change Prompt</ModalHeader>
          <ModalBody>
            <div className="d-flex flex-row bd-highlight mb-3">
              <div className="p-2 bd-highlight">
                Do you want a version change for this Child Parts Item?
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button type="button" onClick={toggle1} color="danger">
              No
            </Button>
            <Button
              type="button"
              onClick={saveItem}
              color="success"
              className="mr-1"
            >
              Yes
            </Button>
          </ModalFooter>
        </Modal>
      )}
      {visibleImageUpload === true && imageData !== null && (
        <ImageUploadModal
          data={imageData}
          closeModal={() => toggleImgUploadModal()}
          eoplProgramId={eoplProgramData?.eoplProgramRefId}
        />
      )}
    </div>
  );
};

export default ChildPartList;
