import { createSlice } from "@reduxjs/toolkit";

export const dashboardObject = {
  timeline: [],
  // rawData: [],
  allTypes: [],
  currentDates: {
    currentStartDate: null,
    currentEndDate: null,
  },
  customDates: [],
  quarterWiseData: [],
  currentQuarterProjectTotals: null,
  currentQuarterBrandWiseTotals: null,
  nextQuarterBrandWiseTotals: null,
  loading: true,
  loaded: false,
  fitToWrapper: true,
  drawSubmittedIcon: true,
  drawReviewIcons: false,
  drawAftercare: true,
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: dashboardObject,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setLoaded(state, action) {
      state.loaded = action.payload;
    },
    setCurrentDates(state, action) {
      const newCurrentDates = action.payload;
      if (newCurrentDates.currentStartDate)
        state.currentDates.currentStartDate = newCurrentDates.currentStartDate;
      if (newCurrentDates.currentEndDate)
        state.currentDates.currentEndDate = newCurrentDates.currentEndDate;
    },
    setFitToWrapper(state, action) {
      state.fitToWrapper = action.payload;
    },
    setDrawSubmittedIcon(state, action) {
      state.drawSubmittedIcon = action.payload;
    },
    setDrawReviewIcons(state, action) {
      state.drawReviewIcons = action.payload;
    },
    setDrawAftercare(state, action) {
      state.drawAftercare = action.payload;
    },
    replaceDashboard(state, action) {
      state.timeline = action.payload.timeline;
      state.loaded = false;
      state.allTypes = action.payload.allTypes;
      state.customDates = action.payload.customDates;
      state.quarterWiseData = action.payload.quarterWiseData;
      state.currentQuarterProjectTotals =
        action.payload.currentQuarterProjectTotals;
      state.currentQuarterBrandWiseTotals =
        action.payload.currentQuarterBrandWiseTotals;
      state.nextQuarterBrandWiseTotals =
        action.payload.nextQuarterBrandWiseTotals;
    },
  },
});

export const dashboardActions = dashboardSlice.actions;

export default dashboardSlice;
