import { useState } from "react";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import { childPartListObject } from "../../../store/childPartListSlice";
import ChildPartList from "./ChildPartList";
import {
  getChildPartList,
  createChildPartList,
  uploadChildPartList,
  deleteChildPartsList,
} from "../../../store/childPartListActions";
import Button from "../../../packages/button/Button";
import { useSelector, useDispatch } from "react-redux";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  DownloadIcon,
  PlusIcon,
  TrashIcon,
  UploadIcon,
} from "@heroicons/react/solid";
import { download } from "../../../store/eoplActions";
import { createRef } from "react";
import useToggle from "../../../packages/_utils/useToggle";
import {
  ModalBody,
  ModalFooter,
  ModalHeader,
  Modal,
} from "../../../packages/modal/Modal";
import * as Yup from "yup";
import { Role } from "../../../data/roles";
import Tippy from "@tippyjs/react";
import SearchInput from "../../../components/ui/SearchInput";

const ChildPartListAdd = (props) => {
  const { data, prev, next } = props;
  const [formData] = useState(childPartListObject);
  const dispatch = useDispatch();

  // let dataOject = null;
  const fileInput = createRef();
  const [file, setFile] = useState(null);
  const [toggle, isOpenModal] = useToggle();

  const handlePrev = (values) => {
    prev(values);
  };

  const handleNext = (values) => {
    next(values);
  };

  const childPart = useSelector((state) => state.childPart.childPartList);
  // const uploading = useSelector((state) => state.childPart.uploading);
  const currentUser = useSelector((state) => state.profile.profilesData);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteValue, setDeleteValue] = useState("");
  const [deleteError, setDeleteError] = useState("");

  const excelDownloadHandler = () =>
    dispatch(download("childPartsExport", data.eoplProgramRefId));

  const triggerInputFile = (event) => {
    if (
      fileInput.current !== undefined &&
      fileInput.current.click !== undefined
    ) {
      dispatch(uploadChildPartList(file, data.eoplProgramRefId)).then(
        () => dispatch(getChildPartList(data.eoplProgramRefId)),
        setFile(null),
        toggle()
      );
    }
  };
  const handleChange = (event) => {
    setFile(event.target.files[0]);
  };
  const handleSubmit = (values) => {
    // dataOject = values.childPartList.map((v) => ({
    const childPartsList = values.childPartList.map((v) => ({
      ...v,
      eoplProgramId: data.eoplProgramRefId,
    }));
    dispatch(createChildPartList(childPartsList)).then(() =>
      dispatch(getChildPartList(data.eoplProgramRefId))
    );
  };

  const validationSchema = Yup.object().shape({
    childPartList: Yup.array().of(
      Yup.object().shape({
        currentEpn: Yup.string()
          .nullable()
          .required("Required")
          .typeError("Please add a valid data"),
        currentApn: Yup.string()
          .nullable()
          .required("Required")
          .typeError("Please add a valid data"),
        newEpn: Yup.string()
          .nullable()
          .required("Required")
          .typeError("Please add a valid data"),
        partDescription: Yup.string()
          .nullable()
          .required("Required")
          .typeError("Please add a valid data"),
        vendor: Yup.string()
          .nullable()
          .required("Required")
          .typeError("Please add a valid data"),
        changeDescription: Yup.string()
          .nullable()
          .required("Required")
          .typeError("Please add a valid data"),
        vehicleApplicability: Yup.string()
          .nullable()
          .required("Required")
          .typeError("Please add a valid data"),
        backwardsCompatible: Yup.string()
          .nullable()
          .required("Required")
          .typeError("Please add a valid data"),
        epn1: Yup.string()
          .nullable()
          .required("Required")
          .typeError("Please add a valid data"),
        pf1: Yup.string()
          .nullable()
          .required("Required")
          .typeError("Please add a valid data"),
        apn1: Yup.string()
          .nullable()
          .required("Required")
          .typeError("Please add a valid data"),
      })
    ),
  });

  const handleUpload = (values) => {
    toggle();
  };

  const errorField = () => {
    return (
      <Tippy
        arrow={true}
        content={
          <span className="bg-secondary text-sm rounded px-2 py-1 shadow text-danger">
            Required Field
          </span>
        }
      >
        <span className="text-white absolute pointer-evdents-none top-1 right-1 font-bold bg-danger rounded-full w-5 h-5 text-center">
          !
        </span>
      </Tippy>
    );
  };

  const deleteChildParts = async () => {
    await dispatch(deleteChildPartsList(data.eoplProgramRefId)).then(() => {
      setIsDeleteModalOpen(false);
      setDeleteValue("");
    });
  };

  return (
    <div>
      <Formik
        initialValues={{
          childPartList: [formData],
        }}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={handleSubmit}
      >
        {({ values, submitForm, setFieldTouched, errors }) => {
          return (
            <>
              {currentUser.role !== Role.view && (
                <Form>
                  <div
                    className="!col-spfan-full whitespace-nowrap overflow-y-scroll mt-2 border-box"
                    style={{ maxHeight: "236px" }}
                  >
                    <FieldArray name="childPartList">
                      {({ push, remove }) => (
                        <div>
                          <div className="!col-spfan-full whitespace-nowrap mt-2 border-box">
                            <table className="w-full whitespace-nowrap rounfded-tl-lg roundefd-tr-lg">
                              <thead
                                className="text-white bg-primary dark:bg-secondary-3-5 dark:text-typography-1 font-large"
                                style={{ position: "sticky", top: 0 }}
                              >
                                <tr className="hidden md:table-row">
                                  <th className="text-center px-2 w-0">
                                    <Button
                                      color="secondary"
                                      className="text-sm !text-primary hover:text-light"
                                      type="button"
                                      onClick={() => push()}
                                    >
                                      <PlusIcon className="h-4 w-4" />
                                    </Button>
                                  </th>
                                  <th className="py-2.5 pl-2 text-left text-xs tracking-wider">
                                    Current EPN
                                  </th>
                                  <th className="py-2.5 pl-2 text-left text-xs tracking-wider">
                                    Current APN
                                  </th>
                                  <th className="py-2.5 pl-2 text-left text-xs tracking-wider">
                                    New EPN
                                  </th>
                                  <th className="py-2.5 pl-2 text-left text-xs tracking-wider">
                                    Part Description
                                  </th>
                                  <th className="py-2.5 pl-2 text-left text-xs tracking-wider">
                                    iCreate
                                  </th>
                                  <th className="py-2.5 pl-2 text-left text-xs tracking-wider">
                                    Vendor
                                  </th>
                                  <th className="py-2.5 pl-2 text-left text-xs tracking-wider">
                                    Change Description
                                  </th>
                                  <th className="py-2.5 pl-2 text-left text-xs tracking-wider">
                                    Vehicle Applicability
                                  </th>
                                  <th className="py-2.5 pl-2 text-left text-xs tracking-wider">
                                    Backwards Compatible
                                  </th>
                                  <th className="py-2.5 pl-2 text-center text-xs tracking-wider">
                                    EPN1
                                  </th>
                                  <th className="py-2.5 pl-2 text-center text-xs tracking-wider">
                                    PF1
                                  </th>
                                  <th className="py-2.5 pl-2 text-center text-xs tracking-wider">
                                    APN1
                                  </th>
                                  <th className="py-2.5 pl-2 text-center text-xs tracking-wider">
                                    EPN2
                                  </th>
                                  <th className="py-2.5 pl-2 text-center text-xs tracking-widerr">
                                    PF2
                                  </th>
                                  <th className="py-2.5 pl-2 text-center text-xs tracking-wider">
                                    APN2
                                  </th>
                                  <th className="py-2.5 pl-2 text-center text-xs tracking-wider">
                                    EPN3
                                  </th>
                                  <th className="py-2.5 pl-2 text-center text-xs tracking-wider">
                                    PF3
                                  </th>
                                  <th className="py-2.5 pl-2 text-center text-xs tracking-wider">
                                    APN3
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="flex-1 md:flex-none bg-secondary-4 text-secondary-1 text-sm">
                                {values.childPartList.map((user, index) => (
                                  <tr
                                    key={index}
                                    className={`md:h-8 h-12 odd:bg-primary/30 dark:bg-secondary-2 dark:text-white text-typography-1`}
                                  >
                                    <td className="relative text-center">
                                      <Button
                                        className="text-sm bg-primary/70 hover:bg-primary text-secondary hover:!text-danger"
                                        type="button"
                                        onClick={() => remove(index)}
                                      >
                                        <TrashIcon className="h-4 w-4" />
                                      </Button>
                                    </td>
                                    <td className="relative">
                                      <Field
                                        type="text"
                                        className="w-24 text-center p-1"
                                        name={`childPartList.${index}.currentEpn`}
                                        placeholder="Current EPN"
                                      />
                                      <ErrorMessage
                                        name={`childPartList.${index}.currentEpn`}
                                        render={() => errorField()}
                                      />
                                    </td>
                                    <td className="relative">
                                      <Field
                                        type="text"
                                        className="w-24 text-center p-1"
                                        name={`childPartList.${index}.currentApn`}
                                        placeholder="Current APN"
                                      />
                                      <ErrorMessage
                                        name={`childPartList.${index}.currentApn`}
                                        render={() => errorField()}
                                      />
                                    </td>
                                    <td className="relative">
                                      <Field
                                        type="text"
                                        className="w-24 text-center p-1"
                                        name={`childPartList.${index}.newEpn`}
                                        placeholder="New EPN"
                                      />
                                      <ErrorMessage
                                        name={`childPartList.${index}.newEpn`}
                                        render={() => errorField()}
                                      />
                                    </td>
                                    <td className="relative">
                                      <Field
                                        type="text"
                                        className="w-full text-center p-1"
                                        name={`childPartList.${index}.partDescription`}
                                        placeholder="Part Description"
                                      />
                                      <ErrorMessage
                                        name={`childPartList.${index}.partDescription`}
                                        render={() => errorField()}
                                      />
                                    </td>
                                    <td className="relative">
                                      <Field
                                        type="text"
                                        className="w-24 text-center p-1"
                                        name={`childPartList.${index}.iCreate`}
                                        placeholder="iCreate"
                                      />
                                      <ErrorMessage
                                        name={`childPartList.${index}.iCreate`}
                                        render={() => errorField()}
                                      />
                                    </td>
                                    <td className="relative">
                                      <Field
                                        type="text"
                                        className="w-24 text-center p-1"
                                        name={`childPartList.${index}.vendor`}
                                        placeholder="Vendor"
                                      />
                                      <ErrorMessage
                                        name={`childPartList.${index}.vendor`}
                                        render={() => errorField()}
                                      />
                                    </td>
                                    <td className="relative">
                                      <Field
                                        type="text"
                                        className="w-ful text-center p-1"
                                        name={`childPartList.${index}.changeDescription`}
                                        placeholder="Change Description"
                                      />
                                      <ErrorMessage
                                        name={`childPartList.${index}.changeDescription`}
                                        render={() => errorField()}
                                      />
                                    </td>
                                    <td className="relative">
                                      <Field
                                        type="text"
                                        className="w-full text-center p-1"
                                        name={`childPartList.${index}.vehicleApplicability`}
                                        placeholder="Vehicle Applicability"
                                      />
                                      <ErrorMessage
                                        name={`childPartList.${index}.vehicleApplicability`}
                                        render={() => errorField()}
                                      />
                                    </td>
                                    <td className="relative">
                                      <Field
                                        type="text"
                                        className="w-full text-center p-1"
                                        name={`childPartList.${index}.backwardsCompatible`}
                                        placeholder="Backwards Compatible"
                                      />
                                      <ErrorMessage
                                        name={`childPartList.${index}.backwardsCompatible`}
                                        render={() => errorField()}
                                      />
                                    </td>
                                    <td className="relative">
                                      <Field
                                        type="text"
                                        className="w-24 text-center p-1"
                                        name={`childPartList.${index}.epn1`}
                                        placeholder="EPN1"
                                      />
                                      <ErrorMessage
                                        name={`childPartList.${index}.epn1`}
                                        render={() => errorField()}
                                      />
                                    </td>
                                    <td className="relative">
                                      <Field
                                        type="text"
                                        className="w-24 text-center p-1"
                                        name={`childPartList.${index}.pf1`}
                                        placeholder="PF1"
                                      />
                                      <ErrorMessage
                                        name={`childPartList.${index}.pf1`}
                                        render={() => errorField()}
                                      />
                                    </td>
                                    <td className="relative">
                                      <Field
                                        type="text"
                                        className="w-24 text-center p-1"
                                        name={`childPartList.${index}.apn1`}
                                        placeholder="APN1"
                                      />
                                      <ErrorMessage
                                        name={`childPartList.${index}.apn1`}
                                        render={() => errorField()}
                                      />
                                    </td>
                                    <td className="relative">
                                      <Field
                                        type="text"
                                        className="w-24 text-center p-1"
                                        name={`childPartList.${index}.epn2`}
                                        placeholder="EPN2"
                                      />
                                      <ErrorMessage
                                        name={`childPartList.${index}.epn2`}
                                        render={() => errorField()}
                                      />
                                    </td>
                                    <td className="relative">
                                      <Field
                                        type="text"
                                        className="w-24 text-center p-1"
                                        name={`childPartList.${index}.pf2`}
                                        placeholder="PF2"
                                      />
                                      <ErrorMessage
                                        name={`childPartList.${index}.pf2`}
                                        render={() => errorField()}
                                      />
                                    </td>
                                    <td className="relative">
                                      <Field
                                        type="text"
                                        className="w-24 text-center p-1"
                                        name={`childPartList.${index}.apn2`}
                                        placeholder="APN2"
                                      />
                                      <ErrorMessage
                                        name={`childPartList.${index}.apn2`}
                                        render={() => errorField()}
                                      />
                                    </td>
                                    <td className="relative">
                                      <Field
                                        type="text"
                                        className="w-24 text-center p-1"
                                        name={`childPartList.${index}.epn3`}
                                        placeholder="EPN3"
                                      />
                                      <ErrorMessage
                                        name={`childPartList.${index}.epn3`}
                                        render={() => errorField()}
                                      />
                                    </td>
                                    <td className="relative">
                                      <Field
                                        type="text"
                                        className="w-24 text-center p-1"
                                        name={`childPartList.${index}.pf3`}
                                        placeholder="PF3"
                                      />
                                      <ErrorMessage
                                        name={`childPartList.${index}.pf3`}
                                        render={() => errorField()}
                                      />
                                    </td>
                                    <td className="relative">
                                      <Field
                                        type="text"
                                        className="w-24 text-center p-1"
                                        name={`childPartList.${index}.apn3`}
                                        placeholder="APN3"
                                      />
                                      <ErrorMessage
                                        name={`childPartList.${index}.apn3`}
                                        render={() => errorField()}
                                      />
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      )}
                    </FieldArray>
                  </div>
                </Form>
              )}
              {currentUser.role !== Role.view && (
                <div className="grid-cols-3 gap-4px-4 py-3 bg-white text-right md:px-6 rounded-b">
                  <Button
                    type="submit"
                    disabled={values.childPartList.length === 0}
                    onClick={() => {
                      if (Object.keys(errors).length === 0) {
                        return submitForm();
                      } else {
                        Object.getOwnPropertyNames(errors).forEach((err) => {
                          setFieldTouched(true);
                        });
                      }
                    }}
                    className={`place-self-center col-start-2`}
                  >
                    Submit
                  </Button>
                </div>
              )}

              <ChildPartList data={data.eoplProgramRefId} />

              <div className="grid grid-cols-2 gap-x-4 py-3 bg-white text-left sm:px-6 rounded-b">
                <div className="flex justify-start gap-x-2">
                  <Button
                    type="button"
                    onClick={() => {
                      return handlePrev(values);
                    }}
                    className={`bg-primary flex items-center `}
                  >
                    <ArrowLeftIcon className="h-4 mr-2" />
                    Programme Details
                  </Button>

                  <Button
                    type="button"
                    onClick={() => {
                      return handleNext(values);
                    }}
                    className={`bg-primary flex items-center `}
                  >
                    <ArrowRightIcon className="h-4 mr-2" />
                    Shopping List
                  </Button>
                </div>
                {currentUser.role !== Role.view && (
                  <div className="flex justify-end gap-x-2">
                    <Button
                      type="button"
                      onClick={() => setIsDeleteModalOpen(true)}
                      // className="bg-danger flex items-center !px-2 mr-4 opacity-80 hover:opacity-100 whitespace-nowrap"
                      className={`bg-primary flex items-center whitespace-nowrap`}
                      color="danger"
                      disabled={
                        !data?.eoplProgramRefId || childPart.length === 0
                      }
                    >
                      Delete Child Parts List &nbsp;
                      <TrashIcon className="h-4" />
                    </Button>
                    <Button
                      className={`bg-primary flex items-center whitespace-nowrap`}
                      tippy="Download"
                      color="primary"
                      disabled={
                        !data?.eoplProgramRefId || childPart.length === 0
                      }
                      onClick={() => {
                        excelDownloadHandler();
                      }}
                    >
                      Download Child Parts List &nbsp;
                      <DownloadIcon className="h-4" />
                    </Button>
                    <Button
                      className={`bg-primary flex items-center whitespace-nowrap`}
                      tippy="Upload"
                      color="primary"
                      onClick={() => {
                        handleUpload();
                      }}
                    >
                      Upload Child Parts List &nbsp;
                      <UploadIcon className="h-4" />
                    </Button>
                  </div>
                )}
              </div>
            </>
          );
        }}
      </Formik>
      {isOpenModal && (
        <Modal
          isOpen={isOpenModal}
          toggle={toggle}
          animate={true}
          closeOnClickOutside={true}
        >
          <ModalHeader>Upload Child Parts List</ModalHeader>
          <ModalBody>
            <div className="d-flex flex-row bd-highlight mb-2">
              <div className="p-3 bd-highlight">
                <input
                  ref={fileInput}
                  type="file"
                  accept=".xlsx,.csv"
                  onChange={handleChange}
                  // style={styles.input}
                />
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button type="button" onClick={() => toggle()}>
              Cancel
            </Button>
            <Button
              type="button"
              // onClick={() => {
              //   return submitForm();
              // }}
              disabled={!file}
              onClick={() => triggerInputFile()}
              color="success"
              className="place-self-center col-start-2"
            >
              Upload
            </Button>
          </ModalFooter>
        </Modal>
      )}
      {data && isDeleteModalOpen && (
        <Modal
          isOpen={isDeleteModalOpen}
          toggle={toggle}
          animate={true}
          closeOnClickOutside={true}
        >
          <ModalHeader>Delete Child Parts List</ModalHeader>
          <ModalBody>
            <div className="d-flex flex-row bd-highlight mb-3">
              <div className="p-2 bd-highlight">
                This will delete the child parts list for program{" "}
                <span className="font-bold">{data.eoplProgramRefId}</span>.
                <br />
                This can't be undone.
                <span className="text-danger"> Use with care.</span>
                <div className="flex mt-4 items-center justify-center">
                  Type DELETE in the box to confirm
                  <SearchInput
                    className="text-danger font-bold text-center w-30"
                    placeholder="TYPE DELETE..."
                    onChange={(value) => {
                      setDeleteError("");
                      setDeleteValue(value);
                    }}
                    value={deleteValue}
                  />
                </div>
                <div className="text-danger pt-2 text-center">
                  {deleteError}
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button type="button" onClick={() => setIsDeleteModalOpen(false)}>
              Cancel
            </Button>
            <Button
              type="button"
              onClick={() => {
                if (deleteValue === "DELETE") {
                  deleteChildParts();
                } else {
                  setDeleteError(
                    "Invalid Input. Please type DELETE to confirm."
                  );
                  setDeleteValue("");
                }
              }}
              color="danger"
              className="mr-1"
            >
              Delete
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </div>
  );
};

export default ChildPartListAdd;
