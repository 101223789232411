import { useState } from "react";
import { Drawer, DrawerHeader, DrawerBody } from "../../packages/drawer/Drawer";
import EOPLForm from "../eopl/form/Form";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "../../packages/modal/Modal";
import Button from "../../packages/button/Button";

const EoplDrawer = (props) => {
  const { isOpen, toggle, changed, eoplProgramId } = props;

  const [reviewModalOpen, setReviewModalOpen] = useState(false);

  const handleClose = (close = true) => {
    if (changed && !reviewModalOpen) {
      setReviewModalOpen(true);
    } else {
      setReviewModalOpen(false);
      if (close) {
        toggle();
      }
    }
  };

  return (
    <>
      <Drawer
        isOpen={isOpen}
        toggle={toggle}
        position="right"
        changed={changed}
        triggerIsOpen={() => handleClose()}
      >
        <DrawerHeader closeDrawer={() => handleClose()}>
          Programme Details
        </DrawerHeader>
        <DrawerBody>
          <div className={`w-full`}>
            <div className="grid grid-cols-1 gap-x-2">
              <div className={`w-full`}>
                <div className="grid grid-cols-1 gap-x-2">
                  {isOpen && eoplProgramId && (
                    <EOPLForm
                      eoplProgramId={eoplProgramId}
                      flag={"programList"}
                      closeDrawer={toggle}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </DrawerBody>
      </Drawer>

      {reviewModalOpen && (
        <Modal isOpen={reviewModalOpen} toggle={toggle} animate={true}>
          <ModalHeader>Close Programme Details</ModalHeader>
          <ModalBody>
            <div className="d-flex flex-col bd-highlight mb-3">
              <div className="p-2 bd-highlight">You have unsaved changes.</div>
              <div className="p-2 bd-highlight">
                Click 'Close' to disregard changes.
              </div>
              <div className="p-2 bd-highlight">
                Click 'Reivew' to return to the form.
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              onClick={() => handleClose()}
              color="danger"
              className="mr-1"
            >
              Close
            </Button>
            <Button
              type="button"
              onClick={() => handleClose(false)}
              color="primary"
            >
              Review
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};

export default EoplDrawer;
