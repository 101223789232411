import New from "./New";
import File from "./File";
import Input from "./Input";
import { FaFolderOpen } from "react-icons/fa6";
import { FaFolder } from "react-icons/fa";
import Empty from "./Empty";
import { useSelector } from "react-redux";

const Folder = (props) => {
  const {
    data,
    open,
    toggleFolderState,
    addNew,
    setAddNew,
    level = 0, // Add a level prop to track the nesting level
    setDeleteItem,
    windowId,
    windowName,
  } = props;
  const { folderId, folderName, children, files } = data;

  const loader = useSelector((state) => state.explorer.loader);

  const renderChildren = () => {
    return (
      <div key={folderId}>
        {children.map((child) => {
          return (
            <Folder
              key={child.folderId}
              data={child}
              open={open}
              toggleFolderState={toggleFolderState}
              addNew={addNew}
              setAddNew={setAddNew}
              level={level + 1} // Increase the level for nested folders
              setDeleteItem={setDeleteItem}
              windowId={windowId}
              windowName={windowName}
            />
          );
        })}
      </div>
    );
  };

  const renderFiles = () => {
    return files.map((file) => (
      <div
        key={file.fileId}
        className="flex items-center w-full group hover:bg-tertiary dark:hover:bg-tertiary/50"
      >
        {generateIndentation(level + 1)}
        <File data={file} level={level + 1} />
      </div>
    ));
  };

  // Generate the appropriate number of span elements for indentation
  const generateIndentation = (level) => {
    const spans = [];
    for (let i = 0; i < level; i++) {
      spans.push(
        <div key={i} className={`pl-2 bg-primary/40 self-stretch`}></div>
      );
    }
    return spans;
  };

  return (
    <div className="w-full">
      {/* folder info */}

      <div
        className={`${
          loader.loading && loader.id === folderName
            ? "bg-danger"
            : "bg-primary/40 group hover:!bg-primary/50"
        } transition-colors duration-300 ease-in-out flex items-center justify-between  cursor-pointer border-b border-primary/20`}
      >
        {generateIndentation(level)}
        <div
          className="flex flex-1"
          onClick={() => {
            if (setAddNew) {
              setAddNew({});
            }
            toggleFolderState(folderId);
          }}
        >
          <div className="flex flex-1 w-full items-center space-x-2 p-2 ">
            <div className="w-12 h-12 flex items-center justify-center text-primary ">
              {open?.includes(folderId) ? (
                <FaFolderOpen className="h-6 w-6" />
              ) : (
                <FaFolder className="h-6 w-6" />
              )}
            </div>
            <div className="flex-1 overflow-hidden flex-wrap">
              <p className="text-typography-1 flex-1 overflow-hidden text-ellipsis">
                {folderName}
              </p>
            </div>
            {setAddNew && (
              <New
                {...{
                  addNew,
                  setAddNew,
                  root: false,
                  setDeleteItem,
                  data,
                  windowId,
                  windowName,
                }}
              />
            )}
          </div>
        </div>
      </div>

      {/* folder contents */}
      {addNew && addNew.parentFolderId === folderId && (
        <div className="flex bg-primary/80 text-typography-2">
          {generateIndentation(level + 1)}
          <Input
            {...{
              addNew,
              setAddNew,
              toggleFolderState,
              open,
            }}
          />
        </div>
      )}

      {open?.includes(folderId) && (
        <>
          {data.children.length === 0 &&
          data.files.length === 0 &&
          !addNew?.parentFolderId ? (
            <Empty message="This folder is empty" />
          ) : (
            <>
              {renderFiles()}
              {renderChildren()}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Folder;
