import React, { useRef, useState } from "react";
import { FormField } from "../../packages/form/FormField";
import { FaFolder } from "react-icons/fa";
import { FaFile, FaLink } from "react-icons/fa6";
import { XIcon } from "@heroicons/react/solid";
import Tippy from "@tippyjs/react";
import { useDispatch } from "react-redux";
import {
  createExplorerFile,
  createExplorerFolder,
  uploadFile,
} from "../../store/explorerActions";
import Button from "../../packages/button/Button";
import { getFileExtensionAndIcon, toProperCase } from "../../utils/utility";
import Toast from "../ui/Toast";

const Input = (props) => {
  const { addNew, setAddNew, toggleFolderState, open } = props;
  const dispatch = useDispatch();

  const fileInput = useRef(null);
  const myRefName = useRef(null);
  const myRefUrl = useRef(null);

  const [hasError, setHasError] = useState({});
  const [fileType, setFileType] = useState(
    addNew.type === "file" ? "url" : addNew.type
  );

  const handleOnSubmit = async (event) => {
    event.preventDefault();

    if (addNew.type === "file") {
      if (fileType === "file") {
        const file = event.target.files[0];
        if (file) {
          const fileInfo = getFileExtensionAndIcon(file.name);
          const icon = fileInfo.icon;

          const newFile = {
            name: file.name,
            url: null,
            path: `EOPL_EXPLORER_FILES/${file?.name}`,
            icon: icon,
            explorerWindow: addNew.windowId,
            parentFolder: addNew.parentFolderId,
          };

          await dispatch(uploadFile(file, newFile));
          fileInput.current.value = ""; // Clear the file input
        }
      } else {
        const name = myRefName.current.value;
        const url = myRefUrl.current.value;
        if (name === "" || url === "") {
          Toast({ status: "error", message: "Name and Url are required" });
          setHasError({ name, url });
          return;
        }
        const newFile = {
          name,
          url,
          path: null,
          icon: "Link",
          explorerWindow: addNew.windowId,
          parentFolder: addNew.parentFolderId,
        };
        dispatch(createExplorerFile(newFile));
      }
    } else {
      const name = myRefName.current.value;
      const newFolder = {
        name,
        explorerWindow: addNew.windowId,
        parentFolder: addNew.parentFolderId,
      };
      dispatch(createExplorerFolder(newFolder));
    }

    setAddNew({});
    if (open && !open.includes(addNew.parentFolderId)) {
      toggleFolderState(addNew.parentFolderId);
    }
  };

  return (
    <form onSubmit={handleOnSubmit} className="flex-1  ">
      <div className="flex flex-1 w-full items-center space-x-2 p-2 relative ">
        <div className={`w-8 h-12 flex items-center justify-center`}>
          {addNew.type === "file" && (
            <>
              <Button
                type="button"
                name="file"
                label="Upload"
                color="primary"
                onClick={() =>
                  setFileType((prev) => (prev === "file" ? "url" : "file"))
                }
                className={`w-8 !py-2 !px-1 flex items-center justify-center !ring-0 !ring-offset-0`}
              >
                {fileType === "file" ? (
                  <FaFile className="h-6 w-6" />
                ) : (
                  <FaLink className="h-6 w-6" />
                )}
              </Button>
            </>
          )}
          {addNew.type === "folder" && <FaFolder className="h-6 w-6" />}
        </div>
        <div className="flex-1 grow items-center w-full">
          {fileType === "file" ? (
            <>
              <input
                ref={fileInput}
                type="file"
                onChange={handleOnSubmit}
                className="hidden"
              />
              <label htmlFor="file" className="pb-1 w-full text-center pr-8">
                Select File
              </label>
              <div className="flex justify-center">
                <Button
                  type="button"
                  onClick={() => fileInput.current.click()}
                  color="primary"
                  className="flex items-center !pl-2 !pr-3 mr-8"
                >
                  Upload File
                </Button>
              </div>
            </>
          ) : (
            <>
              <FormField
                ref={myRefName}
                labelClassName="pt-1 pl-1"
                autoFocus
                label={`${toProperCase(fileType)} Name`}
                name={`addNew${addNew.type}`}
                placeholder={`Enter ${toProperCase(fileType)} Name`}
                error={hasError?.name === ""}
                onChange={() => setHasError({})}
                autoComplete="off"
              />
              {addNew.type === "file" && (
                <FormField
                  ref={myRefUrl}
                  labelClassName="pt-1 pl-1"
                  label={`Url`}
                  name={`addNew${addNew.type}`}
                  placeholder="Url"
                  error={hasError?.url === ""}
                  onChange={() => setHasError({})}
                  autoComplete="off"
                />
              )}
            </>
          )}
        </div>
        <Tippy
          arrow={true}
          content={
            <span className="bg-secondary-2 text-sm rounded px-2 py-1 shadow text-white">
              Cancel
            </span>
          }
          delay={[200, 0]} // delay by 200 url give the previous tippy time url close
        >
          <Button
            type="button"
            onClick={() => {
              setAddNew({});
            }}
            className="h-6 w-6 !p-0 !bg-inherit text-danger hover:text-typography-2 cursor-pointer !absolute right-2 top-1 !ring-0 !ring-offset-0"
          >
            <XIcon className="h-6 w-6" />
          </Button>
        </Tippy>
      </div>
      <button type="submit" className="hidden" />{" "}
    </form>
  );
};

export default Input;
