import React from "react";
import { Field, ErrorMessage } from "formik";
import TextError from "./TextError";
import "react-datepicker/dist/react-datepicker.css";
// import moment from 'moment';
import style from "./Styles";

function DatePicker(props) {
  const { label, name, className, labelClass, ...rest } = props;

  // let dt = value
  // if (dt) {
  //   var parts = value.split('-');
  //   // const y = value.slice(0, 4)
  //   // const m = value.slice(3, 2)
  //   // const d = value.slice(7, 2)
  //   dt = new Date(parts[0], parts[1] - 1, parts[2])
  // }

  return (
    <div className={`${className || style.formControl}`}>
      <label htmlFor={name} className={`${style.label} ${labelClass}`}>
        {label}
      </label>
      <Field name={name}>
        {({ form, field }) => {
          const { setFieldValue } = form;
          const { value } = field;
          //const date = moment();//value ? Moment(value.slice(0, 10)).toDate() : Moment().toDate(); //value ? new Date(value.slice(0, 10)) : new Date(0);//.toISOString();//? new Date(value.toISOString().slice(0, 10)) : new Date(0);
          // const formatDate = moment().format('DD-MM-YYYY')

          // const getValue = () => {
          let dtString = "";
          new Date();
          //let dtDate = new Date();
          // const formatDate = moment(dt).toDate()._d;//.format('DD-MM-YYYY')
          if (value) {
            // var parts = value.split('-');
            // dt = new Date(`${value}.0000z`);
            // const y = value.substring(0, 4)
            // const m = value.substring(5, 7)
            //dtDate = new Date(value);
            dtString = value.slice(0, 10);
            //value.toISOString().slice(0, 10)

            // dt = new Date(parts[0], parts[1] - 1, parts[2])
            // dt = [y, m, d].join('-');//.toString('yyyy-MM-dd')
          }

          // return dt
          // }

          return (
            <>
              {/* <p>{formatDate}</p> */}
              {/* <DateView
                id={name}
                {...field}
                {...rest}
                // value={date}
                selected={value ?? null}
                onChange={val => {
                  return setFieldValue(name, val);

                }}
              // dateFormat="dd/MM/yyyy"
              /> */}
              <input
                {...rest}
                className={style.input}
                // min={min !== "Invalid Date" && min.toISOString().slice(0, 10)}
                // max={max !== "Invalid Date" && max.toISOString().slice(0, 10)}
                onChange={(e) => {
                  const val = e.target.value;
                  // return setFieldValue(moment(val).format('YYYY-MM-DD'))
                  return setFieldValue(name, val);
                  // return setFieldValue(new Date(val))
                  // setFilter((old = []) => [val ? val : undefined, old[1]]);
                }}
                type="date"
                value={dtString}
              />
            </>
          );
        }}
      </Field>
      <ErrorMessage component={TextError} name={name} />
    </div>
  );
}

export default DatePicker;
