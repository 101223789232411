import { useState, useEffect } from "react";
import { Formik, Field } from "formik";
import { FieldArray } from "formik";
import Button from "../../../packages/button/Button";
import { useSelector, useDispatch } from "react-redux";
import { Role } from "../../../data/roles";
import {
  extractUsername,
  getFormattedDate,
  updateObject,
} from "../../../utils/utility";
import { updateQuarterlyStatus } from "../../../store/eoplActions";
import moment from "moment";
import FormField from "./FormField";
import { InformationCircleIcon, SaveIcon } from "@heroicons/react/solid";
import style from "../../../components/ui/Formik/Controls/Styles";
import Tippy from "@tippyjs/react";

const NextQuarterReviewView = () => {
  const dispatch = useDispatch();
  // redux state
  const eoplProgramData = useSelector((state) => state.eopl.data);
  const currentUser = useSelector((state) => state.profile.profilesData);
  const reviewDateActions = useSelector(
    (state) => state.dropdown.dropdownsData?.reviewDateAction
  );

  // local state
  const [nextQuarterReviewData, setNextQuarterReviewData] = useState([]);
  const [reviewActionOptions, setReviewActionOptions] = useState([]);
  const [mostRecentReview, setMostRecentReview] = useState();
  const [showQuarterlyReviews, setShowQuarterlyReviews] = useState(false);

  // set the quarterly review data
  useEffect(() => {
    // if (nextQuarterReviewData.length > 0) return;
    if (!eoplProgramData?.id) return;
    let updatedObject = eoplProgramData?.eoplNextQuarterReview?.map((v) => ({
      ...v,
      auditDateTime: getFormattedDate(v.auditDateTime, true),
      nextQtrReviewDate: getFormattedDate(v.nextQtrReviewDate),
      reviewDateAction: +v.reviewDateAction,
    }));
    setNextQuarterReviewData(updatedObject);
  }, [eoplProgramData]);

  // update the quarterly review status and get the updated reviews from the database
  const updateQuarterlyReview = (values, id) => {
    const eoplProgramStateUpdate = updateObject(
      { ...eoplProgramData },
      { eoplNextQuarterReview: values.nextReviewData }
    );
    let objectToSave = values.nextReviewData.find((e) => e.id === id);
    const { nextQtrReviewDate, ...nextQuarterReviewStatus } = objectToSave;
    dispatch(
      updateQuarterlyStatus(
        nextQuarterReviewStatus.id,
        nextQuarterReviewStatus,
        eoplProgramStateUpdate
      )
    );
  };

  // set the review action options (to { label: "name", value: "id" })
  useEffect(() => {
    if (reviewActionOptions.length > 0 || reviewDateActions.length === 0)
      return;
    const statusOptions = reviewDateActions.map((option) => {
      return {
        label: option.name,
        value: option.id,
      };
    });
    setReviewActionOptions(statusOptions);
  }, [reviewActionOptions, reviewDateActions]);

  // set the most recent past review
  useEffect(() => {
    const flaggedPastRecords = checkAndFlagPastRecords();
    if (flaggedPastRecords.length > 0) {
      setMostRecentReview(flaggedPastRecords[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextQuarterReviewData]);

  // const getMostRecentPastReview = () => {
  //   if (!nextQuarterReviewData || nextQuarterReviewData.length === 0) return null;

  //   const pastReviews = nextQuarterReviewData
  //     .filter((data) => {
  //       const reviewDate = moment(data.nextQtrReviewDate, "DD/MM/YYYY");
  //       return reviewDate.isBefore(moment());
  //     })
  //     .sort((a, b) => {
  //       const dateA = moment(a.nextQtrReviewDate, "DD/MM/YYYY");
  //       const dateB = moment(b.nextQtrReviewDate, "DD/MM/YYYY");
  //       return dateB.diff(dateA); // Sort in descending order to get the most recent past review
  //     });

  //   return pastReviews[0]; // Pick the most recent past review
  // };

  const getMissedReviewsAfterCompleted = () => {
    const pastRecords = getPastRecords();
    if (pastRecords.length === 0) return [];

    // Find the index of the most recent completed review
    const mostRecentCompletedIndex = pastRecords.findIndex(
      (record) => +record.reviewDateAction !== 3
    );

    // If no completed review is found, return all past records
    if (mostRecentCompletedIndex === -1) return pastRecords;

    // Get the missed reviews after the most recent completed review
    const missedReviews = pastRecords
      .slice(0, mostRecentCompletedIndex + 1)
      .filter((record) => +record.reviewDateAction === 3);

    return missedReviews;
  };

  const getPastRecords = () => {
    if (!nextQuarterReviewData || nextQuarterReviewData.length === 0) return [];
    const pastRecords = nextQuarterReviewData
      .filter((data) => {
        const reviewDate = moment(data.nextQtrReviewDate, "DD/MM/YYYY");
        return reviewDate.isBefore(moment());
      })
      .sort((a, b) => {
        const dateA = moment(a.nextQtrReviewDate, "DD/MM/YYYY");
        const dateB = moment(b.nextQtrReviewDate, "DD/MM/YYYY");
        if (dateA.isSame(dateB)) {
          return b.id - a.id; // Sort by id if dates are the same
        }
        return dateB.diff(dateA); // Sort in descending order
      });

    return pastRecords;
  };

  const checkAndFlagPastRecords = () => {
    const pastRecords = getPastRecords();
    if (pastRecords.length === 0) return [];

    return pastRecords.map((record, index) => ({
      ...record,
      editable: index === 0, // Only the most recent past review is editable
      outstanding: record.reviewDateAction === 3,
      message: index === 0 ? "" : "This review is no longer editable",
      status:
        index === 0
          ? "OK"
          : record.reviewDateAction === 3
          ? "Missed"
          : reviewActionOptions.find((m) => m.value === record.reviewDateAction)
              ?.label,
    }));
  };

  const getNextReview = () => {
    if (!nextQuarterReviewData || nextQuarterReviewData.length === 0) return;
    const nextReviewRecord = nextQuarterReviewData
      .filter((data) => {
        const nextReviewDate = moment(data.nextQtrReviewDate, "DD/MM/YYYY");
        const isAfterToday = nextReviewDate.isAfter(moment()); //&& !data.reviewDateStatus
        // const isOutstanding =
        //   isAfterToday && (data.reviewDateAction === 3 || !data.notes);
        return isAfterToday;
      })
      .sort((a, b) => {
        const dateA = moment(a.nextQtrReviewDate, "DD/MM/YYYY");
        const dateB = moment(b.nextQtrReviewDate, "DD/MM/YYYY");
        return dateA.diff(dateB); // Sort in ascending order
      })[0]; // Pick the first record from the sorted list

    return nextReviewRecord;
  };

  const nextReview = getNextReview();

  const getDurationString = (reviewDate) => {
    // const duration = moment.duration(
    //   moment(reviewDate, "DD/MM/YYYY").diff(moment())
    // );
    // moment duration is not accurate for months/days, so we need to calculate it manually

    const now = moment();
    let start;
    let end;
    if (now.isBefore(moment(reviewDate, "DD/MM/YYYY"))) {
      start = now;
      end = moment(reviewDate, "DD/MM/YYYY");
    } else {
      end = now;
      start = moment(reviewDate, "DD/MM/YYYY");
    }

    const duration = moment.duration(end.diff(start));

    const totalMonths = Math.floor(duration.asMonths());
    const years = Math.floor(totalMonths / 12);
    const months = totalMonths % 12;
    const days = end.diff(start.clone().add(totalMonths, "months"), "days") + 1; // Include the last day

    let durationString = "";
    if (years > 0) durationString += `${years} years`;
    if (years > 0 && months > 0) durationString += ", ";
    if (months > 0) durationString += `${months} months`;
    if ((years > 0 || months > 0) && days > 0) durationString += ", ";
    if (days > 0) durationString += `${days} days`;

    return durationString;
  };

  const quarterlyReviewsMessage = () => {
    if (!nextQuarterReviewData || nextQuarterReviewData.length === 0) return;
    const nextReview = getNextReview();

    const allPastRecords = getPastRecords();
    const allMissedRecords = getMissedReviewsAfterCompleted();
    const pastRecords = allMissedRecords.filter(
      (m) => m.reviewDateAction === 3
    );

    let pastMessage = "";
    let nextMessage = "";

    if (!nextReview) {
      nextMessage = "No upcoming quarterly reviews found.";
    } else {
      const nextReviewDate = nextReview.nextQtrReviewDate;
      const durationNext = getDurationString(nextReviewDate);
      let daysUntilNextReview;
      daysUntilNextReview = moment(nextReviewDate, "DD/MM/YYYY").diff(
        moment(),
        "days"
      );

      nextMessage = `The next review `;
      nextMessage += `${
        nextReview?.reviewDateAction !== 3 ? "was" : "is"
      } due on ${nextReviewDate}.  `;

      if (nextReview?.reviewDateAction !== 3) {
        nextMessage += `Completed on ${nextReview?.auditDateTime}.  `;
      } else {
        nextMessage += `  (${durationNext} ${
          daysUntilNextReview < 0 ? "past" : "to go).  "
        }`;
      }
    }

    if (pastRecords && pastRecords.length > 0) {
      const mostRecentOutstandingReviewDate = pastRecords[0].nextQtrReviewDate;

      const durationPast = getDurationString(mostRecentOutstandingReviewDate);

      const pastReviews = pastRecords.length;

      if (pastReviews > 0) {
        pastMessage = `${pastReviews} quarterly review`;
        pastMessage += `${pastReviews > 1 || pastReviews === 0 ? "s" : ""} `;
        pastMessage += ` ${pastReviews > 1 ? "were" : "was"} missed`;

        if (allPastRecords.length > allMissedRecords.length) {
          pastMessage += `, since last completed.  `;
        } else {
          pastMessage += ".  ";
        }

        if (allMissedRecords[0].reviewDateAction === 3) {
          pastMessage += `The last was due on ${mostRecentOutstandingReviewDate}`;
          pastMessage += `  (${durationPast} ago).`;
        }
      }
    }

    return (
      <div className="flex flex-col justify-center text-xs font-semibold bg-white rounded px-2 ml-auto h-9">
        {pastRecords && pastRecords.length > 0 && (
          <p className={`text-danger`}>{pastMessage}</p>
        )}
        {nextMessage && (
          <p
            className={`${
              +nextReview?.reviewDateAction !== 3
                ? "text-success"
                : "text-typography-1"
            }`}
          >
            {nextMessage}
          </p>
        )}
      </div>
    );
  };

  return (
    <div>
      <div className="flex items-center px-4 bg-primary dark:bg-secondary-3 text-typography-2 h-10">
        <h3 className="font-semibold w-52">
          Quarterly Reviews ({nextQuarterReviewData?.length ?? 0})
        </h3>
        <div
          className={`flex gap-x-2 text-xs font-semibold items-center text-white flex-row-reversse cursor-pointer`}
          onClick={() => setShowQuarterlyReviews(!showQuarterlyReviews)}
        >
          <FormField
            label={`${
              showQuarterlyReviews === false ? "Show" : "Hide"
            }  Quarterly Reviews`}
            labelClassName="!w-36"
            name="showQuarterlyReviews"
            type="checkbox"
            checked={showQuarterlyReviews}
            onClick={() => setShowQuarterlyReviews(!showQuarterlyReviews)}
          />
        </div>
        {quarterlyReviewsMessage()}
      </div>

      <Formik
        initialValues={{ nextReviewData: nextQuarterReviewData }}
        enableReinitialize={true}
      >
        {({ values, setFieldValue }) => {
          const flaggedPastRecords = checkAndFlagPastRecords();

          return (
            showQuarterlyReviews && (
              <div className="p-1 border-x-2 border-x-primary border-b-2 border-b-primary">
                <div className="whitespace-nowrap overflow-y-scroll border-box ">
                  <FieldArray name="nextReviewData">
                    {() => (
                      <div className="max-h-80">
                        <table className="table-auto w-full whitespace-nowrap text-center">
                          <thead className="text-white bg-primary dark:bg-secondary-3-5 text-typography-2 text-sm sticky top-0">
                            <tr className="hidden md:table-row">
                              <th className="py-2 pl-2 tracking-wider">
                                Review Due
                              </th>
                              <th className="py-2 pl-2 tracking-wider">
                                Last Modified
                              </th>
                              <th className="py-2 pl-2 tracking-wider">
                                Username
                              </th>
                              <th className="py-2 pl-2 tracking-wider">
                                Review Notes
                              </th>
                              <th
                                className="py-2 pl-2 tracking-wider"
                                colSpan={2}
                              >
                                Status
                              </th>
                            </tr>
                          </thead>
                          <tbody className="flex-1 md:flex-none bg-secondary-4 dark:bg-secondary-3 text-secondary-1 text-sm">
                            {values.nextReviewData.length ? (
                              values.nextReviewData.map((item, index) => {
                                // get the record from the flaggedPastRecords array
                                const pastRecord = flaggedPastRecords.find(
                                  (record) => record.id === item.id
                                );

                                // get this record's review date as a date object
                                const reviewDate = moment(
                                  item.nextQtrReviewDate,
                                  "DD/MM/YYYY"
                                );

                                // check if the review date is before today
                                const isBeforeToday = reviewDate.isBefore(
                                  moment()
                                );

                                // check if this record is the most outstanding review
                                let isMostRecentReview =
                                  mostRecentReview?.id === item.id;

                                let isNextReview = nextReview?.id === item.id;

                                // format the font based on the record's status
                                let fontFormat =
                                  isBeforeToday &&
                                  pastRecord?.outstanding &&
                                  +item?.reviewDateAction === 3 &&
                                  !isMostRecentReview
                                    ? "text-danger/70"
                                    : "text-typography-1 dark:text-white";

                                // highlight the most recent and next review
                                if (isMostRecentReview || isNextReview) {
                                  fontFormat += " font-semibold";
                                }

                                // highlight the most recent review if it has been not completed
                                if (
                                  isMostRecentReview &&
                                  +item?.reviewDateAction === 3
                                ) {
                                  fontFormat += " !text-danger";
                                }

                                // highlight the next review
                                if (isNextReview) {
                                  fontFormat += " !text-info";
                                }

                                // disable the input fields if the user is not an editor or the record is not editable
                                let isDisabled =
                                  currentUser.role !== Role.view &&
                                  isBeforeToday &&
                                  !pastRecord?.editable;

                                // NOTE: The following code will also disable if the most recent review has been complete
                                /*
                                  if (
                                    !isDisabled &&
                                    +mostRecentReview?.reviewDateAction !== 3 &&
                                    isMostRecentReview
                                  ) {
                                    isDisabled = true;
                                  }
                                  */

                                // show the update actions if the next review or most recent past review
                                let showUpdateActions =
                                  currentUser.role !== Role.view &&
                                  ((isBeforeToday && pastRecord?.editable) ||
                                    isNextReview);

                                // NOTE: The following code will also hide the action inputs if the most recent review has been complete
                                /*
                                  if (
                                    showUpdateActions &&
                                    +mostRecentReview?.reviewDateAction !== 3 &&
                                    isMostRecentReview
                                  ) {
                                    showUpdateActions = false;
                                  }
                                  */

                                // get the status name based on the reviewDateAction value
                                let statusValue = reviewActionOptions.find(
                                  (m) => m.value === item.reviewDateAction
                                )?.label;

                                // if the record is outstanding show the message from flagged records
                                if (pastRecord?.outstanding) {
                                  statusValue = pastRecord?.status;
                                }

                                return (
                                  <tr
                                    key={index}
                                    className={`md:hs-8 h-1d2 bg-rsed-500 odd:bg-primary/30 dark:bg-secondary-2 
                                        ${fontFormat}`}
                                  >
                                    <td className="w-28">
                                      <Field
                                        type="text"
                                        className={`${style.input} !h-7 !rounded-none text-center`}
                                        name={`nextReviewData.${index}.nextQtrReviewDate`}
                                        disabled={true}
                                      />
                                    </td>
                                    {pastRecord ||
                                    nextReview?.id === item?.id ? (
                                      <>
                                        {/* only show the last modified and notes for past records or the next record */}
                                        <td className="w-36">
                                          <Field
                                            type="text"
                                            className={`${style.input} !h-7 !rounded-none text-center`}
                                            name={`nextReviewData.${index}.auditDateTime`}
                                            disabled={true}
                                            value={getFormattedDate(
                                              item.auditDateTime,
                                              true
                                            )}
                                          />
                                        </td>
                                        <td className="w-24">
                                          <Field
                                            type="text"
                                            className={`${style.input} !h-7 !rounded-none text-center`}
                                            name={`nextReviewData.${index}.auditUserId`}
                                            disabled={true}
                                            value={extractUsername(
                                              item.auditUserId.name
                                            )}
                                          />
                                        </td>
                                        <td>
                                          <Field
                                            type="text"
                                            className={`${style.input} !h-7 !rounded-none`}
                                            name={`nextReviewData.${index}.notes`}
                                            placeholder={"Enter notes..."}
                                            disabled={isDisabled}
                                            value={
                                              isBeforeToday &&
                                              pastRecord?.outstanding &&
                                              !item?.notes
                                                ? pastRecord?.message
                                                : item?.notes ?? ""
                                            }
                                          />
                                        </td>
                                      </>
                                    ) : (
                                      // blank space for the last modified and notes for other records
                                      <td colSpan={2}></td>
                                    )}
                                    {/* only show the actions for past records or the next record */}
                                    {showUpdateActions ? (
                                      <>
                                        <td className="w-20">
                                          <Field
                                            className={`${style.input} !h-7 !rounded-none`}
                                            as="select"
                                            id={`nextReviewData[${index}].reviewDateAction`}
                                            name={`nextReviewData[${index}].reviewDateAction`}
                                            value={item.reviewDateAction}
                                            disabled={isDisabled}
                                          >
                                            {reviewActionOptions.map(
                                              (element, i) => (
                                                <option
                                                  className="text-center"
                                                  key={i}
                                                  value={element?.value}
                                                >
                                                  {element?.label}
                                                </option>
                                              )
                                            )}
                                          </Field>
                                        </td>
                                        <td className="w-10">
                                          <div className="w-full flex justify-center">
                                            <Tippy
                                              arrow={true}
                                              content={
                                                <span className="font-bold  bg-secondary text-sm rounded px-2 py-1 shadow text-primary">
                                                  Update Status
                                                </span>
                                              }
                                              delay={[200, 0]} // delay by 200 to give the previous tippy time to close
                                            >
                                              <Button
                                                type="button"
                                                className={`flex !p-px !ring-0 !ring-offset-0 ${
                                                  +item?.reviewDateAction ===
                                                    3 &&
                                                  pastRecord &&
                                                  "!bg-danger"
                                                }`}
                                                onClick={() =>
                                                  updateQuarterlyReview(
                                                    values,
                                                    item.id
                                                  )
                                                }
                                              >
                                                <SaveIcon className="h-6 w-6 p-1" />
                                              </Button>
                                            </Tippy>
                                          </div>
                                        </td>
                                      </>
                                    ) : (
                                      <td className="w-30" colSpan={2}>
                                        {pastRecord && (
                                          <Field
                                            type="text"
                                            className={`${style.input} !h-7 !rounded-none text-center`}
                                            name={`nextReviewData.${index}.reviewDateAction`}
                                            value={statusValue}
                                            disabled={true}
                                          />
                                        )}
                                      </td>
                                    )}
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td
                                  className="p-4 font-bold text-lg h-20"
                                  colSpan={5}
                                >
                                  <span className="text-info flex items-center">
                                    <InformationCircleIcon className="h-7 mr-2" />
                                    No quarterly reviews found for this
                                    programme..!
                                  </span>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    )}
                  </FieldArray>
                </div>
              </div>
            )
          );
        }}
      </Formik>
    </div>
  );
};

export default NextQuarterReviewView;
