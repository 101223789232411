import { TrashIcon } from "@heroicons/react/solid";
import React from "react";
import { FaFileCirclePlus, FaFolderPlus } from "react-icons/fa6";

const New = (props) => {
  const { setAddNew, root, setDeleteItem, data, windowId, windowName, addNew } =
    props;

  const newFolder = (e) => {
    e.stopPropagation();
    if (addNew?.type === "folder") {
      setAddNew({});
    } else {
      const newFolder = {
        parentFolderId: data.folderId,
        folderName: data.folderName,
        windowId: windowId,
        windowName: windowName,
        root,
        type: "folder",
      };
      setAddNew(newFolder);
    }
  };

  const newFile = (e) => {
    e.stopPropagation();
    if (addNew?.type === "file") {
      setAddNew({});
    } else {
      const newFile = {
        parentFolderId: data.folderId,
        folderName: data.folderName,
        windowId: windowId,
        windowName: windowName,
        root,
        type: "file",
      };
      setAddNew(newFile);
    }
  };

  const deleteItem = (e) => {
    e.stopPropagation();
    setDeleteItem({
      folderId: data?.folderId,
      folderName: data?.folderName,
      windowId: data?.windowId,
      windowName: data.windowName,
      type: data?.folderId ? "Folder" : "Window",
    });
  };

  return (
    <div className="flex items-center text-typography-1 space-x-2">
      <TrashIcon
        onClick={deleteItem}
        className={`h-6 w-6 hover:text-danger ${
          root ? "text-secondary" : "text-primary"
        } cursor-pointer`}
      />
      <FaFileCirclePlus
        onClick={newFile}
        className={`h-6 w-5 hover:text-info ${
          root ? "text-secondary" : "text-primary"
        }`}
        cursor="pointer"
      />
      <FaFolderPlus
        onClick={newFolder}
        className={`h-6 w-5 hover:text-info ${
          root ? "text-secondary" : "text-primary "
        }`}
        cursor="pointer"
      />
    </div>
  );
};

export default New;
