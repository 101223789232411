import { Route, Routes, useLocation } from "react-router-dom";

import Layout from "./components/layout/Layout";
import Home from "./pages/Home";
import EOPLCreate from "./pages/eopl/Edit";
import Dashboard from "./pages/dashboard";
import EOPLAllData from "./pages/eopl/AllData";
import CyclePlanUpload from "./pages/eopl/Upload";
import Explorer from "./pages/explorer";
import NotFound from "./pages/NotFound";
import NotAllowed from "./pages/NotAllowed";
import NetworkError from "./pages/NetworkError";
import Settings from "./pages/settings/Settings";
import Users from "./pages/settings/Users";
import { Toaster } from "react-hot-toast";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { Spinner } from "./packages/spinner/Spinner";
import { getUser } from "./store/authActions";
import Login from "./pages/auth/Login";
import PrivateRoute from "./components/PrivateRoute";
import { ResponseInterceptor } from "./axios";

import { Role } from "./data/roles";
import Register from "./pages/auth/Register";
import { getAllDropdowns } from "./store/dropdownActions";
import { getActiveProfile } from "./store/profileActions";
import Issue from "./pages/eopl/Issue";

const App = (props) => {
  const { buildType } = props;
  const loader = useSelector((state) => state.global);
  // const token = useSelector((state) => state.auth.token);
  // eslint-disable-next-line no-unused-vars
  const timeoutExceeded = useSelector((state) => state.global.timeoutExceeded);
  const networkError = useSelector((state) => state.auth.networkError);
  const loginSuccess = useSelector((state) => state.profile.loginSuccess);
  const dispatch = useDispatch();
  const location = useLocation();

  const [persist, setPersist] = useState(
    localStorage.getItem("persist") || false
  );

  useEffect(() => {
    dispatch(getActiveProfile());
    dispatch(getUser());
    dispatch(getAllDropdowns());
  }, [dispatch]);

  useEffect(() => {
    setPersist(localStorage.setItem("persist", location.pathname));
  }, [location, persist]);

  let routes = (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />
      {networkError && <Route path="*" element={<NetworkError />} />}
    </Routes>
  );

  if (loginSuccess) {
    routes = (
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route element={<PrivateRoute roles={[Role.user, Role.admin]} />}>
          <Route path="/eopl/create" element={<EOPLCreate />}>
            <Route path=":eoplProgramRefId" element={<EOPLCreate />} />
            <Route path="id/:id" element={<EOPLCreate />} />
          </Route>
          <Route path="/eopl/upload" element={<CyclePlanUpload />} />
          <Route path="/eopl/explorer" element={<Explorer />} />
          <Route path="/eopl/mail" element={<Issue />} />
          <Route path="/dashboard" element={<Dashboard />} />
        </Route>
        <Route
          element={<PrivateRoute roles={[Role.view, Role.user, Role.admin]} />}
        >
          <Route path="/eopl/allprograms" element={<EOPLAllData />} />
        </Route>
        <Route element={<PrivateRoute roles={[Role.admin]} />}>
          <Route path="/users" element={<Users />} />
          <Route path="/settings" element={<Settings />} />
        </Route>
        <Route path="/unauthorised" element={<NotAllowed />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    );
  }

  const devLabel = `${
    buildType === "PROD" ? "hidden" : "fixed"
  } block bottom-4 opacity-30 pointer-events-none -right-24 origin-bottom -rotate-45 bg-primary/80 text-typr p-6 w-56 text-center text-2xl font-bold`;

  return (
    <Layout>
      <Toaster
        position="top-right"
        toastOptions={{
          className: "shadow-md !rounded-none !min-w-[540px]",
        }}
      />

      <Spinner isOpen={loader.loading} label={loader.label} />

      {/* {!loading && routes} */}
      {routes}
      <div className={devLabel}>{buildType}</div>

      <ResponseInterceptor location={location} />
    </Layout>
  );
};

export default App;
