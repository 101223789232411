import React, { useEffect } from "react";
import Input from "./Controls/Input";
import TableInput from "./Controls/TableInput";
import Textarea from "./Controls/Textarea";
import Select from "./Controls/Select";
import RadioButtons from "./Controls/RadioButtons";
import CheckboxGroup from "./Controls/CheckboxGroup";
import Checkbox from "./Controls/Checkbox";
import DatePicker from "./Controls/DatePicker";
import { useDispatch, useSelector } from "react-redux";
import { setChanged } from "../../../store/eoplActions";

function FormikControl(props) {
  const { control, type, dirty, ...rest } = props;
  const dispatch = useDispatch();

  const changed = useSelector((state) => state.eopl.changed);

  useEffect(() => {
    if (dirty && !changed) {
      dispatch(setChanged(true));
      // console.log(control + " field has changed");
    }
  }, [dirty, dispatch, changed, control]);

  switch (control) {
    case "input":
      return <Input type={type} {...rest} />;
    case "tableinput":
      return <TableInput type={type} {...rest} />;
    case "textarea":
      return <Textarea {...rest} />;
    case "select":
      return <Select {...rest} />;
    case "radio":
      return <RadioButtons {...rest} />;
    case "checkboxGroup":
      return <CheckboxGroup {...rest} />;
    case "checkbox":
      return <Checkbox {...rest} />;
    case "date":
      return <DatePicker {...rest} />;
    default:
      return null;
  }
}

export default FormikControl;
