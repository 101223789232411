import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Formik } from "formik";
import FormikControl from "../../../components/ui/Formik/FormikControl";
import * as Yup from "yup";
import Button from "../../../packages/button/Button";
// import useToggle from "../../../packages/_utils/useToggle";
import {
  createEoplProgrammeDetails,
  updateEoplProgrammeDetails,
} from "../../../store/eoplActions";
// import Toast from "../../../components/ui/Toast";
import { constructPogramDetailsObject } from "../../../utils/utility";
import { Role } from "../../../data/roles";
import { useNavigate } from "react-router-dom";
import { globalActions } from "../../../store/globalSlice";

const validationSchema = Yup.object().shape({
  eoplProgramRefId: Yup.string().nullable().required("Required"),
  job1: Yup.date().required("Required").typeError("Required"),
  initialArchitecturePlatform: Yup.number()
    .nullable()
    .required("Required")
    .typeError("Required"),
  initialVehicleLine: Yup.number()
    .nullable()
    .required("Required")
    .typeError("Required"),
  name: Yup.string().nullable().required("Required"),
  tfle: Yup.number().nullable().required("Required").typeError("Required"),
  manufacturingYear: Yup.string()
    .nullable(true)
    .required("Required")
    .typeError("Required"),
});

const ProgramDetails = (props) => {
  const { next } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.profile.profilesData);
  const eoplProgramData = useSelector((state) => state.eopl.data);
  const dropdownsData = useSelector((state) => state.dropdown.dropdownsData);
  const [architecturePlatformOptions] = useState(
    dropdownsData.architecturePlatformList
  );
  const [vehicleBrands] = useState(dropdownsData.vehicleBrands);
  const [vehicleLineOptions] = useState(dropdownsData.vehilcelineList);
  const [initialArchitecturePlatform, setinitialArchitecturePlatform] =
    useState(eoplProgramData?.architecturePlatform?.id);
  const [initialVehicleLine, setInitialVehicleLine] = useState(
    eoplProgramData?.vehicleLine?.id
  );
  const [typeOptions, setTypeOptions] = useState([
    { id: "misc. BiW", name: "misc. BiW" },
    { id: "misc. Propulsion", name: "misc. Propulsion" },
    { id: " misc. Other", name: " misc. Other" },
  ]);
  const [isDisabled, setIsDisabled] = useState(false);
  const customTypes = ["misc. Other", "misc. Propulsion", "misc. BiW"];

  useEffect(() => {
    setinitialArchitecturePlatform(eoplProgramData?.architecturePlatform?.id);
    setInitialVehicleLine(eoplProgramData?.vehicleLine?.id);
    if (
      !customTypes?.includes(eoplProgramData?.type.trim()) &&
      eoplProgramData?.id !== null
    ) {
      setTypeOptions([
        {
          id: eoplProgramData?.type.trim(),
          name: eoplProgramData?.type.trim(),
        },
      ]);
    } else if (!customTypes?.includes(eoplProgramData?.type.trim())) {
      setTypeOptions([
        { id: "misc. BiW", name: "misc. BiW" },
        { id: "misc. Propulsion", name: "misc. Propulsion" },
        { id: " misc. Other", name: " misc. Other" },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eoplProgramData]);

  const handleSubmit = (values) => {
    const customProgarmDetailsObj = constructPogramDetailsObject(
      values,
      dropdownsData,
      true
    );

    if (eoplProgramData?.id !== null) {
      dispatch(updateEoplProgrammeDetails(values.id, customProgarmDetailsObj));
    } else {
      dispatch(createEoplProgrammeDetails(customProgarmDetailsObj)).then(() => {
        dispatch(
          globalActions.setLoading({
            loading: true,
            label: `Refreshing All Data for Programme`,
          })
        );
        setTimeout(() => {
          navigate("/eopl/allprograms");
          dispatch(globalActions.setLoading({ loading: false }));
        }, [3000]);
      });
    }
    next(values, true);
  };

  useEffect(() => {
    if (
      customTypes?.includes(eoplProgramData?.type.trim()) ||
      eoplProgramData?.id === null
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eoplProgramData]);

  return (
    <>
      <Formik
        initialValues={{
          ...eoplProgramData,
          initialVehicleLine,
          initialArchitecturePlatform,
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
        validateOnMount
        id="eoplProgramDetails"
      >
        {({ submitForm, errors, values, setFieldTouched }) => {
          return (
            <>
              {/* <Form> */}
              <div className="px-4 pt-4 bg-primary/30 dark:bg-secondary-3-5 sm:pt-6 sm:pXb-4 ">
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 xl:grid-cols-11 2xl:grid-cols-12 gap-x-6 ">
                  <FormikControl
                    className="pb-4 !col-span-2 md:!col-span-2 lg:!col-span-3 xl:!col-span-5 2xl:!col-span-3"
                    control="input"
                    type="text"
                    label="Programme Reference"
                    name="eoplProgramRefId"
                    disabled={eoplProgramData.eoplProgramRefId !== null}
                  />
                  <FormikControl
                    className="pb-4 !col-span-2 sm:!col-span-1 lg:!col-span-2 xl:!col-span-3 min-w-fsit"
                    control="input"
                    type="text"
                    label="Programme Title"
                    name="name"
                    disabled={isDisabled}
                  />
                  <FormikControl
                    className="pb-4 !col-span-2 sm:!col-span-1 lg:!col-span-1 xl:!col-span-3 min-w-fsit"
                    control="select"
                    label="Type"
                    name="type"
                    isMulti={false}
                    options={typeOptions?.map((element) => ({
                      value: element.id,
                      label: element.name,
                    }))}
                    disabled={isDisabled}
                  />
                  <FormikControl
                    className="pb-4 !col-span-2 sm:!col-span-1 lg:!col-span-2 xl:!col-span-3 min-w-fsit"
                    control="input"
                    type="date"
                    label="J1"
                    name="job1"
                    disabled={isDisabled}
                  />
                  <FormikControl
                    className="pb-4 !col-span-2 sm:!col-span-1 lg:!col-span-1 xl:!col-span-1 min-w-fsit"
                    control="input"
                    type="number"
                    label="TFLE"
                    name="tfle"
                    disabled={isDisabled}
                  />
                  <FormikControl
                    className="pb-4 !col-span-2 sm:!col-span-1 lg:!col-span-1 xl:!col-span-2 min-w-fsit"
                    control="input"
                    type="text"
                    label="MY"
                    name="manufacturingYear"
                    disabled={isDisabled}
                  />
                  <FormikControl
                    className="pb-4 !col-span-2 sm:!col-span-1 lg:!col-span-2 xl:!col-span-2 min-w-fsit"
                    control="select"
                    label="ARCH"
                    name="initialArchitecturePlatform"
                    isMulti={false}
                    options={architecturePlatformOptions?.map((element) => ({
                      value: element.id,
                      label: element.name,
                    }))}
                    disabled={isDisabled}
                  />
                  <FormikControl
                    className="pb-4 !col-span-2 sm:!col-span-1 lg:!col-span-2 xl:!col-span-3 min-w-fsit"
                    control="select"
                    label="VL"
                    name="initialVehicleLine"
                    isMulti={false}
                    options={vehicleLineOptions?.map((element) => ({
                      value: element.id,
                      label: element.name,
                    }))}
                    disabled={isDisabled}
                  />
                  <FormikControl
                    className="pb-4 !col-span-2 sm:!col-span-1 lg:!col-span-2 xl:!col-span-2 min-w-fsit"
                    control="select"
                    label="Brand"
                    name="vehicleBrands"
                    isMulti={false}
                    options={vehicleBrands?.map((element) => ({
                      value: element.id,
                      label: element.name,
                    }))}
                    disabled={isDisabled}
                  />
                  <FormikControl
                    className="pb-4 !col-span-2 sm:!col-span-1 lg:!col-span-2 xl:!col-span-2 min-w-fsit"
                    control="input"
                    type="text"
                    label="S4P"
                    name="sp4"
                    disabled={isDisabled}
                  />
                  {/* <FormikControl
                      className="pb-4 !col-span-2 sm:!col-span-1 lg:!col-span-2 xl:!col-span-2 min-w-fsit"
                      control="input"
                      type="text"
                      label="Created By"
                      name="auditUserId.name"
                      disabled={isReadOnly}
                    />
                    <FormikControl
                      className="pb-4 !col-span-2 sm:!col-span-1 lg:!col-span-2 xl:!col-span-2 min-w-fsit"
                      control='date'
                      type='date'
                      label="Create Date"
                      name="auditDateTime"
                      disabled={isDisabled}
                    /> */}
                  {/* <FormikControl
                      className="pb-4 !col-span-2 sm:!col-span-1 lg:!col-span-2 xl:!col-span-2 min-w-fsit"
                      control="input"
                      type="text"
                      label="Engineer Contacts"
                      
                      name="engineersContact"
                      disabled={isReadOnly}
                    /> */}
                  {/* <FormikControl
                      className="pb-4 !col-span-2 sm:!col-span-1 lg:!col-span-2 xl:!col-span-3 min-w-fsit"
                      control="select"
                      label="EOPL IN-SCOPE"
                      name="initialInScopeData"
                      isMulti={false}
                      options={inScopeOptions?.map((element) => (
                        { value: element.id, label: element.name }
                      ))}
                      isCustomHandler={true}
                      customHandlerCallback={handleInScopeDropdownChange}
                      disabled={isReadOnly}
                    />
                    <FormikControl
                      className="pb-4 !col-span-2 sm:!col-span-1 lg:!col-span-2 xl:!col-span-3 min-w-fsit"
                      control="select"
                      label="PDL Check"
                      name="initialPdlCheck"
                      isMulti={false}
                      options={pdlCheckOptions?.map((element) => (
                        { value: element.id, label: element.value }
                      ))}
                      disabled={isReadOnly}
                    />
                    <FormikControl
                      className="pb-4 !col-span-2 sm:!col-span-1 lg:!col-span-2 xl:!col-span-3 min-w-fsit"
                      control="select"
                      label="EOPL Impact"
                      name="initialImpact"
                      isMulti={false}
                      options={impactOptions?.map((element) => (
                        { value: element.id, label: element.value }
                      ))}
                      disabled={isReadOnly}
                    />
                    <FormikControl
                      className="pb-4 !col-span-2 sm:!col-span-1 lg:!col-span-2 xl:!col-span-3 min-w-fsit"
                      control="select"
                      label="EOPL Status"
                      name="initialStatus"
                      isMulti={false}
                      options={statusOptions?.map((element) => (
                        { value: element.id, label: element.name }
                      ))}
                      disabled={isReadOnly}
                    /> */}
                </div>
              </div>
              {/* </Form> */}
              <hr />

              <div className="grid grid-cols-3 gap-4px-4 py-3 bg-white text-left sm:px-6 rounded-b">
                {currentUser.role !== Role.view && (
                  <>
                    <Button
                      disabled={isDisabled}
                      onClick={() => {
                        if (Object.keys(errors).length === 0) {
                          return submitForm();
                        } else {
                          Object.keys(errors).forEach((err) => {
                            setFieldTouched(err);
                          });
                        }
                      }}
                      color="success"
                      className={`place-self-center col-start-2`}
                    >
                      {!eoplProgramData.id ? "Submit" : "Save"}
                    </Button>
                  </>
                )}
              </div>
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default ProgramDetails;
