import { createSlice, current } from "@reduxjs/toolkit";

import _ from "underscore";

export const shoppingListObject = {
  apn: "",
  callOut: "",
  description: "",
  eoplProgramId: "",
  epc: "",
  epn: "",
  pf: "",
  pfApn: "",
};

export const initialValues = {
  id: null,
  apn: "",
  callOut: "",
  description: "",
  eoplProgramId: "",
  epc: "",
  epn: "",
  pf: null,
  pfApn: "",
  auditDateTime: null,
  version: null,
  isReadonly: true,
  imageCount: null,
};

const shoppingListSlice = createSlice({
  name: "shopping",
  initialState: {
    shoppingList: [],
    data: initialValues,
    changed: false,
    uploaded: false,
    uploading: false,
    loading: true,
    searchValue: null,
  },
  reducers: {
    setSearchValue(state, action) {
      state.searchValue = action.payload;
    },
    setUploading(state, action) {
      state.uploading = action.payload ?? false;
    },
    resetUploaded(state) {
      state.uploaded = false;
    },
    setChanged(state, action) {
      state.changed = action.payload ?? false;
    },
    setError(state) {
      state.error = true;
    },
    resetShoppingListData(state) {
      state.data = initialValues;
      state.shoppingList = [];
      state.changed = true;
      state.error = false;
    },
    updateField(state, action) {
      const currentData = current(state.data);
      const newData = {
        ...currentData,
        [action.payload.field]: action.payload.value,
      };
      state.data = newData;
    },
    // all data
    replaceShoppingListData(state, action) {
      const data = action.payload?.shoppingList ?? [];
      state.shoppingList = _.sortBy(data, "id");
      state.changed = true;
      state.error = false;
    },
    removeSpoAllData(state, action) {
      const id = action.payload;
      var data = state.shoppingList.filter((d) => d.id !== id);
      state.shoppingList = _.sortBy(data, "auditDateTime").reverse();
      state.changed = true;
      state.error = false;
    },
    updateShoppingListData(state, action) {
      const data = action.payload.shoppingList;
      const updatedData = [
        ...state.shoppingList.filter((d) => d.id !== data.id),
        data,
      ];
      state.shoppingList = _.sortBy(updatedData, "id");
      state.changed = true;
      state.error = false;
      state.uploaded = action.payload?.uploaded ?? false;
    },
  },
});

export const shoppingListActions = shoppingListSlice.actions;

export default shoppingListSlice;
