import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { ThemeContext } from "./ThemeProvider";

import {
  AdjustmentsIcon,
  BellIcon,
  ChatAltIcon,
  ColorSwatchIcon,
  MailIcon,
  MenuIcon,
  UserCircleIcon,
  XIcon,
} from "@heroicons/react/solid";

import useClickOutside from "../../packages/_utils/useClickOutside";
// import { logout } from "../../store/authActions";

const Navbar = (props) => {
  const { sideNavOpen, setSideNavOpen } = props;
  const [profile, setProfile] = useState(false);

  //#region Theme
  const { theme, setTheme } = useContext(ThemeContext);

  const toggleTheme = (e) => {
    switch (theme) {
      case "theme1":
        setTheme("theme2");
        break;
      case "theme2":
        setTheme("theme3");
        break;
      case "theme3":
        setTheme("theme4");
        break;
      default:
        setTheme("theme1");
        break;
    }
  };
  //#endregion

  const [notificationIcon] = useState(false);
  const [notifications] = useState(0);
  const [messagesIcon] = useState(false);
  const [messages] = useState(0);
  // const [userAvatar, setUserAvatar] = useState("https://i.pravatar.cc/100");
  const [userAvatar] = useState();
  // const dispatch = useDispatch();
  const profileData = useSelector((state) => state.profile.profilesData);
  // const navigate = useNavigate();
  // const logoutUrl = process.env.REACT_APP_SERVER_URL + "/saml2/logout/";

  let domNode = useClickOutside(() => {
    setProfile(false);
  });

  // const handleLogout = () => {
  //   dispatch(logout(user.userName));
  // };

  const userName = profileData.first_name
    ? `${profileData.first_name}  ${profileData.last_name}`
    : "JLR SSO Login";
  const style = {
    navBar: {
      button:
        "focus:outline-none flex h-16 w-full items-center justify-start focus:bg-secondary focus:text-primary hover:bg-primary text-primary transition-colors duration-300 ease-in-out relative group",
      icon: `fill-stroke flex flex-none h-full w-16 items-center justify-center text-primary hover:!text-secondary dark:text-heading dark:group-hover:!text-secondary`,
      text: "flex w-full h-full text-base items-center p-2",
    },
  };

  const jlrLogo = () => {
    let logo = "";
    switch (theme) {
      case "theme2":
        logo = "/Blue_small use.png";
        break;
      case "theme3":
        logo = "/White_small use.png";
        break;
      case "theme4":
        logo = "/White_small use.png";
        break;
      default:
        logo = "/Blue_small use.png";
        break;
    }
    return <img src={logo} width={40} alt="" />;
  };

  return (
    <nav className="h-16 flex items-stretch justify-between bg-secondary shadow-md fixed w-full z-30">
      <div className="flex w-full pr-2">
        <div className={`flex h-full flex-none `}>
          <button
            onClick={() => setSideNavOpen(!sideNavOpen)}
            className={`
            ignore-click-outside group flex flex-none items-center text-primary justify-center w-16 focus:outline-none focus:shadow-outline ease-in-out duration-300 hover:bg-primary hover:text-secondary ${
              sideNavOpen && ""
            }`}
          >
            {sideNavOpen ? (
              <XIcon className="h-6 w-6" />
            ) : (
              <MenuIcon className="h-6 w-6" />
            )}
          </button>
          <Link
            to="/"
            className="flex flex-initial justify-center items-center space-x-3 ml-4 w-16"
          >
            {jlrLogo()}
          </Link>
        </div>

        <div className="flex flex-1 items-center justify-left text-heading font-jlr-emeric-extra-light font-bold pl-4 relative overflow-hidden">
          <span className="absolute text-[8rem] -top-14X left-10 text-primary/20 font-jlr-emeric-semi-bold z-0">
            EOPL
          </span>
          <h1 className="text-3xl z-10">EOPL Application</h1>
        </div>

        <div className="hiddden lg:fdlex">
          <div className="flex items-center justify-end">
            {notificationIcon && (
              <div
                className={`h-full w-16 flex items-center justify-center border-l border-primary/40  ${
                  !messagesIcon && "border-r"
                }`}
              >
                <button className={style.navBar.button}>
                  <div className={style.navBar.icon}>
                    <BellIcon />
                  </div>
                  {/* Notification Count */}
                  {notifications > 0 && (
                    <div className="w-6 h-6 rounded-full pb-[1.5px] text-white text-sm font-bold flex justify-center items-center bg-danger border !border-secondary absolute top-3 right-3 pointer-events-none">
                      {notifications}
                    </div>
                  )}
                </button>
              </div>
            )}
            {messagesIcon && (
              <div className="h-full w-16 flex items-center justify-center border-l border-r border-primary/40 cursor-pointer hdidden">
                <button className={style.navBar.button}>
                  <div className={style.navBar.icon}>
                    <ChatAltIcon />
                  </div>
                  {/* Messages Count */}
                  {messages > 0 && (
                    <div className="w-6 h-6 rounded-full pb-[1.5px] text-white text-sm font-bold flex justify-center items-center bg-danger border !border-secondary absolute top-3 right-3 pointer-events-none">
                      {messages}
                    </div>
                  )}
                </button>
              </div>
            )}

            <div
              ref={domNode}
              className="flex items-center relative cursor-pointer h-full border-l border-secondary dark:text-typography-2 border-0"
              onClick={() => {
                if (!profileData.username)
                  window.location.replace(
                    process.env.REACT_APP_SERVER_URL + "/saml2/login/"
                  );
                else setProfile(!profile);
              }}
            >
              <div
                className={`transition-opacity duration-300 ease-in-out ${
                  profile ? "opacity-100" : "opacity-0"
                }`}
              >
                <ul
                  className={`p-2 w-full border-r bg-secondary absolute left-0 shadow-md mt-12 dark:text-typography-2 ${
                    !profile && "pointer-events-none"
                  }`}
                >
                  <li className="flex h-14 w-full px-2 justify-between transition-colors duration-300 ease-in-out text-primkary hover:bg-primary hover:text-typography-2 cursor-pointer items-center group">
                    <Link
                      className="flex items-center  group-hover:text-typography-2 "
                      to="/genericemail"
                    >
                      <MailIcon className="h-8 w-8" />
                      <span className="text-sm ml-2 w-full">Email</span>
                    </Link>
                  </li>
                  <li className="flex h-14 w-full px-2 justify-between transition-colors duration-300 ease-in-out text-primakry hover:bg-primary hover:text-typography-2 cursor-pointer items-center group">
                    <Link
                      className="flex items-center  group-hover:text-typography-2 "
                      to="/settings"
                    >
                      <AdjustmentsIcon className="h-8 w-8" />
                      <span className="text-sm ml-2 w-full">Settings</span>
                    </Link>
                  </li>
                  <li
                    className="flex h-14 w-full px-2 justify-between transition-colors duration-300 ease-in-out  hover:bg-primary hover:text-typography-2  cursor-pointer items-center"
                    onClick={() => toggleTheme()}
                  >
                    <ColorSwatchIcon className="h-8 w-8" />
                    <span className="text-sm ml-2 w-full">Toggle Theme</span>
                  </li>
                  <hr className=" opacity-100 w-full bg-primary" />
                  {/*<li className="flex h-14 w-full px-2 justify-between transition-colors duration-300 ease-in-out  hover:bg-primary hover:text-typography-2  cursor-pointer items-center">
                    <div className="flex items-center" onClick={handleLogout}>
                      <LogoutIcon className="h-8 w-8" />
                      <span className="text-sm ml-2 w-full">Logout</span>
                    </div>
                  </li>*/}
                </ul>
              </div>
              <div className="flex items-center text-primary dark:text-heading h-16 p-2 !pl-4 hover:bg-primary hover:!text-secondary dark:hover:!text-secondary transition-colors duration-300 ease-in-out group ">
                <div className="grid ">
                  {userAvatar ? (
                    <img
                      className="flex rounded-full h-10 w-10 object-cover border-2 border-secondary"
                      src={userAvatar}
                      alt="avatar"
                    />
                  ) : (
                    <UserCircleIcon className="w-10 h-10 right-3 transition-colors duration-300 text-primary dark:text-heading group-hover:!text-secondary" />
                  )}
                  {/* Online notification */}
                  {/* <div className='w-2.5 h-2.5 flex !-mb-3 self-end place-self-center	rounded-full bg-success border-2 border-secondary absolute' /> */}
                </div>
                <p className="text-inherit text-sm mx-3 p-0 m-o border-0">
                  {userName}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
